import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const EditIcon = (props) => {
    const [isShown, setIsShown] = React.useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Tooltip title={props.name==='templete'?props.disabled===true?t('TEMP003'):t('COMMON005'):props.disabled===true?t('BRNC001'):t('COMMON005')}>
                {props.disabled === true ?
                    <Grid>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="22.003" viewBox="0 0 22.001 22.003">
                            <path id="pencil" d="M24,7.764a1.1,1.1,0,0,0-.319-.781L19.018,2.319a1.116,1.116,0,0,0-1.562,0L14.343,5.432h0L2.319,17.456A1.1,1.1,0,0,0,2,18.237V22.9A1.1,1.1,0,0,0,3.1,24H7.764a1.1,1.1,0,0,0,.836-.319L20.558,11.659h0L23.682,8.6a1.309,1.309,0,0,0,.242-.363,1.1,1.1,0,0,0,0-.264.77.77,0,0,0,0-.154ZM7.313,21.8H4.2V18.688L15.124,7.764l3.113,3.113ZM19.788,9.326,16.675,6.213l1.562-1.551,3.1,3.1Z" transform="translate(-2 -2)" fill="#304669" opacity="0.5" />
                        </svg>
                    </Grid> :
                    <Grid
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                    >
                        {isShown ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="22.003" viewBox="0 0 22.001 22.003">
                                <path id="pencil" d="M24,7.764a1.1,1.1,0,0,0-.319-.781L19.018,2.319a1.116,1.116,0,0,0-1.562,0L14.343,5.432h0L2.319,17.456A1.1,1.1,0,0,0,2,18.237V22.9A1.1,1.1,0,0,0,3.1,24H7.764a1.1,1.1,0,0,0,.836-.319L20.558,11.659h0L23.682,8.6a1.309,1.309,0,0,0,.242-.363,1.1,1.1,0,0,0,0-.264.77.77,0,0,0,0-.154ZM7.313,21.8H4.2V18.688L15.124,7.764l3.113,3.113ZM19.788,9.326,16.675,6.213l1.562-1.551,3.1,3.1Z" transform="translate(-2 -2)" fill="#304669" />
                            </svg>

                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="22.003" viewBox="0 0 22.001 22.003">
                                <path id="pencil" d="M24,7.764a1.1,1.1,0,0,0-.319-.781L19.018,2.319a1.116,1.116,0,0,0-1.562,0L14.343,5.432h0L2.319,17.456A1.1,1.1,0,0,0,2,18.237V22.9A1.1,1.1,0,0,0,3.1,24H7.764a1.1,1.1,0,0,0,.836-.319L20.558,11.659h0L23.682,8.6a1.309,1.309,0,0,0,.242-.363,1.1,1.1,0,0,0,0-.264.77.77,0,0,0,0-.154ZM7.313,21.8H4.2V18.688L15.124,7.764l3.113,3.113ZM19.788,9.326,16.675,6.213l1.562-1.551,3.1,3.1Z" transform="translate(-2 -2)" fill="#304669" opacity="0.9" />
                            </svg>

                        )}
                    </Grid>
                }
            </Tooltip>
        </>
    );
};
export default EditIcon;
