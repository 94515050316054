import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 12.166 9.0720 0 20 20"><path fill="${encodeURIComponent(
            '#36C96D'
          )}" d="M4.132,13.471.178,9.517a.608.608,0,0,1,0-.86l.86-.86a.608.608,0,0,1,.86,0L4.562,10.46l5.7-5.7a.608.608,0,0,1,.86,0l.86.86a.608.608,0,0,1,0,.86l-7,7A.608.608,0,0,1,4.132,13.471Z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#36C96D',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#FFFFFF',
  
      marginTop: '7.5px',
      marginLeft: '1px',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 4,
        top: 4,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));
