import { IconButton, Paper, Tooltip } from "@material-ui/core";
import defaultProfile from '@material-ui/icons/EditOutlined';
import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUser } from "../../actions/ProfileApiCalls";
import Breadcrumb from "../../components/Breadcrumb";
import CommonUtil from "../../components/Util/CommonUtils";
import setJWTToken from "../../oauth-client/setJWTToken";
import EditIcon from "../reusableIcons/EditIcon";
import ProfileUplodUser from "./UploadProfileUser";

export default function UserProfile() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.security);
  const history = useHistory();
  const [profile, setProfile] = React.useState(defaultProfile);
  const { t } = useTranslation();
  const [userData, setUserData] = React.useState({});
  const breadcrumb = [{ path: '', name: t('COMMON128') }];
  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    nickName: '',
    dateOfBirth: '',
    gender: '',
    email: '',
    phone: '',
    profilePhoto: '',
  });
  useEffect(() => {
    if (store.validToken && !CommonUtil.isEmptyString(store.AccJwtToken)) {
      setJWTToken(store.AccJwtToken);
    }
    dispatch(getUser(store.user.id));
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!CommonUtil.isEmpty(store.userDate)) {
      if (!CommonUtil.isEmptyString(store.userDate.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + store.userDate.profilePhoto);
      }
    }
  }, [store.userDate]);
  useEffect(() => {
    if (!CommonUtil.isEmpty(store)) {
      setUserData(store.userData);
      setPayload({ ...payload, ...store.userData });
      if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + store.userData.profilePhoto);
      }

    }
    //eslint-disable-next-line
  }, [store]);

  const CreateTemplate = () => {
    history.push({ pathname: '/fams/user-profile-screen', });
  };
  return (
    <>
      <Breadcrumb links={breadcrumb} style={{ paddingBottom: '15px' }}>
        <Box textAlign='left' style={{ paddingBottom: '15px', paddingTop: '10px' }}>
          <div style={{ backgroundColor: '#F2F2F6' }}>
            <IconButton onClick={CreateTemplate} style={{ fontSize: '0.5rem' }}>
              <EditIcon />
            </IconButton>
          </div>
        </Box>
      </Breadcrumb>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <Paper className='css-pepar-in-userprofilesss'>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >
            <Grid container spacing={3}>
              <Grid container justifyContent="center" alignItems='center' lg={2} sm={12} mr={12} mt={4} mb={2}>
                <Grid lg={2} sm={12}  >
                  <ProfileUplodUser profilePhoto={profile} disabled={true} border='dashed' heightimage='225px' />
                </Grid>
              </Grid>

              <Grid container md={8} sm={12} spacing={1}>
                <Grid item textAlign={'center'} lg={6} sm={12} >
                  <div className='p-3' >
                    <Box display="flex" paddingTop={6}>
                      <Box item paddingRight={1} minWidth="120px">
                        <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON020')} </Typography>
                      </Box>
                      <Box item paddingRight={1} minWidth="25px">
                        <Typography style={{ color: '#939FB1' }}>:</Typography>
                      </Box>
                      <Box item className='textbreakk'>
                        <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                          {userData ? userData.firstName : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div className='p-3'>
                    <Box display="flex" >
                      <Box item paddingRight={1} minWidth="120px">
                        <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON021')}</Typography>
                      </Box>
                      <Box item paddingRight={1} minWidth="25px">
                        <Typography style={{ color: '#939FB1' }}>:</Typography>
                      </Box>
                      <Box item className='textbreakk'>
                        <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                          {userData ? userData.lastName : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div className='p-3' >
                    <Box display="flex" mb={3} >
                      <Box item paddingRight={1} minWidth="120px">
                        <Typography variant="fieldLabel" style={{ color: '#30466980' }} >{t('COMMON095')}</Typography>
                      </Box>
                      <Box item paddingRight={1} minWidth="25px">
                        <Typography style={{ color: '#939FB1' }}>:</Typography>
                      </Box>
                      <Box item className='textbreakk'>
                        <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}  >
                          {userData ? userData.nickName : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </Grid>
                <Divider orientation="vertical" className="MuiDividers-roots" />
                <Grid item lg={4} sm={12} paddingLeft="32px">
                  <div className='p-3' >
                    <Box display="flex" paddingTop={6}>
                      <Box item paddingRight={1} minWidth="90px">
                        <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON097')}</Typography>
                      </Box>
                      <Box item paddingRight={1} minWidth="25px">
                        <Typography style={{ color: '#939FB1' }}>:</Typography>
                      </Box>
                      <Box item className='textbreakk'>
                        <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                          {userData ? userData.gender : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div className='p-3'>
                    <Box display="flex" >
                      <Box item paddingRight={1} minWidth="90px">
                        <Tooltip title={t('COMMON042')}>
                        <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('TEMP004')}</Typography>
                        </Tooltip>
                      </Box>
                      <Box item paddingRight={1} minWidth="25px">
                        <Typography style={{ color: '#939FB1' }}>:</Typography>
                      </Box>
                      <Box item >
                        <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                          {userData ? userData.email : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div className='p-3'>
                    <Box display="flex" mb={3}>
                      <Box item paddingRight={1} minWidth="90px">
                        <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON116')}</Typography>
                      </Box>
                      <Box item paddingRight={1} minWidth="25px">
                        <Typography style={{ color: '#939FB1' }}>:</Typography>
                      </Box>
                      <Box item className='textbreakk'>
                        <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }} noWrap>
                          {userData ? userData.phone : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
