// OAuth-client
export const REACT_LOGIN = '/fams/login';
export const REACT_VERIFY_COMPANY = '/fams/verify-company';
export const REACT_LOGOUT = '/fams/login';
export const REACT_RESULT_PAGE = '/fams/result-page';

// Dashboard
export const REACT_URL_DASHBOARD = '/fams/dashboard';


//Organization
export const REACT_URI_PERSON = '/fams/person';
export const REACT_URI_UPDATE_PERSON = '/fams/update-person';
export const JAVA_API_PERSON_DEACTIVATION = '/user/deactivate';
export const JAVA_API_CHECK_IS_MEMBER_ACTIVE = '/user/';
export const REACT_URI_DEPARTMENT = '/fams/department';
export const REACT_URI_DESIGNATION = '/fams/designation';
export const REACT_URI_APPLICATIONS = '/fams/userRole';
export const REACT_URL_ADD_BRANCH ='/fams/organization/add-branch';
export const REACT_URL_BRANCH = "/fams/organization/branch";

//Settings
export const REACT_URI_BRANCH_CONFIGURATIONS = '/fams/branch-configurations';
export const REACT_URI_REQUEST_TYPE = '/fams/request-types';
export const REACT_URI_TEMPLATE_ITEMS = '/fams/template-items';
export const REACT_URI_TEMPLATES = '/fams/templates';
export const REACT_URI_APPROVAL_WORKFLOW = '/fams/approval-workflow';
export const REACT_URI_WORKFLOW_NODE = '/fams/workflow-node';
export const REACT_URI_APPROVAL_CONDITION = '/fams/approval-condition';
export const REACT_URI_MY_REQUESTS = '/fams/my-requests';
export const REACT_URI_APPROVER = '/fams/approver';


//Reports
export const REACT_URI_REPORTS = '/fams/reports';







