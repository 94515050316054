import { HIDE_LOADER, SHOW_LOADER } from "../actions/types";

const initialState = {
    loader: false,
}
export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        case HIDE_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        default:
            return state;

    }

}