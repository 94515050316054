export const GET_ERRORS = 'GET_ERRORS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

//OAuth-client
export const GET_COMPANIES = 'GET_COMPANIES';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REFRESH_TOKEN_TIMER = 'REFRESH_TOKEN_TIMER';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const GET_TIMEPERIODS = 'GET_TIMEPERIODS';
export const SUCCESS = 'SUCCESS';
export const GET_ACCESS_EMPLOYEE_LIST = 'GET_ACCESS_EMPLOYEE_LIST';
export const GET_PERSON_PRIMARY_LIST = 'GET_PERSON_PRIMARY_LIST';
export const GET_ACCESS_DEPARTMENT_LIST = 'GET_ACCESS_DEPARTMENT_LIST';
export const GET_ACCESS_LEVEL_DEPT_LIST = 'GET_ACCESS_LEVEL_DEPT_LIST';
export const GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID = 'GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID';
export const GET_ACCESS_LEVEL_DEPARTMENT_LIST = 'GET_ACCESS_LEVL_DEPARTMENT_LIST';
export const GET_ACCESS_DEPARTMENT_REMOVET = 'GET_ACCESS_DEPARTMENT_REMOVET';
export const GET_DASHBOARD_EMPLOYEE_COUNT = 'GET_DASHBOARD_EMPLOYEE_COUNT';
export const GET_DASHBOARD_NOTIFICATION_LIST = 'GET_DASHBOARD_NOTIFICATION_LIST';
export const GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT';

//--------------organization-----------------//

export const GET_DEPARTMENT_LIST = 'GET_DEPARTMENT_LIST';
export const GET_DESIGNATION_LIST = 'GET_DESIGNATION_LIST';

export const GET_RESIGNED_EMPLOYEE_LIST = 'GET_RESIGNED_EMPLOYEE_LIST';

export const GET_TEMPORARY_EMPLOYEE_LIST = 'GET_TEMPORARY_EMPLOYEE_LIST';

export const GET_PARENTID_NOT_NULL_LIST = 'GET_PARENTID_NOT_NULL_LIST';

export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
export const SAVE_DEPARTMENT = 'SAVE_DEPARTMENT';
export const SAVE_DESIGNATION = 'SAVE_DESIGNATION';

export const DELETE_DESIGNATION = 'DELETE_DESIGNATION';
export const UPDATE_DESIGNATION = 'UPDATE_DESIGNATION';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';

export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';

export const CREATE_COMPANY = 'CREATE_COMPANY';

//V2

export const LEVEL_PERSON_LIST = 'LEVEL_PERSON_LIST';
export const LEVEL_PERSON_SELECT_LIST = 'LEVEL_PERSON_SELECT_LIST';
export const LEVEL_DEPT_LIST = 'LEVEL_DEPT_LIST';
export const LEVEL_DEPT_SELECT_LIST = 'LEVEL_DEPT_SELECT_LIST';


// new organization - employee
export const EMPLOYEE_LIST = 'EMPLOYEE_LIST';
export const EMPLOYEE_ERRORS = 'EMPLOYEE_ERRORS';

// new organization - department
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const DEPARTMENT_ERRORS = 'DEPARTMENT_ERRORS';

// new organization - designation
export const DESIGNATION_LIST = 'DESIGNATION_LIST';
export const DESIGNATION_ERRORS = 'DESIGNATION_ERRORS';

// new organization - resigned employee
export const RESIGNED_EMPLOYEE_LIST = 'RESIGNED_EMPLOYEE_LIST';
export const RESIGNED_EMPLOYEE_ERRORS = 'RESIGNED_EMPLOYEE_ERRORS';
export const SET_COMPANY = 'SET_COMPANY';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_LOADER = 'SHOW_LOADER';


//fams
export const REQUEST_TYPE_LIST = 'GET_REQUEST_TYPE_LIST';

export const TEMPLATE_LIST = 'TEMPLATE_LIST';

export const BRANCH_CONFIGURATION_LIST = 'GET_ BRANCH_CONFIGURATION_LIST';

export const TEMPLATE_ITEM_LIST = 'GET_TEMPLATE_ITEM_LIST';

export const APPROVAL_WORKFLOW_LIST = 'GET_APPROVAL_WORKFLOW_LIST';

export const APPROVAL_CONDITION_LIST = 'GET_APPROVAL_CONDITION_LIST';
//settings
export const WORKFLOW_NODE_LIST="WORKFLOW_NODE_LIST";

export const MY_REQUESTS_LIST = "GET_MY_REQUESTS_LIST";

export const PENDING_MY_REQUESTS_LIST = "GET_PENDING_MY_REQUESTS_LIST";

export const COMPANY_LIST = "GET_COMPANY_LIST";

export const GET_FAMS_EMPLOYEE_LIST="GET_FAMS_EMPLOYEE_LIST";


export const GET_STATUS_REPORT_LIST ='GET_STATUS_REPORT_LIST';

export const COMPANY_BRANCH_LIST = "GET_COMPANY_BRANCH_LIST";

export const GET_FAMS_EMPLOYEE_LIST_USER_ID="GET_FAMS_EMPLOYEE_LIST_USER_ID";

export const GET_REQUESTS_STATUS_COUNTS="GET_REQUESTS_STATUS_COUNTS";

export const GET_COMPANY_BY_ID = 'COMPANY_BY_ID';