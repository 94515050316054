import { Box, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUser, updateUser } from '../../actions/ProfileApiCalls';
import defaultProfile from '../../assets/images/defaultProfile.png';
import Breadcrumb from '../../components/Breadcrumb';
import CancelButtons from '../../components/CancelButtons';
import MobileInput from '../../components/MobileInput';
import SubmitButtons from '../../components/SubmitButtons';
import CommonUtil from '../../components/Util/CommonUtils';
import setJWTToken from '../../oauth-client/setJWTToken';
import ProfileUplod from './UploadImg';
export default function UserProfileScreen() {
  const { t } = useTranslation();
  const history = useHistory();
  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    nickName: '',
    gender: '',
    email: '',
    phone: '',
    profilePhoto: '',
  });

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    nickName: false,
    gender: false,
    email: false,
    phone: false,
    profilePhoto: false,
  });

  const [profile, setProfile] = useState(defaultProfile);

  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const store = useSelector((state) => state.security);


  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );

  useEffect(() => {
    if (store.validToken && !CommonUtil.isEmptyString(store.AccJwtToken)) {
      setJWTToken(store.AccJwtToken);
    }
    dispatch(getUser(store.user.id));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store)) {
      setUserData(store.userData);
      setPayload({ ...payload, ...store.userData });
      if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + store.userData.profilePhoto);
      }
    }
    //eslint-disable-next-line
  }, [store]);
  const handleProfilePicture = (data) => {
    if (data === 'Error') {
      return;
      //eslint-disable-next-line
    }
    setUserData({ ...userData, profilePhoto: data.split(',')[1] });
  };

  useEffect(() => {
    if (!CommonUtil.isEmptyString(payload.phone)) {
      if (payload.phone.includes('-')) {
        setCountryCode(payload.phone.split('-')[0]);
        setPayload({ ...payload, phone: payload.phone.split('-')[1] });
      }
    }
    //eslint-disable-next-line
  }, [payload.phone]);

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === 'phone') {
      if (event.target.value.match(/^[0-9 ]+$/)) {
        setPayload({
          ...payload,
          [name]: event.target.value,
        });
      }
    } else {
      setPayload({
        ...payload,
        [name]: event.target.value,
      });
    }
    setError({
      ...error,
      [name]: false,
    });
  };

  const handleClickEdit = () => {
    history.push({ pathname: '/fams/user-profile' });
  }

  const breadcrumb = [
    { path: '/fams/user-profile', name: t('COMMON128') },
    { path: '/fams/user-profile-screen', name: t("PROF001") },
  ];
  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.email)) {
      setError({ ...error, email: true });
      toast.error(t('USERPROFSCREEN001'));
      return;
    }
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({ ...error, firstName: true });
      toast.error(t('USERPROFSCREEN002'));
      return;
    }
    if (!CommonUtil.isValidName(payload.firstName)) {
      setError({ ...error, firstName: true });
      toast.error(t('USERPROFSCREEN003'));
      return;
    }
    if (!CommonUtil.isValidName(payload.lastName)) {
      setError({ ...error, lastName: true });
      toast.error(t('USERPROFSCREEN004'));
      return;
    }
    if (!CommonUtil.isValidName(payload.nickName)) {
      setError({ ...error, nickName: true });
      toast.error(t('USERPROFSCREEN005'));
      return;
    }
    if (!CommonUtil.isEmptyString(payload.phone) && CommonUtil.isEmptyString(countryCode)) {
      toast.error(t('COMMON193'));
      return;
    }
    var data = {};
    if (!CommonUtil.isEmptyString(payload.phone)) {
      if (payload.phone.length < 8 || payload.phone.length > 15) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      } else {
        data = { ...payload, phone: '+' + countryCode.replace('+', '') + '-' + payload.phone };
      }
    } else {
      data = { ...payload };
      setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
    }
    dispatch(
      updateUser(
        data,
        (successData) => {
          setError({
            firstName: false,
            lastName: false,
            nickName: false,
            gender: false,
            email: false,
            phone: false,
          });
          handleClickEdit();
        },
        (error) => { }
      )
    );
  };

  useEffect(() => {
    setPayload({ ...payload, ...userData });
    if (!CommonUtil.isEmptyString(userData.profilePhoto)) {
      setProfile('data:image/jpeg;base64,' + userData.profilePhoto);
    }
    if (!CommonUtil.isEmptyString(userData.phone)) {
      if (userData.phone.includes('-')) {
        setCountryCode(userData.phone.split('-')[0]);
        setPayload({ ...userData, phone: userData.phone.split('-')[1] });
      }
    } else {
      setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
    }
    setError({
      firstName: false,
      lastName: false,
      nickName: false,
      gender: false,
      profilePhoto: false,
      email: false,
      phone: false,
    });
    //eslint-disable-next-line
  }, [userData]);
  if (!payload)
    return 'No post!';
  //eslint-disable-next-line
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

        }}
      >
        <Box>
          <Typography
            gutterBottom
            variant="h3"
            component="h2"
            color="secondary"
            style={{ paddingBottom: '15px' }}
          >
            <Breadcrumb links={breadcrumb} />
          </Typography>
        </Box>
        <Box textAlign='left' style={{ paddingBottom: '15px' }}>
          <div style={{ backgroundColor: '#F2F2F6' }}>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>{t('COMMON008')}</Typography>}>
              <IconButton onClick={handleClickEdit} >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      </Box>
      <Paper className='css-pepar-in-userprofile'>
        <Grid container p={5} spacing={3}>
          <Grid container justifyContent="center" alignItems='center' md={4} sm={12}>
            <Grid xl={6} lg={10} md={8} sm={4}>
              <ProfileUplod profilePhoto={profile} onsetProfile={(data) => handleProfilePicture(data)} border='dashed' heightimage='225px' />
            </Grid>
          </Grid>

          <Grid container md={8} sm={12} spacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  className='m-0 mb-2'
                  size="large"
                  label={t('COMMON020') + '*'}
                  error={error.firstName}
                  variant='outlined'
                  placeholder={t('USERPROFSCREEN007')}
                  helperText=''
                  type='text'
                  name='firstName'
                  fullWidth
                  value={payload.firstName}
                  onChange={handleChange}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  className='m-0 mb-2'
                  size="large"
                  label={t('COMMON021')}
                  error={error.lastName}
                  variant='outlined'
                  placeholder={t('USERPROFSCREEN008')}
                  helperText=''
                  type='text'
                  name='lastName'
                  fullWidth
                  value={payload.lastName}
                  onChange={handleChange}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  className='m-0 mb-2'
                  size="large"
                  label={t('COMMON095')}
                  error={error.nickName}
                  variant='outlined'
                  placeholder={t('COMMON095')}
                  helperText=''
                  type='text'
                  name='nickName'
                  fullWidth
                  value={payload.nickName}
                  onChange={handleChange}

                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <FormControl variant='outlined' size="large" fullWidth>
                  <InputLabel>{t('COMMON097')}</InputLabel>
                  <Select

                    fullWidth
                    className='m-0 mb-2 text-left'
                    helperText=''
                    label={t('COMMON097')}
                    name='gender'
                    value={payload.gender}
                    onChange={handleChange}
                    error={error.companySize}
                  >
                    <MenuItem value={'M'}>{t('COMMON103')}</MenuItem>
                    <MenuItem value={'F'}>{t('COMMON104')}</MenuItem>
                    <MenuItem value={'O'}>{t('others')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  className='m-0 mb-2'
                  size="large"
                  label={t('COMMON042') + '*'}
                  variant='outlined'
                  error={error.email}
                  placeholder={t('COMPPROFSCREEN018')}
                  helperText=''
                  type='email'
                  name='email'
                  fullWidth
                  value={payload.email}
                  disabled
                  onChange={handleChange}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>

            <MobileInput
              specialLabel={t('COMMON116')}
              error={error.phone}
              countryCode={countryCode}
              phone={payload.phone}
              enableSearch='true'
              countryCodeEditable={true}
              searchNotFound={t('SEARCH01')}
              searchPlaceholder={t('SEARCH02')}
              onMobileNoChange={(phone) => {
                setError({ phone: false });
                setPayload({ ...payload, phone: phone });
              }}
              onCounryCodeChange={(code) => setCountryCode(code)}
            />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent="flex-end" alignItems='center' display='flex'>
          <div className='p-1'>
            <CancelButtons color="primary" variant="outlined" className='m-3' onClick={() => history.push({ pathname: '/fams/user-profile' })} >
              {t('COMMON008')}
            </CancelButtons>
            <SubmitButtons
              onClick={() => handleSubmit()}
              color="primary" variant="contained" className='m-3'
            >
              {t('COMMON052')}
            </SubmitButtons>
          </div>
        </Grid>
      </Paper>
    </>
  );
}
