import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const DectivatePersonIcon = () => {
    const [isShown, setIsShown] = React.useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Tooltip title={t("COMMON200")}>
                <Grid
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    {isShown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="23.836" viewBox="0 0 25.5 23.836">
                            <g id="Group_8753" data-name="Group 8753" transform="translate(-1622.25 -273.085)">
                                <path id="Subtraction_4" data-name="Subtraction 4" d="M24546.688,11231.839h-12.379a3.559,3.559,0,0,1-2.711-1.256l2.066-1.464a1.056,1.056,0,0,0,.645.218h12.379a1.061,1.061,0,0,0,1.061-1.06,6.448,6.448,0,0,0-4.314-6.08l2.344-1.661a8.987,8.987,0,0,1,4.473,7.741A3.567,3.567,0,0,1,24546.688,11231.839Zm-15.064-7.405,0,0a9.017,9.017,0,0,1,5.143-4.6,6.5,6.5,0,1,1,10.176-6.245l-2.539,1.8a4,4,0,1,0-4.377,3.1l-8.4,5.95Z" transform="translate(-22905 -10934.918)" fill="#304669" />
                                <path id="Line_18" data-name="Line 18" d="M1,17.25a1.25,1.25,0,0,1-.724-2.27l23-16a1.25,1.25,0,0,1,1.743.3,1.25,1.25,0,0,1-.3,1.743l-23,16A1.244,1.244,0,0,1,1,17.25Z" transform="translate(1622.5 278.5)" fill="#304669" />
                            </g>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="23.836" viewBox="0 0 25.5 23.836">
                            <g id="Group_8752" data-name="Group 8752" transform="translate(-1622.25 -273.085)">
                                <path id="Subtraction_4" data-name="Subtraction 4" d="M24546.688,11231.839h-12.379a3.559,3.559,0,0,1-2.711-1.256l2.066-1.464a1.056,1.056,0,0,0,.645.218h12.379a1.061,1.061,0,0,0,1.061-1.06,6.448,6.448,0,0,0-4.314-6.08l2.344-1.661a8.987,8.987,0,0,1,4.473,7.741A3.567,3.567,0,0,1,24546.688,11231.839Zm-15.064-7.405,0,0a9.017,9.017,0,0,1,5.143-4.6,6.5,6.5,0,1,1,10.176-6.245l-2.539,1.8a4,4,0,1,0-4.377,3.1l-8.4,5.95Z" transform="translate(-22905 -10934.918)" fill="rgba(48,70,105,0.9)" />
                                <path id="Line_18" data-name="Line 18" d="M1,17.25a1.25,1.25,0,0,1-.724-2.27l23-16a1.25,1.25,0,0,1,1.743.3,1.25,1.25,0,0,1-.3,1.743l-23,16A1.244,1.244,0,0,1,1,17.25Z" transform="translate(1622.5 278.5)" fill="rgba(48,70,105,0.9)" />
                            </g>
                        </svg>
                    )}
                </Grid>
            </Tooltip>
        </>
    );
};
export default DectivatePersonIcon;
