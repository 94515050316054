import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const CopyIcon = (props) => {
    const [isShown, setIsShown] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Tooltip title={t("COPY")}>
                {props.disabled === true ? <Grid>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.781" height="24.063" viewBox="0 0 20.781 24.063">
                        <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M18.313,1.5H5.188A2.194,2.194,0,0,0,3,3.688V19H5.188V3.688H18.313Zm3.281,4.375H9.563A2.194,2.194,0,0,0,7.375,8.063V23.375a2.194,2.194,0,0,0,2.188,2.188H21.594a2.194,2.194,0,0,0,2.188-2.187V8.063A2.194,2.194,0,0,0,21.594,5.875Zm0,17.5H9.563V8.063H21.594Z" transform="translate(-3 -1.5)" fill="#304669" opacity="0.6" />
                    </svg>
                </Grid> :
                    <Grid
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                    >
                        {isShown ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.781" height="24.063" viewBox="0 0 20.781 24.063">
                                <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M18.313,1.5H5.188A2.194,2.194,0,0,0,3,3.688V19H5.188V3.688H18.313Zm3.281,4.375H9.563A2.194,2.194,0,0,0,7.375,8.063V23.375a2.194,2.194,0,0,0,2.188,2.188H21.594a2.194,2.194,0,0,0,2.188-2.187V8.063A2.194,2.194,0,0,0,21.594,5.875Zm0,17.5H9.563V8.063H21.594Z" transform="translate(-3 -1.5)" fill="#304669" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.781" height="24.063" viewBox="0 0 20.781 24.063">
                                <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M18.313,1.5H5.188A2.194,2.194,0,0,0,3,3.688V19H5.188V3.688H18.313Zm3.281,4.375H9.563A2.194,2.194,0,0,0,7.375,8.063V23.375a2.194,2.194,0,0,0,2.188,2.188H21.594a2.194,2.194,0,0,0,2.188-2.187V8.063A2.194,2.194,0,0,0,21.594,5.875Zm0,17.5H9.563V8.063H21.594Z" transform="translate(-3 -1.5)" fill="rgba(48,70,105,0.9)" />
                            </svg>
                        )}
                    </Grid>
                }
            </Tooltip>
        </>
    );
};
export default CopyIcon;
