import axios from 'axios';
import { SET_CURRENT_USER, GET_COMPANIES } from '../actions/types';
import setJWTToken from './setJWTToken';
import jwt_decode from 'jwt-decode';
import { REACT_LOGIN, REACT_URL_DASHBOARD, REACT_VERIFY_COMPANY } from '../actions/EndPoints';

export const redirectLogin = (history) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v1.0/loginUrl');
    window.location.assign(res ? res.data.data : '/fams/login');
  } catch (error) {}
};

export const getAccessToken = (code, history) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v1.0/token?code=' + code);

    if (res.status === 200) {
      //const token = res.data.data;
      const token = res.data.data.accessToken;
      // store the token in the localStorage
      localStorage.setItem("temp_token", token);
      // set our token in header ***
      setJWTToken(token);
      // decode token on React
      const decoded = jwt_decode(token);
      localStorage.setItem('companyId', decoded.id);

      // dispatch to our securityReducer
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      if (localStorage.getItem('companyCode') === '' || localStorage.getItem('companyCode') === null) {
        history.push({
          pathname: REACT_VERIFY_COMPANY,
        });
      } else {
        try {
          var companyCode = localStorage.getItem('companyCode');
          const res = await axios.put('/api/v1.0/verifyCompany?companyCode=' + companyCode);

          if (res.status === 200) {
            //const token = res.data.data;
            const token = JSON.stringify(res.data.data);
            // store the token in the localStorage
            localStorage.setItem("access_token", res.data.data);
            // set our token in header ***
            setJWTToken(token);
            localStorage.setItem('companyCode', companyCode);
            // decode token on React
            const decoded = jwt_decode(token);
            localStorage.setItem('companyId', decoded.companyId);

            // dispatch to our securityReducer
            dispatch({
              type: SET_CURRENT_USER,
              payload: decoded,
            });
            history.push({
              pathname: REACT_URL_DASHBOARD,
            });
          } else {
            history.push({
              pathname: '/',
            });
          }
        } catch (error) {
          localStorage.removeItem('companyCode');
          localStorage.removeItem('companyName');
          localStorage.removeItem('companyId');
          localStorage.removeItem("access_token");
          history.push({
            pathname: '/',
          });
        }
      }
    } else {
      history.push({
        pathname: '/',
      });
    }
  } catch (error) {
    localStorage.removeItem('companyCode');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyId');
    localStorage.removeItem("access_token");
    history.push({
      pathname: '/',
    });
  }
};

export const verifyCompany = (companyCode, history) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v1.0/verifyCompany?companyCode=' + companyCode);

    if (res.status === 200) {
      //const token = res.data.data;
      const token = JSON.stringify(res.data.data);
      // store the token in the localStorage
      localStorage.setItem("access_token", res.data.data);
      // set our token in header ***
      localStorage.setItem('companyCode', companyCode);
      // decode token on React
      const decoded = jwt_decode(token);
      localStorage.setItem('companyId', decoded.companyId);
      setJWTToken(token);

      // dispatch to our securityReducer
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      history.push({
        pathname: REACT_URL_DASHBOARD,
      });
    } else {
      history.push({
        pathname: '/',
      });
    }
  } catch (error) {
    alert('Invalid Company Code');
  }
};

export const getCompanyList = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/v1.0/companyList');

    dispatch({
      type: GET_COMPANIES,
      payload: res.data.data,
    });
  } catch (error) {}
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem('companyCode');
  localStorage.removeItem('companyId');
  localStorage.removeItem("temp_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  axios.get('/user/revoke');
  setInterval(function() {
    setJWTToken(false);
    window.location.href = REACT_LOGIN;
  }, 1000);
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
};
