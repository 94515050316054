import { Grid, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeListByUserId } from '../../../actions/approvalCondition/ApprovalConditionActions';
import Breadcrumb from '../../../components/Breadcrumb';
import DataTable from '../../../components/DataTableNew';
import CommonUtil from '../../../components/Util/CommonUtils';

export default function Membership() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const breadcrumb = [{ path: '', name: t('USERROLE0001') }];
  useEffect(() => {
    if (!CommonUtil.isEmptyString(store.security)) {
      loadData(page, rowsPerPage);
    }
    //eslint-disable-next-line
  }, [store.security.user.client_id]);

  const loadData = (page, rowsPerPage) => {
    dispatch(getEmployeeListByUserId(page, rowsPerPage, ''));
  };

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.employeeV2.employeeUserIdList)) {
      setPage(store.employeeV2.employeeUserIdList.data.currentPage - 1);
      setData(store.employeeV2.employeeUserIdList.data.employee);
      setTotalPages(store.employeeV2.employeeUserIdList.data.totalPages);
    } else {
      setPage(0);
      setData([]);
    }
  }, [store.employeeV2.employeeUserIdList]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.userRole.userFilterList)) {
      setPage(store.userRole.userFilterList.currentPage - 1);
      setData(store.userRole.userFilterList.employee);
      setTotalPages(store.userRole.userFilterList.totalPages)
    } else {
      setPage(0);
      setData([]);
    }
  }, [store.userRole.userFilterList]);

  const handleChangeRowsPerPage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size);
  };
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: 'firstName',
      headerName: t('MEMBER002'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.firstName + '\n' + params.row.employeeCode,
      renderCell: (params) => (
        <Tooltip title={params.row.firstName + '\n' + params.row.employeeCode} >
          <span className="table-cell-trucate">{params.row.firstName + '\n' + params.row.employeeCode}</span>
        </Tooltip>
      ),
    },
    {
      field: 'email',
      headerName: t('MEMBER003'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.email + '\n' + params.row.phone,
      renderCell: (params) => (
        <Tooltip title={params.row.email + '\n' + params.row.phone} >
          <span className="table-cell-trucate">{params.row.email + '\n' + params.row.phone}</span>
        </Tooltip>
      ),
    },
    {
      field: 'roleName',
      headerName: t('MEMBER004'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.roleName?params.row.roleName:'Inactive',
      renderCell: (params) => (
        <Tooltip title={params.row.roleName?params.row.roleName:'Inactive'} >
          <span className="table-cell-trucate">{params.row.roleName?params.row.roleName:'Inactive'}</span>
        </Tooltip>
      ),
    },
  ]);

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
      </Breadcrumb>
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={data}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChangeRowsPerPage(newPage, size)}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </Grid>
  );
}
