import axios from "axios";
import { toast } from "react-toastify";
import { JAVA_API_APPROVAL_CONDITION, JAVA_API_APPROVAL_CONDITION_LIST, JAVA_API_EMPLOYEE_LIST_USER_ID } from "../BackendEndPoints";
import { APPROVAL_CONDITION_LIST, GET_FAMS_EMPLOYEE_LIST_USER_ID } from "../types";

const buildQuery = (filter) => {
  let query = '';
  if (filter.firstName) {
    query = `&firstName=${filter.firstName}`;
  }
  if (filter.email) {
    query = query + `&email=${filter.email}`;
  }
  if (filter.requestTypeName) {
    query = query + `&requestTypeName=${filter.requestTypeName}`;
  }
  if (filter.employeeEmail) {
    query = query + `&employeeEmail=${filter.employeeEmail}`;
  }
  return query;
}

export const addApprovalCondition = (data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.post(JAVA_API_APPROVAL_CONDITION, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const updateApprovalCondition = (id, data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.put(JAVA_API_APPROVAL_CONDITION + '/' + id, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess();
    } else {
      onFail(res.data);
    }
  } catch (error) {
    toast.error();
  }
};


export const getApprovalConditionList = (pageNumber, pageSize, filter) => async (dispatch) => {
  if (filter === undefined || filter === '') {
    filter = {
      requestTypeName: '',
      employeeEmail: ''
    }
  }

  const res = await axios.get(JAVA_API_APPROVAL_CONDITION_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
  if (res.data.code === 'FHRMSI0001') {
    dispatch({
      type: APPROVAL_CONDITION_LIST,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: APPROVAL_CONDITION_LIST,
      payload: [],
    });
  }
};

export const deleteApprovalCondition = (id, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.delete(JAVA_API_APPROVAL_CONDITION + '/' + id);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess();
    } else {
      onFail(res.data);
    }
  } catch (error) {
    toast.error();
  }
};

export const getEmployeeListByUserId = (pageNumber, pageSize, filter) => async (dispatch) => {
  if (filter === undefined || filter === '') {
    filter = {
      firstName: '',
      email: '',
    }
  }

  const res = await axios.get(JAVA_API_EMPLOYEE_LIST_USER_ID + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
  if (res.data.code === 'FHRMSI0001') {
    dispatch({
      type: GET_FAMS_EMPLOYEE_LIST_USER_ID,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_FAMS_EMPLOYEE_LIST_USER_ID,
      payload: [],
    });
  }
};