import axios from 'axios';
import { JAVA_API_MY_REQUESTS, JAVA_API_REQUESTS_STATUS_COUNTS } from '../BackendEndPoints';
import { GET_REQUESTS_STATUS_COUNTS, MY_REQUESTS_LIST, PENDING_MY_REQUESTS_LIST } from '../types';
import CommonUtil from '../../components/Util/CommonUtils';

function buildQuery(filter) {
  let query = '';
  if (filter.requestTypeName) {
    query = query + `&requestTypeName=${filter.requestTypeName}`;
  }
  if (filter.createdDate) {
    query = query + `&createdDate=${CommonUtil.formatToUtc(filter.createdDate)}`;
  }
  return query;
}

const isIdExist = (data) => {
  if (data === undefined)
    return '';
  return data;
}

export const addRequest = (file, payload, onSuccess, onFail) => async (dispatch) => {
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  };

  const res = await axios.post(JAVA_API_MY_REQUESTS + "?requestTypeId=" + payload.requestTypeId + "&templateId=" + payload.templateId + "&requestDetails=" + payload.requestDetails + "&id=" + isIdExist(payload.id) + "&name=" + payload.name + "&status=" + payload.status + "&description=" + payload.description + "&objectKey=" + payload.objectKey, file, file !== '' ? header : null);
  try {
    if (res.data.code === 'FHRMSI0004' || res.data.code === 'FHRMSI0005') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const getMyRequestsList = (pageNumber, pageSize) => async (dispatch) => {
  const res = await axios.get(JAVA_API_MY_REQUESTS + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
  try {
    if (res.data.code === 'FHRMSI0001') {
      dispatch({
        type: MY_REQUESTS_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: MY_REQUESTS_LIST,
        payload: [],
      });
    }
  } catch (error) {
  }
};

export const cancelMyRequest = (id, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.put(JAVA_API_MY_REQUESTS + '/' + id);
  try {
    if (res.data.code === 'FHRMSE0069') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const getPendingMyRequestsList = (pageNumber, pageSize, filter) => async (dispatch) => {
  if (filter === undefined || filter === '') {
    filter = {
      requestTypeName: '',
      createdDate: ''
    }
  }
  const res = await axios.get(JAVA_API_MY_REQUESTS + '/pending?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
  try {
    if (res.data.code === 'FHRMSI0001') {
      dispatch({
        type: PENDING_MY_REQUESTS_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: PENDING_MY_REQUESTS_LIST,
        payload: [],
      });
    }
  } catch (error) {
  }
};


export const updateRequest = (id, payload, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.put(JAVA_API_MY_REQUESTS + '/auditLog/' + id + '?status=' + payload.status + '&remarks=' + payload.remarks);
  try {
    if (res.data.code === 'FHRMSE0078') {
      onSuccess(res.data);
    }
    else if (res.data.code === 'FHRMSE0077') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const getStatusCounts = () => async (dispatch) => {
  const res = await axios.get(JAVA_API_REQUESTS_STATUS_COUNTS);

  try {
    if (res.data.code === 'FHRMSI0001') {
      dispatch({
        type: GET_REQUESTS_STATUS_COUNTS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_REQUESTS_STATUS_COUNTS,
        payload: [],
      });
    }
  } catch (error) {
  }
};
