import { BRANCH_CONFIGURATION_LIST } from "../../actions/types";

const initialState = {
    branchConfigurationList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BRANCH_CONFIGURATION_LIST:
            return {
                ...state,
                branchConfigurationList: action.payload
            };
        default:
            return state;
    }
}