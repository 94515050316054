import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_LOGIN } from '../../actions/EndPoints';
import forgetImg from '../../assets/images/onboard/Group 186.svg';
import forgetImgTitle from '../../assets/images/onboard/Group 51.svg';
import forgetImgm from '../../assets/images/onboard/Group 8371.svg';
import CustomTextfield from '../../components/CustomTextField';
import CommonUtil from '../../components/Util/CommonUtils';
import { forgotPassword } from '../../oauth-client/Onboard';
import './custom.css';
import LoginLayout from './LoginLayout';
import SubmitButtons from '../../components/SubmitButtons';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
  label: {
    color: '#999',
    fontSize: '12px',
    marginLeft: '20px',
    textAlign: 'start',
  },
  labelActive: {
    color: '#4C6A9F',
    fontSize: '12px',
    margin: '10px'
  },
  email_icon: {
    height: '10%',
    width: '15%',
  },
}));
function ForgotPasword(props) {
  const classes = useStyles();
  const [email, setemail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setemail(event.target.value);
  };

  const handleSubmit = (event) => {
    if (CommonUtil.isEmptyString(email)) {
      toast.error(t('COMMON168'));
      setError(true);
      event.preventDefault();
      return;
    } else {
      dispatch(
        forgotPassword(
          email,
          () => {
            setSuccess(true);
          },
          (error) => {
            setError(true);
          }
        )
      );
      event.preventDefault();
    }
  };
  const { t } = useTranslation();
  const query = new URLSearchParams(props.location.search);

  return !CommonUtil.isEmptyString(query.get('code')) ? (
    <></>
  ) : (
    <LoginLayout login={forgetImg} disableLeftpage={success} >
      <Grid container className='center_div' >
        <Grid container className={classes.form}>
          <Grid item xs={12} md={12} >
            <Typography align='center' className='signin_title_image'>
              {success ?
                <img src={forgetImgm} alt='logo' style={{ width: '250px' }} /> :
                <Grid item xs={12} md={12} display='flex' justifyContent='center' className='signin_title_'>
                  <img src={forgetImgTitle} alt='logo' style={{ width: '150px', marginBottom: '30px', marginTop: '20px' }} />
                </Grid>}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}  >
            <Typography className='signin_title' variant='h1' align='center'  >
              {success ? <Typography style={{ color: '#3D4977', fontSize: '26px' }}>{t('COMMON141')}</Typography> : <Typography className='signin_title' variant='h1' align='left' style={{ marginLeft: '42px' }}>{t('COMMON137')}</Typography>}
            </Typography>
            <Grid item xs={12} >
              {success ? (
                <Typography variant='h3' className={classes.label} color='#30466987' style={{ marginBottom: '5px', textAlign: 'center' }}  >
                  {t('Forget001')}
                </Typography>
              ) : (
                <Grid item xs={12} md={12} style={{ textAlign: 'center', marginLeft: '25px' }} >
                  <Typography variant='h3' className={classes.label} color='#30466987' >
                    {t('COMMON143')}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {!success ? (
          <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
            <Grid container alignItems='center'>
              <Grid item>
                <PersonIcon className='login_icons' />
              </Grid>
              <Grid item xs>
                <CustomTextfield
                  error={error}
                  className='login_input'
                  size='medium'
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  validation='email'
                  label={t('COMMON042')}
                  autoComplete='off'
                  autoFocus
                  helperText={error.email}
                  name='email'
                  type={'text'}
                  value={email}
                  isValid={true}
                  handleChange={(e) => { handleChange(e); setError(false) }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={5} alignItems='center' style={{ marginTop: '5px', }}>
              <Grid item></Grid>
              <Grid item xs>
                <SubmitButtons type='submit' fullWidth variant='contained' color='primary' className={classes.submit} size='medium'>
                  {t('COMMON144')}
                </SubmitButtons>
              </Grid>
            </Grid>

            <Grid className='signin_title' variant='h1' align='center' style={{ marginLeft: '34px', marginTop: '10px' }}>
              <Button color='primary' style={{ backgroundColor: '#ffffff' }} className='forgot_password' href={REACT_LOGIN} size={'small'}>
                <Typography style={{ color: '#304669', opacity: 0.8, marginRight: '4px', }}>{t('SIGN002')}</Typography>
                <Typography >{t('COMMON133')}</Typography>
              </Button>
            </Grid>
          </form>
        ) : (
          <Grid className='signin_title' variant='h1' align='left' >
            {success ? <SubmitButtons type='submit' fullWidth variant='contained' color='primary' href={REACT_LOGIN} className={classes.submit} size='medium' style={{ width: '350px', height: '20px' }}>
              {t('COMMON133')}
            </SubmitButtons> : <></>}
          </Grid>
        )}
      </Grid>
    </LoginLayout>
  );
}
export default withRouter(ForgotPasword);
