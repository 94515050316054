import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URI_DEPARTMENT } from '../../../actions/EndPoints';
import { getDepartments, updateDepartment } from '../../../actions/organization/DepartmentApiCalls';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
  customContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 4px #00000029',
    border: '0.5px solid #00000029',
    opacity: 1,
    marginBottom: '5px',
    borderRadius: '14px',
    padding: '18px',
    margin: '24px',
    height: "auto",
    [theme.breakpoints.down("lg")]: {

      height: "auto",
    },
  },
}));

export default function AddDepartment(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state);
  const [parentDepartments, setParentDepartments] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.history.location.state ? props.history.location.state?.id : '',
    name: props.history.location.state ? props.history.location.state?.name : '',
    code: props.history.location.state ? props.history.location.state?.code : '',
    description: props.history.location.state ? props.history.location.state?.description : '',
    parentDepartment: props.history.location.state.parentDepartment ? props.history.location.state?.parentDepartment : '',
  });

  const [error, setError] = React.useState({
    name: false,
    code: false,
    description: false,
    parentDepartment: false,
  });

  useEffect(() => {
    dispatch(
      getDepartments(1, 0, (data) => {
        setParentDepartments(data.department);
      })
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.departmentError)) {
      const { departmentError } = store.errors;
      switch (departmentError.code) {
        case 'OMSE0002':
          setError({ name: true });
          break;
        case 'OMSE0001':
          setError({ code: true });
          break;
        default:
      }
    }
    // eslint-disable-next-line
  }, [store.errors.departmentError]);
  const handleClose = (event) => {
    history.push(REACT_URI_DEPARTMENT)
  }
  const breadcrumb = [
    { path: REACT_URI_DEPARTMENT, name: t('DEPT001') },
    { path: '/fams/create-department', name: props.history.location.state?.id === undefined ? t('DEPT002') : t("DEPT008") },
  ];
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let deptObj = {};
      let parentObj = {};
      deptObj.id = state.id;
      deptObj.name = state.name;
      deptObj.code = state.code;
      deptObj.description = state.description;
      parentObj.id = state.parentDepartment.id;
      deptObj.parentDepartment = parentObj;
      dispatch(
        updateDepartment(deptObj, (data) => {
          handlError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.name) && CommonUtil.isEmptyString(state.code)) {
      setError({ name: true });
      toast.error(t('DEPT011'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({ code: true });
      toast.error(t('DEPT010'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('DEPT011'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.name)) {
      setError({ name: true });
      toast.error(t('DEPT013'));
      return false;
    }
    if (!CommonUtil.isValidCode(state.code)) {
      setError({ code: true });
      toast.error(t('DEPT012'));
      return false;
    }
    return true;
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'OMSI0001':
        history.push(REACT_URI_DEPARTMENT);
        break;
      case 'OMSW0001':
        data = data.data.error[0];

        break;
      case 'OMSI0002':
        history.push(REACT_URI_DEPARTMENT);
        break;
      default:
        break;
    }

    switch (data.code) {
      case 'OMSE0002':
        setError({ name: true });
        break;
      case 'OMSE0001':
        setError({ code: true });
        break;
      case 'OMSE0077':
        setError({ code: true });
        break;
      case 'OMSE0078':
        setError({ name: true });
        break;
      case 'OMSE0283':
        setError({ parentDepartment: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0001' || data.code === 'OMSI0002') {
      toast.success(data.message);
      handleClose();
    } else if (data.code === 'OMSE0077') {
      toast.error(t('DEPT014'));

    } else if (data.name === 'OMSE0209') {
      toast.error(t('ORGDEPT0003'));
    } else if (data.code === 'OMSE0256') {
      toast.error(t("DEPT020"));
    } else {
      toast.error(data.message);
    }
  };

  return (
    <div>
      <Grid  >
        <Breadcrumb links={breadcrumb} />
        <Grid fullWidth className={`${classes.customContainer} background-size`}>
          <Grid container sx={12} xs={12} alignItems="center">
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  required
                  error={error.name}
                  name='name'
                  label={t('COMMON044')}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  defaultValue={state.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 49 }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  required
                  error={error.code}
                  name='code'
                  label={t('COMMON045')}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  defaultValue={state.code}
                  onChange={handleChange}
                  inputProps={{ maxLength: 29 }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={12} xs={12} alignItems="center">
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={'20px'}>
                <Autocomplete
                  style={{ width: '100%' }}
                  openText={t('COMMON169')}
                  closeText={t('COMMON170')}
                  clearText={t('COMMON215')}
                  noOptionsText={t('COMMON214')}
                  options={parentDepartments}
                  name={state.parentDepartment}
                  value={state.parentDepartment}
                  getOptionLabel={(parentDepartmentss) => (parentDepartmentss.name ? parentDepartmentss.name : '')}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      setState({ ...state, parentDepartment: newValue });
                    } else {
                      setState({ ...state, parentDepartment: '' });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} value={state.parentDepartment} name={state.parentDepartment} error={error.parentDepartment} variant='outlined' size="medium" fullWidth label={t('COMMON050')} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  size='medium'
                  inputProps={{ className: classes.textarea, maxLength: 140 }}
                  label={t('DEPT009')}
                  variant='outlined'
                  aria-label='maximum height'
                  value={state.description}
                  name='description'
                  onChange={handleChange}
                  multiline
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box display="flex" m={1} p={1}>
              <CancelButtons onClick={handleClose} color='primary' variant='outlined' size='small' className='mr-3'>
                {t('COMMON008')}
              </CancelButtons>
              <SubmitButtons onClick={handleSubmit} color='primary' variant='contained' size='small' className='ml-3'>
                {t('COMMON035')}
              </SubmitButtons>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
