import { Box, Grid, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getCompaniesByPartnerTypeANDActive } from '../../../actions/branch/BranchActions';
import { addBranchConfiguration, updateBranchConfiguration } from '../../../actions/branchConfiguration/BranchConfigurationActions';
import { REACT_URI_BRANCH_CONFIGURATIONS } from '../../../actions/EndPoints';
import { getRequestTypeList } from '../../../actions/requestType/RequestTypeActions';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
	number: {
		"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			margin: 0
		}
	}, customContainer: {
		backgroundColor: '#ffffff',
		boxShadow: '0px 0px 4px #00000029',
		border: '0.5px solid #00000029',
		opacity: 1,
		marginBottom: '5px',
		borderRadius: '14px',
		padding: '18px',
		margin: '24px',
		height: "700px",
		[theme.breakpoints.down("lg")]: {

			height: "400px",
		},
	},
}));
export default function AddBranchConfiguration(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const store = useSelector((state) => state);
	const history = useHistory();
	const dispatch = useDispatch();
	const [companies, setCompanies] = React.useState([]);
	const [requestTypes, setRequestTypes] = React.useState([]);
	const [state, setState] = React.useState({
		id: '',
		companyId: '',
		approvalCriteria: '',
		requestTypeId: '',
	});
	const invalidChars = ['-', '+', 'e'];
	useEffect(() => {
		setState({
			id: props.history.location.state ? props.history.location.state?.id : '',
			companyId: props.history.location.state.companyId ? props.history.location.state?.companyId : '',
			approvalCriteria: props.history.location.state.approvalCriteria ? props.history.location.state.approvalCriteria : '',
			requestTypeId: props.history.location.state.requestTypeId ? props.history.location.state?.requestTypeId : "",
		});
	}, [props.history.location.state]);
	const [error, setError] = React.useState({
		approvalCriteria: false,
		requestTypeId: false,
		companyId: false,
	});
	const handleClose = () => {
		history.push(REACT_URI_BRANCH_CONFIGURATIONS)
	};
	const breadcrumb = [
		{ path: REACT_URI_BRANCH_CONFIGURATIONS, name: t('BRANCHCONFIG0001') },
		{ path: '/fams/create-branchConfiguration', name: props.history.location.state?.id === undefined ? t("BRANCHCONFIG0002") : t("BRANCHCONFIG0013") },
	];

	const handleChange = (event) => {
		const name = event.target.name;
		if (name !== 'approvalCriteria') {
			setState({
				...state,
				[name]: event.target.value,
			});
		}
		if (name === 'approvalCriteria' && event.target.value.length <= 10) {
			setState({
				...state,
				[name]: event.target.value,
			});
		}
		setError({
			[name]: false,
		});
	};

	useEffect(() => {
		loadCompanies();
		loadRequestTypes();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!CommonUtil.isEmpty(store.requestType.requestTypeList)) {
			setRequestTypes(store.requestType.requestTypeList.request_types);
		} else {
			setRequestTypes([]);
		}

	}, [store.requestType.requestTypeList]);

	useEffect(() => {
		if (!CommonUtil.isEmpty(store.branch.companyBranchList)) {
			setCompanies(store.branch.companyBranchList.company);
		} else {
			setCompanies([]);
		}
	}, [store.branch.companyBranchList]);

	const loadCompanies = () => {
		const filter = {
			operator: 'AND',
			type: 'PARTNER',
			partnerType: 'BRANCH',
			status: 'ACTIVE'
		}
		dispatch(getCompaniesByPartnerTypeANDActive(1, 0, filter));
	}

	const loadRequestTypes = () => {
		dispatch(getRequestTypeList(1, 0, ''));
	}

	const handleSubmit = () => {
		if (CommonUtil.isEmptyString(state.companyId)) {
			setError({ companyId: true })
			toast.error(t("BRANCHCONFIG0008"))
		} else if (CommonUtil.isEmptyString(state.requestTypeId)) {
			setError({ requestTypeId: true })
			toast.error(t("BRANCHCONFIG0006"))
		} else if (CommonUtil.isEmptyString(state.approvalCriteria)) {
			setError({ approvalCriteria: true })
			toast.error(t("APPROVALCONDITION0005"))
		} else if (!CommonUtil.isValidNumeric(state.approvalCriteria)) {
			setError({ approvalCriteria: true })
			toast.error(t("APPROVALCONDITION0009"))
		} else {
			const update = {
				approvalCriteria: state.approvalCriteria,
				requestTypeId: state.requestTypeId.id,
				companyId: state.companyId.id,
			}
			if (!CommonUtil.isEmptyString(state.id)) {
				dispatch(updateBranchConfiguration(state.id, update,
					() => {
						handleClose();
						toast.success(t('BRANCHCONFIG0010'));
					},
					(error) => {
						if (error.code === "FHRMSE0104") {
							setError({ approvalCriteria: true });
						}
						toast.error(error.message);
					}
				));
			} else {
				dispatch(addBranchConfiguration(update, () => {
					handleClose();
					toast.success(t('BRANCHCONFIG0009'));
				},
					(error) => {
						if (error.code) {
							switch (error.code) {
								case 'FHRMSE0072':
									setError({ requestTypeId: true });
									break;
								case 'FHRMSE0104':
									setError({ approvalCriteria: true });
									break;
								default:
									setError({ ...error });
							}
							toast.error(error.message);
						}
					}
				));
			}
		}
	}

	return (
		<>
			<Grid   >
				<Breadcrumb links={breadcrumb} />
				<Grid fullWidth className={`${classes.customContainer} background-size`}>
					<Grid container sx={12} xs={12} alignItems="center">
						<Grid item xs={6} sm={6}>
							<Box display='flex' justifyContent='center' m={1} p={1}>
								<Autocomplete
									openText={t('COMMON169')}
									closeText={t('COMMON170')}
									clearText={t('COMMON215')}
									noOptionsText={t('COMMON214')}
									style={{ width: '100%' }}
									options={companies}
									name={state.companyId}
									value={state.companyId}
									getOptionLabel={(company) => (company.name ? company.name : '')}
									onChange={(event, newValue) => {
										if (!CommonUtil.isEmpty(newValue)) {
											setState({ ...state, companyId: newValue });

										} else {
											setState({ ...state, companyId: '' });
										}
									}}
									renderInput={(params) => (
										<TextField {...params} value={state.companyId} name={state.companyId} error={error.companyId} variant='outlined' size="medium" fullWidth label={t('BRANCHCONFIG0007') + '*'} />
									)}
								/>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6}>
							<Box display='flex' justifyContent='center' m={1} p={1}>
								<Autocomplete
									openText={t('COMMON169')}
									closeText={t('COMMON170')}
									clearText={t('COMMON215')}
									noOptionsText={t('COMMON214')}
									style={{ width: '100%' }}
									options={requestTypes.filter(request => request.name.toUpperCase().includes('HR') ? null : request.name)}
									name={state.requestTypeId}
									value={state.requestTypeId}
									getOptionLabel={(requestType) => (requestType.name ? requestType.name : '')}
									onChange={(event, newValue) => {
										if (!CommonUtil.isEmpty(newValue)) {
											setState({ ...state, requestTypeId: newValue });
											setError({ requestTypeId: false });
										} else {
											setState({ ...state, requestTypeId: '' });
										}
									}}
									renderInput={(params) => (
										<TextField {...params} value={state.requestTypeId} name={state.requestTypeId} error={error.requestTypeId} variant='outlined' size="medium" fullWidth label={t('BRANCHCONFIG0004') + '*'} />
									)}
								/>
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} >
						<Box display="flex" justifyContent="center" m={1} p={1}>
							<TextField
								fullWidth
								error={error.approvalCriteria}
								name="approvalCriteria"
								type="number"
								label={t("BRANCHCONFIG0011") + '*'}
								helperText=""
								variant="outlined"
								size="medium"
								value={state.approvalCriteria}
								className={classes.number}
								onChange={handleChange}
								onKeyDown={(e) => {
									if (invalidChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onWheel={e => {
									e.target.blur();
								}}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
							/>
						</Box>
					</Grid>
					<Grid container direction="row" justifyContent="flex-end" alignItems="center">
						<Box display="flex" m={1} p={1}>
							<CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
								{t("COMMON008")}
							</CancelButtons>
							<SubmitButtons onClick={handleSubmit} color="primary" variant="contained" className='m-2'>
								{t("COMMON035")}
							</SubmitButtons>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}
