import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios';
import i18n from 'i18next';
import { SnackbarProvider } from 'notistack';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/base.scss';
import BrowserLanguage from './components/Util/BrowserLanguage';
import CommonUtil from './components/Util/CommonUtils';
import store from './config/configureStore';
import setJWTToken from './oauth-client/setJWTToken';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';

toast.configure();

axios.interceptors.request.use(
  (config) => {
    const language = BrowserLanguage.getDefaultLanguage();
    config.headers.common['time-zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.headers.common['Accept-Language'] = language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class App extends Component {
  componentDidMount() {
    let language = BrowserLanguage.getDefaultLanguage();
    language = language === null ? 'en' : language;
    i18n.changeLanguage(language);
    if (CommonUtil.isEmptyString(localStorage.getItem("access_token"))) {
      setJWTToken(false);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    } else {
      setJWTToken(localStorage.getItem("access_token"));
    }
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename='/'>
          <CssBaseline />
          <ScrollToTop>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              maxSnack={2}
              autoHideDuration={3000}
            >
              <Routes />
              <ToastContainer autoClose={2000} position='top-center' hideProgressBar className='toast-container' toastClassName='dark-toast' />
            </SnackbarProvider>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
