import { GET_REQUESTS_STATUS_COUNTS, MY_REQUESTS_LIST, PENDING_MY_REQUESTS_LIST } from "../../actions/types";

const initialState = {
    myRequestsList: [],
    myRequestsPendingList: [],
    requestsStatusCountsList: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MY_REQUESTS_LIST:
            return {
                ...state,
                myRequestsList: action.payload
            };
        case PENDING_MY_REQUESTS_LIST:
            return {
                ...state,
                myRequestsPendingList: action.payload
            };
            case GET_REQUESTS_STATUS_COUNTS:
                return {
                    ...state,
                    requestsStatusCountsList: action.payload
                };
        default:
            return state;
    }
}
