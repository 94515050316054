import { APPROVAL_CONDITION_LIST } from '../../actions/types';

const initialState = {
    approvalConditionList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case APPROVAL_CONDITION_LIST:
            return {
                ...state,
                approvalConditionList: action.payload
            };
        default:
            return state;
    }
}
