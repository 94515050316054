import { Box, Grid, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URL_BRANCH } from '../../../actions/EndPoints';
import { getByBranchId, updateCompanyProfile } from '../../../actions/ProfileApiCalls';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
import { countryList } from '../../../components/Util/CountryList';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: "700px",
        [theme.breakpoints.down("lg")]: {
            height: "500px",
        },
    },
}));
const countries = countryList;

export default function UpdateBranch(props) {
    const classes = useStyles();
    const [countryCode, setCountryCode] = useState(
        CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
    );
    const history = useHistory();
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        industry: '',
        companySize: '',
        code: '',
        registrationNumber: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
    });
    const [error, setError] = useState({
        name: false,
        industry: false,
        companySize: false,
        code: false,
        registrationNumber: false,
        email: false,
        phone: false,
        address: false,
        city: false,
        state: false,
        country: false,
        pincode: false,
    });

    useEffect(() => {
        loadCompanyData();
        //eslint-disable-next-line
    }, []);

    const loadCompanyData = () => {
        dispatch(
            getByBranchId(props.history.location.state.id, (data) => {
                setPayload(data);
            }),
            () => {
            }
        );
    };

    useEffect(() => {
        if (!CommonUtil.isEmptyString(payload.phone)) {
            if (payload.phone.includes('-')) {
                setCountryCode(payload.phone.split('-')[0]);
                setPayload({ ...payload, phone: payload.phone.split('-')[1] });
            }
        }
        //eslint-disable-next-line
    }, [payload.phone]);

    const handleChange = (event) => {

        var val = event.target.value;
        if (val.trim() === '') {
            val = val.trim();
        }
        if (event.target.value.length > 0 && val.length === 0) {
            return;
        }
        if (val.length > 2) {
            if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
                return;
            }
            if (val[0] === ' ') {
                return;
            }
        }
        const name = event.target.name;
        if (!CommonUtil.isEmptyString(event.target.value) && name === 'phone') {
            if (event.target.value.match(/^[0-9 ]+$/)) {
                setPayload({
                    ...payload,
                    [name]: event.target.value,
                });
            }
        } else {
            setPayload({
                ...payload,
                [name]: event.target.value,
            });
        }
        setError({
            ...error,
            [name]: false,
        });
    };

    const handleChangeCountry = (event) => {

        if (event === null) {
            setPayload({
                ...payload,
                country: ''
            });
        } else {
            setPayload({
                ...payload,
                country: event.country,
            });
        }
        setError({
            country: false,
        });
    }

    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(payload.name)) {
            setError({ ...error, name: true });
            toast.error(t('COMPPROFSCREEN001'));
            return;
        } else if (!CommonUtil.validateName(payload.name)) {
            setError({ ...error, name: true });
            toast.error(t('nameValidation'));
            return;
        }
        if (CommonUtil.isEmptyString(payload.country)) {
            setError({ ...error, country: true });
            toast.error(t('COMMON162'));
            return;
        }
        if (!CommonUtil.isEmptyString(payload.phone)) {
            var mobile = payload.phone + countryCode;
            if (mobile.length < 8 || payload.phone.length > 23) {
                setError({ ...error, phone: true });
                toast.error(t('COMPPROFSCREEN003'));
                return;
            }
        }

        if (!CommonUtil.isEmptyString(payload.email) && !payload.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
            setError({ ...error, email: true });
            toast.error(t('COMP0007'));
            return;
        }
        if (!CommonUtil.isEmptyString(payload.pincode) && !CommonUtil.isAlphaNumericSlash(payload.pincode)) {
            setError({ pincode: true });
            toast.error(t('COMPPROFSCREEN004'));
            return false;
        }
        if (!CommonUtil.isAlphaNumericSlash(payload.pincode)) {
            setError({ pincode: true });
            toast.error(t('COMPPROFSCREEN004'));
            return false;
        }

        if (!CommonUtil.isEmptyString(payload.industry) && !CommonUtil.isAlphaNumericSpace(payload.industry)) {
            setError({ industry: true });
            toast.error(t('Branch0008'));
            return false;
        }

        if (!CommonUtil.isEmptyString(payload.state) && !CommonUtil.isAlphaNumericSpace(payload.state)) {
            setError({ state: true });
            toast.error(t('Branch0008'));
            return false;
        }

        if (!CommonUtil.isEmptyString(payload.city) && !CommonUtil.isAlphaNumericSpace(payload.city)) {
            setError({ city: true });
            toast.error(t('Branch0008'));
            return false;
        }

        var data = {};
        if (!CommonUtil.isEmptyString(payload.phone)) {
            var phone = payload.phone + countryCode;
            if (phone.length < 8 || phone.length > 23) {
                setError({ ...error, phone: true });
                toast.error(t('COMPPROFSCREEN003'));
                return;
            } else {
                data = { ...payload, phone: countryCode + '-' + payload.phone };
            }
        } else {
            data = { ...payload };
            setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
        }
        dispatch(
            updateCompanyProfile(
                data,
                (data) => {
                    setEditMode(!editMode);
                    handleClose();
                    toast.success(t('Branch0006'));
                },
                (data) => {
                    handleError(data);
                }
            )
        );
    };

    const handleError = (data) => {
        switch (data.code) {
            case 'UASE0059':
                setError({ ...error, registrationNumber: true });
                break;
            case 'UASE0060':
                setError({ ...error, name: true });
                break;
            default:
                break;
        }
    };


    const handleClose = () => {
        history.push(REACT_URL_BRANCH);
    };
    const breadcrumb = [
        { path: REACT_URL_BRANCH, name: t('BRANCHCONFIG0007') },
        { path: '/fams/create-Updatebranch', name: t('Branch0010') },
    ];

    return (
        <>
            <Grid fullWidth onClose={handleClose} >
                <form onSubmit={handleSubmit}>
                    <Breadcrumb links={breadcrumb} CurrentTab={props.history.location.state.id !== '' ? t("REQUESTTYPES0007") : t("REQUESTTYPES0001")} />
                    <Grid fullWidth onClose={handleClose} className={`${classes.customContainer} background-size`}>
                        <Grid container sx={12} xs={12} alignItems="center" >
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        autoComplete='off'
                                        className='m-0 mb-2'
                                        size='medium'
                                        label={t('Branch0012')}
                                        variant='outlined'
                                        placeholder={t('Branch0012')}
                                        helperText=''
                                        type='text'
                                        name='name'
                                        fullWidth
                                        value={payload.name}
                                        onChange={handleChange}
                                        error={error.name}
                                        inputProps={{
                                            maxlength: 100,
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        autoComplete='off'
                                        className='m-0 mb-2'
                                        size='medium'
                                        label={t('COMMON108')}
                                        variant='outlined'
                                        placeholder={t('COMMON109')}
                                        helperText=''
                                        type='text'
                                        name='industry'
                                        fullWidth
                                        value={payload.industry}
                                        onChange={handleChange}
                                        error={error.industry}
                                        inputProps={{
                                            maxlength: 50,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container sx={12} xs={12} alignItems="center" >
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        autoComplete='off'
                                        className='m-0 mb-2'
                                        size='medium'
                                        label={t('COMMON112')}
                                        variant='outlined'
                                        type='text'
                                        placeholder={t('COMMON113')}
                                        helperText=''
                                        name='code'
                                        fullWidth
                                        value={payload.code}
                                        error={error.code}
                                        disabled={true}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        autoComplete='off'
                                        className='m-0 mb-2'
                                        size='medium'
                                        label={t('COMMON114') + ' *'}
                                        variant='outlined'
                                        type='text'
                                        placeholder={t('COMMON115')}
                                        helperText=''
                                        name='registrationNumber'
                                        disabled={true}
                                        fullWidth
                                        value={payload.registrationNumber}
                                        onChange={handleChange}
                                        error={error.registrationNumber}
                                        inputProps={{
                                            maxlength: 50,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container sx={12} xs={12} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <Autocomplete
                                        clearText={t('COMMON216')}
                                        openText={t('COMMON169')}
                                        closeText={t('COMMON170')}
                                        noOptionsText={t('COMMON214')}
                                        classes={{
                                            paper: classes.paper
                                        }}
                                        fullWidth
                                        name='country'
                                        options={countries}
                                        getOptionLabel={(option) => option.country}
                                        onChange={(event, newValue) => {
                                            handleChangeCountry(newValue);
                                        }}
                                        value={{ country: payload.country }}
                                        renderInput={(params) => <TextField {...params}
                                            name='country'
                                            className='login_input'
                                            variant='outlined'
                                            fullWidth
                                            label={t('COMMON122')}
                                            error={error.country}
                                            size='medium' />}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        autoComplete='off'
                                        className='m-0 mb-2'
                                        size='medium'
                                        label={t('COMMON121')}
                                        variant='outlined'
                                        type='text'
                                        placeholder={t('COMMON121')}
                                        helperText=''
                                        name='state'
                                        fullWidth
                                        value={payload.state}
                                        onChange={handleChange}
                                        error={error.state}
                                        inputProps={{
                                            maxlength: 50,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container sx={12} xs={12} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        autoComplete='off'
                                        className='m-0 mb-2'
                                        size='medium'
                                        label={t('COMMON120')}
                                        variant='outlined'
                                        type='text'
                                        placeholder={t('COMMON120')}
                                        helperText=''
                                        name='city'
                                        fullWidth
                                        value={payload.city}
                                        onChange={handleChange}
                                        error={error.city}
                                        style={{ zIndex: 0 }}
                                        inputProps={{
                                            maxlength: 50,
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        autoComplete='off'
                                        className='m-0 mb-2'
                                        size='medium'
                                        label={t('COMMON123')}
                                        variant='outlined'
                                        type='text'
                                        placeholder={t('COMMON124')}
                                        helperText=''
                                        name='pincode'
                                        fullWidth
                                        value={payload.pincode}
                                        onChange={handleChange}
                                        error={error.pincode}
                                        inputProps={{
                                            maxlength: 50,
                                        }}
                                    ></TextField>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Box display="flex" m={1} p={1}>
                                <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
                                    {t("COMMON008")}
                                </CancelButtons>
                                <SubmitButtons onClick={handleSubmit} color="primary" variant="contained" className='m-2'>
                                    {t("COMMON035")}
                                </SubmitButtons>

                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </>
    )
}
