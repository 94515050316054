import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import CommonUtil from '../../../components/Util/CommonUtils';
import './PhoneInput.css';

export default function PersonMobileInput(props) {
  const { t } = useTranslation();
  const invalidChars = ['-', '+', 'e'];
  const handleCountryCodeChange = (countryCode) => {
    props.onCounryCodeChange(countryCode);
  };

  const handleMobileNoChange = (mobileNo) => {
    var regex = /^[0-9]*$/;
    if (regex.test(mobileNo)) {
      props.onMobileNoChange(mobileNo);
    }
  };

  return (
    <Grid container item xl={6} lg={6} md={6} sm={12}>
      <Grid item xs={6} lg={5}>
        <div padding='1pc' style={{ padding: '1rem', paddingRight: '0px' }}>
          <div
            style={{ position: 'absolute', left: '18px', zIndex: 2, top: '10px', backgroundColor: 'white', color: 'rgba(0, 0, 0, 0.6)', fontSize: 9, padding: '0px 4px' }}
          >
            {t('COMMON189')}
          </div>
          <PhoneInput
            countryCodeEditable={false}
            enableSearch='true'
            searchPlaceholder={t('search')}
            searchNotFound={t('COMMON165')}
            onChange={(phone) => handleCountryCodeChange(phone)}
            inputProps={{
              disabled: true,
            }}
            value={props.countryCode}
            country={CommonUtil.getDefaultCountryCode()}
            placeholder={false}
          />

        </div>
      </Grid>
      <Grid item xs={6} lg={7}>
        <div className='p-3' padding='1pc'>
          <div
            style={{ position: 'absolute', left: '26px', zIndex: 2, top: '10px', backgroundColor: 'white', color: '00002D', fontSize: 9, padding: '0px 4px' }}
          >
          </div>
          <TextField
            className='m-0 mb-2'
            size='medium'
            label={t('PERSON006')}
            variant='outlined'
            error={props.error}
            helperText=''
            type='number'
            name='phone'
            fullWidth
            onKeyDown={(e) => {
              if (invalidChars.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 15)
            }}
            value={props.phone}
            onChange={(e) => {
              handleMobileNoChange(e.target.value);
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}
