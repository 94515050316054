import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const UpdateBranchIcon = (props) => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={props.disabled===true?t('BRNC001'):t("Branch0013")}>
      {props.disabled === true ?
                    <Grid>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.572" height="30.559" viewBox="0 0 21.572 30.559">
              <g id="Update_Admin_Icon" data-name="Update Admin Icon" transform="translate(-1768.428 -405)">
                <path id="Subtraction_2" data-name="Subtraction 2" d="M10.266,23.993H2.713A2.714,2.714,0,0,1,0,21.285v-1.55a8.9,8.9,0,0,1,14.072-7.243A7.776,7.776,0,0,0,11.8,13.83,6.508,6.508,0,0,0,8.9,13.159a6.587,6.587,0,0,0-6.582,6.576v1.55a.387.387,0,0,0,.391.384H9.262a7.786,7.786,0,0,0,1,2.322ZM8.9,10.237a5.119,5.119,0,1,1,5.121-5.119A5.135,5.135,0,0,1,8.9,10.237Zm0-7.919a2.8,2.8,0,1,0,2.795,2.8A2.8,2.8,0,0,0,8.9,2.318Z" transform="translate(1768.428 405.001)" fill="#304669" opacity="0.5" />
                <g id="Group_8721" data-name="Group 8721" opacity="0.5">
                  <g id="Ellipse_3000" data-name="Ellipse 3000" transform="translate(1780 418)" fill="none" stroke="#304669" stroke-width="1.8">
                    <circle cx="5" cy="5" r="5" stroke="none" />
                    <circle cx="5" cy="5" r="4.1" fill="none" />
                  </g>
                  <path id="Path_6844" data-name="Path 6844" d="M-18749.1-12829.172v10.416l3.813-3.346,3.8,3.346v-10.416" transform="translate(20530.287 13253.413)" fill="none" stroke="#304669" stroke-linejoin="round" stroke-width="1.8" />
                </g>
              </g>
            </svg>
                    </Grid> :
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="21.572" height="30.559" viewBox="0 0 21.572 30.559">
              <g id="Update_Admin_Icon" data-name="Update Admin Icon" transform="translate(-1768.428 -405)">
                <path id="Subtraction_2" data-name="Subtraction 2" d="M10.266,23.993H2.713A2.714,2.714,0,0,1,0,21.285v-1.55a8.9,8.9,0,0,1,14.072-7.243A7.776,7.776,0,0,0,11.8,13.83,6.508,6.508,0,0,0,8.9,13.159a6.587,6.587,0,0,0-6.582,6.576v1.55a.387.387,0,0,0,.391.384H9.262a7.786,7.786,0,0,0,1,2.322ZM8.9,10.237a5.119,5.119,0,1,1,5.121-5.119A5.135,5.135,0,0,1,8.9,10.237Zm0-7.919a2.8,2.8,0,1,0,2.795,2.8A2.8,2.8,0,0,0,8.9,2.318Z" transform="translate(1768.428 405.001)" fill="#304669" />
                <g id="Group_8721" data-name="Group 8721">
                  <g id="Ellipse_3000" data-name="Ellipse 3000" transform="translate(1780 418)" fill="none" stroke="#304669" stroke-width="1.8">
                    <circle cx="5" cy="5" r="5" stroke="none" />
                    <circle cx="5" cy="5" r="4.1" fill="none" />
                  </g>
                  <path id="Path_6844" data-name="Path 6844" d="M-18749.1-12829.172v10.416l3.813-3.346,3.8,3.346v-10.416" transform="translate(20530.287 13253.413)" fill="none" stroke="#304669" stroke-linejoin="round" stroke-width="1.8" />
                </g>
              </g>
            </svg>

          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="21.572" height="30.559" viewBox="0 0 21.572 30.559">
              <g id="Update_Admin_Icon" data-name="Update Admin Icon" transform="translate(-1768.428 -405)">
                <path id="Subtraction_2" data-name="Subtraction 2" d="M10.266,23.993H2.713A2.714,2.714,0,0,1,0,21.285v-1.55a8.9,8.9,0,0,1,14.072-7.243A7.776,7.776,0,0,0,11.8,13.83,6.508,6.508,0,0,0,8.9,13.159a6.587,6.587,0,0,0-6.582,6.576v1.55a.387.387,0,0,0,.391.384H9.262a7.786,7.786,0,0,0,1,2.322ZM8.9,10.237a5.119,5.119,0,1,1,5.121-5.119A5.135,5.135,0,0,1,8.9,10.237Zm0-7.919a2.8,2.8,0,1,0,2.795,2.8A2.8,2.8,0,0,0,8.9,2.318Z" transform="translate(1768.428 405.001)" fill="#304669" opacity="0.9" />
                <g id="Group_8721" data-name="Group 8721" opacity="0.9">
                  <g id="Ellipse_3000" data-name="Ellipse 3000" transform="translate(1780 418)" fill="none" stroke="#304669" stroke-width="1.8">
                    <circle cx="5" cy="5" r="5" stroke="none" />
                    <circle cx="5" cy="5" r="4.1" fill="none" />
                  </g>
                  <path id="Path_6844" data-name="Path 6844" d="M-18749.1-12829.172v10.416l3.813-3.346,3.8,3.346v-10.416" transform="translate(20530.287 13253.413)" fill="none" stroke="#304669" stroke-linejoin="round" stroke-width="1.8" />
                </g>
              </g>
            </svg>

          )}
        </Grid>
}
      </Tooltip>
    </>
  );
};
export default UpdateBranchIcon;
