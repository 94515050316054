import { TEMPLATE_LIST } from "../actions/types";

const initialState ={
    templateList: [],
};

export default function(state = initialState, action){
    switch (action.type) {
        case TEMPLATE_LIST:
        return{
            ...state,
            templateList : action.payload,
        };
        default:
            return state;   
    }
}

