import {  GET_STATUS_REPORT_LIST } from "../../actions/types";

const initialState = {
    statusreportList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STATUS_REPORT_LIST:
            return {
                ...state,
                statusreportList: action.payload
            };
        default:
            return state;
    }
}