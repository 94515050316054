import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { getPendingMyRequestsList } from "../../actions/myRequests/MyRequestsActions";
import Breadcrumb from '../../components/Breadcrumb';
import DataTable from "../../components/DataTableNew";
import FilterButton from "../../components/FilterButtom";
import CommonUtil from "../../components/Util/CommonUtils";
import ViewRequestDetails from "../myRequests/ViewRequestDetails";
import ApproveIcon from "../reusableIcons/ApproveRequestIcon";
import RejectIcon from "../reusableIcons/RejectRequestIcon";
import ViewIconA from "../reusableIcons/ViewIconA";
import ApproveOrRejectRequestDialog from "./ApproveOrRejectRequestDialog";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles((theme) => ({
    approve: {
        color: '#36C96D',
        '&:hover': {
            color: '#1AB755'
        },
    },
    reject: {
        color: '#FC5F46',
        '&:hover': {
            color: '#E5482F'
        },
    },
}));
export default function Approver() {
    const { t } = useTranslation();
    const store = useSelector((state) => state);
    const breadcrumb = [{ path: '', name: t('APPROVER0001') }];
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedObject, setSelectedObject] = React.useState()
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [statuss, setStatuss] = useState([]);
    const [totalPages, setTotalPages] = React.useState(1);
    const [title, seTitle] = React.useState([])
    const [lebal, setLebal] = React.useState([])
    const [text, setText] = React.useState([])
    const [openView, setOpenView] = React.useState(false);
    const [clearFilterButton, setClearFilterButton] = React.useState(false);
    const [editMode, setEditMode] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [state, setState] = React.useState({
        requestTypeName: '',
        createdDate: ''
    });


    //eslint-disable-next-line
    const columns = React.useMemo(() => [
        {
            field: "name",
            headerName: t('COMMON044'),
            flex: 1,
            width: 10,
            renderCell: (params) => (
                <Tooltip title={params.row.name} >
                    <span className="table-cell-trucate">{params.row.name}</span>
                </Tooltip>
            ),
        },
        {
            field: 'requestTypeId.name',
            headerName: t('BRANCHCONFIG0004'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.requestTypeId.name,
            renderCell: (params) => (
                <Tooltip title={params.row.requestTypeId.name} >
                    <span className="table-cell-trucate">{params.row.requestTypeId.name}</span>
                </Tooltip>
            ),
        },

        {
            field: 'companyId.name',
            headerName: t('BRANCHCONFIG0007'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.companyId.name,
            renderCell: (params) => (
                <Tooltip title={params.row.companyId.name} >
                    <span className="table-cell-trucate">{params.row.companyId.name}</span>
                </Tooltip>
            ),
        },
        {
            field: "employeeId.firstName",
            headerName: t('EMP001'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.employeeId.firstName,
            renderCell: (params) => (
                <Tooltip title={params.row.employeeId.firstName} >
                    <span className="table-cell-trucate">{params.row.employeeId.firstName}</span>
                </Tooltip>
            ),
        },
        {
            field: 'status',
            headerName: t('COMMON001'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.status,
        },

        {
            field: 'createdAt',
            headerName: t('DEPT006'),
            flex: 1,
            width: 10,
            renderCell: (params) => (
                <Tooltip title={CommonUtil.getLocalDate(params.row.createdAt)} >
                    <span className="table-cell-trucate">{CommonUtil.getLocalDate(params.row.createdAt)}</span>
                </Tooltip>
            ),
        },

        {
            field: "Actions",
            headerName: t('dataTableAction'),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<ViewIconA />}
                    onClick={(event) => viewDialtailos(params.row)}
                />,
                <GridActionsCellItem
                    icon={<ApproveIcon className={classes.approve} />}
                    onClick={(event) => approveRequest(params.row)}
                />,
                <GridActionsCellItem
                    icon={<RejectIcon className={classes.reject} />}
                    onClick={(event) => rejectRequest(params.row)}
                />,
            ],
        },
    ]);
    const viewDialtailos = (data) => {
        setSelectedObject(data);
        setOpenView(true);
    };
    const approveRequest = (data) => {
        setSelectedObject(data);
        setOpenRejectDialog(true);
        seTitle(t("DASHBOARD0006"));
        setText(t("MYREQ0008"));
        setStatuss("APPROVED")
        setLebal(t('COMMON016'))
    };
    const rejectRequest = (data) => {
        setSelectedObject(data);
        setOpenRejectDialog(true);
        seTitle(t("DASHBOARD0007"));
        setText(t("MYREQ00010"));
        setStatuss("REJECTED")
        setLebal(t('COMMON016') + '*')
    };

    useEffect(() => {
        loadData(page, rowsPerPage, state);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.myRequest.myRequestsPendingList)) {
            setPage(store.myRequest.myRequestsPendingList.currentPage - 1);
            setData(store.myRequest.myRequestsPendingList.requests);
            setTotalPages(store.myRequest.myRequestsPendingList.totalPages)
        } else {
            setPage(0);
            setData([]);
        }

    }, [store.myRequest.myRequestsPendingList]);

    const loadData = (page, rowsPerPage, state) => {
        dispatch(getPendingMyRequestsList(page, rowsPerPage, state));
    };

    const handleChangePage = (newPage, size) => {
        setRowsPerPage(size);
        setPage(newPage);
        loadData(newPage, size, state);
    };

    const handleCancel = () => {
        setState({
            requestTypeName: '',
            createdDate: '',
        });
        setStartDate(null);
        setEditMode(!editMode);
    };

    const clearFilter = () => {
        setState({
            requestTypeName: '',
            createdDate: '',
        });
        setClearFilterButton(false);
        setStartDate(null);
        loadData(1, rowsPerPage, '');
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleFilter = () => {
        if (state.requestTypeName.trim() === '' && state.createdDate === '') {
            toast.error(t('COMMON015'));
            return;
        }
        loadData(1, rowsPerPage, state);
        setClearFilterButton(true)
        handleClose();
    };

    const handleClose = () => {
        setEditMode(false)
    };
    const reset = () => {
        if (state.requestTypeName.trim() === '' && state.createdDate === '') {
            toast.error(t('COMMON027'));
            return;
        }
        setState({
            requestTypeName: '',
            createdDate: '',
        });
        setStartDate(null);
    };

    useEffect(() => {
        setState({ ...state, createdDate: startDate });
        // eslint-disable-next-line
    }, [startDate]);

    return (
        <Grid>
            <Breadcrumb links={breadcrumb}>
                {clearFilterButton === false ?
                    <Box display='flex'>
                        <Tooltip arrow title={t('COMMON006')}>
                            <IconButton size='small' variant='contained' color='secondary' onClick={() => handleCancel()}>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    </Box> : ''}
            </Breadcrumb>
            {editMode ? <>
                <Box style={{ boxShadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%)', backgroundColor: '#FFFFFF', marginBottom: '13px' }} display='flex'>
                    <Grid container xs={12} sm={12} >
                        <Grid item sm={3}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <TextField fullWidth name='requestTypeName' label={t('BRANCHCONFIG0004')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={state.requestTypeName} />
                            </Box>
                        </Grid>
                        <Grid item sm={3}>
                            <Box display='flex' justifyContent='center' m={1} p={1} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={t('DEPT006')}
                                        maxDate={new Date()}
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params}  onKeyDown={(e) => { e.preventDefault(); }} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item sm={2}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <FilterButton onClick={handleFilter} color="primary" variant="contained" className='mt-2' backgroundColor={'#36C96D'} hoverbackgroundColor={'#1AB755'}>
                                    {t("APPLY001")}
                                </FilterButton>
                            </Box>
                        </Grid>

                        <Grid item sm={2}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <FilterButton onClick={reset} variant="outlined" className='mt-2' borderColor={'#36C96D'} hoverborderColor={'#1AB755'} color={'#36C96D'} hovercolor={'#1AB755'} >
                                    {t("CLEAR001")}
                                </FilterButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box marginTop='0px'>
                        <Tooltip arrow title={t('COMMON170')}>
                            <IconButton size='small' variant='contained' color='primary' onClick={() => handleCancel()}>
                                <span className='btn-wrapper--icon rounded'>
                                    <CloseIcon style={{ color: '#B7B7B7' }} />
                                </span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </> : <>
                {clearFilterButton ?
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                            <FilterButton onClick={clearFilter} variant="outlined" className='m-2' borderColor={'#FC5F4699'} hoverborderColor={'#E5482F99'} hoverbackgroundColor={'#FC5F4608'} color={'#FC5F46'} hovercolor={'#E5482F'} >
                                {t("ClearFilters001")}
                            </FilterButton>
                        </Box>
                    </Grid> : ''}
            </>
            }
            <DataTable
                columns={columns}
                rows={data}
                page={page}
                pageCount={totalPages}
                pageSize={rowsPerPage}
                handleChange={(newPage, size) => handleChangePage(newPage, size)}
            />
            {openRejectDialog && (
                <ApproveOrRejectRequestDialog
                    selectedObject={selectedObject}
                    open={openRejectDialog}
                    title={title}
                    text={text}
                    statuss={statuss}
                    lebal={lebal}
                    onClose={() => {
                        setOpenRejectDialog(false);
                        loadData(page, rowsPerPage, '');
                        setSelectedObject([]);
                    }}
                />
            )}

            {openView && (
                <ViewRequestDetails
                    open={openView}
                    selectedObject={selectedObject}
                    onClose={() => {
                        setOpenView(false);
                        loadData(page, rowsPerPage, '');
                        setSelectedObject([]);
                    }}
                />
            )}
        </Grid>

    )
}