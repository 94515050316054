import {
  REACT_URI_APPLICATIONS,
  REACT_URI_APPROVAL_CONDITION,
  REACT_URI_APPROVAL_WORKFLOW,
  REACT_URI_APPROVER,
  REACT_URI_BRANCH_CONFIGURATIONS,
  REACT_URI_DEPARTMENT,
  REACT_URI_DESIGNATION, REACT_URI_PERSON,
  REACT_URI_REPORTS,
  REACT_URI_REQUEST_TYPE,
  REACT_URI_TEMPLATES,
  REACT_URI_TEMPLATE_ITEMS,
  REACT_URI_WORKFLOW_NODE,
  REACT_URL_BRANCH,
  REACT_URL_DASHBOARD
} from '../../actions/EndPoints';
import { ReactComponent as Approverh_Icon } from '../../assets/images/LeftSideMenu/ApproverHIcon.svg';
import { ReactComponent as Dashboardh_Icon } from '../../assets/images/LeftSideMenu/DashboardHoverIcon.svg';
import { ReactComponent as Organizationh_Icon } from '../../assets/images/LeftSideMenu/OrganizationHIcon.svg';
import { ReactComponent as Reportsh_Icon } from '../../assets/images/LeftSideMenu/ReportsHIcon.svg';
import { ReactComponent as Settings_Icon } from '../../assets/images/LeftSideMenu/SettingsHIcon.svg';
import BrowserLanguage from '../../components/Util/BrowserLanguage';

let language = BrowserLanguage.getDefaultLanguage();
const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboardh_Icon,
  },
  {
    id: '1',
    name: 'Approver',
    Icon: Approverh_Icon,
    link: `${REACT_URI_APPROVER}`
  },
  {
    id: '3',
    name: 'Organization',
    Icon: Organizationh_Icon,
    items: [
      {
        id: '9',
        name: 'Person',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '10',
        name: 'Department',
        link: `${REACT_URI_DEPARTMENT}`,
      },
      {
        id: '11',
        name: 'Designation',
        link: `${REACT_URI_DESIGNATION}`,
      },
      {
        id: '12',
        name: 'User Role',
        link: `${REACT_URI_APPLICATIONS}`,
      },
      {
        id: '35',
        name: 'Branch',
        link: `${REACT_URL_BRANCH}`,
      },
    ]
  },
  {
    id: '4',
    name: 'Reports',
    Icon: Reportsh_Icon,
    link: `${REACT_URI_REPORTS}`,
  },
  {
    id: '13',
    name: 'Settings',
    Icon: Settings_Icon,
    items: [
      {
        id: '14',
        name: 'Request Types',
        link: `${REACT_URI_REQUEST_TYPE}`,
      },
      {
        id: '15',
        name: 'Branch Configurations',
        link: `${REACT_URI_BRANCH_CONFIGURATIONS}`,
      },
      {
        id: '16',
        name: 'Templates',
        link: `${REACT_URI_TEMPLATES}`,
      },
      {
        id: '17',
        name: 'Template Items',
        link: `${REACT_URI_TEMPLATE_ITEMS}`,
      },
      {
        id: '18',
        name: 'Approval Condition',
        link: `${REACT_URI_APPROVAL_CONDITION}`,
      },
      {
        id: '19',
        name: 'Approval Workflow',
        link: `${REACT_URI_APPROVAL_WORKFLOW}`,
      },
      {
        id: '20',
        name: 'Workflow Node',
        link: `${REACT_URI_WORKFLOW_NODE}`,
      },
    ]
  },
];

const dataEs = [
  {
    id: '0',
    name: 'Tablero',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboardh_Icon,
  },
  {
    id: '1',
    name: 'Aprobadora',
    Icon: Approverh_Icon,
    link: `${REACT_URI_APPROVER}`
  },
  {
    id: '3',
    name: 'Organizacion',
    Icon: Organizationh_Icon,
    items: [
      {
        id: '9',
        name: 'Persona',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '10',
        name: 'Departamento',
        link: `${REACT_URI_DEPARTMENT}`,
      },
      {
        id: '11',
        name: 'Designacion',
        link: `${REACT_URI_DESIGNATION}`,
      },
      {
        id: '12',
        name: 'Rol del usuario',
        link: `${REACT_URI_APPLICATIONS}`,
      },
      {
        id: '35',
        name: 'Sucursal',
        link: `${REACT_URL_BRANCH}`,
      },
    ]
  },
  {
    id: '4',
    name: 'Informes',
    Icon: Reportsh_Icon,
    link: `${REACT_URI_REPORTS}`,
  },
  {
    id: '13',
    name: 'Ajustes',
    Icon: Settings_Icon,
    items: [
      {
        id: '14',
        name: 'Tipos de solicitud',
        link: `${REACT_URI_REQUEST_TYPE}`,
      },
      {
        id: '15',
        name: 'Configuraciones de sucursales',
        link: `${REACT_URI_BRANCH_CONFIGURATIONS}`,
      },
      {
        id: '16',
        name: 'Plantillas',
        link: `${REACT_URI_TEMPLATES}`,
      },
      {
        id: '17',
        name: 'Elementos de plantilla',
        link: `${REACT_URI_TEMPLATE_ITEMS}`,
      },
      {
        id: '18',
        name: 'Criterios de aprobacion',
        link: `${REACT_URI_APPROVAL_CONDITION}`,
      },
      {
        id: '19',
        name: 'Flujo de trabajo de aprobacion',
        link: `${REACT_URI_APPROVAL_WORKFLOW}`,
      },
      {
        id: '20',
        name: 'Nodo de flujo de trabajo',
        link: `${REACT_URI_WORKFLOW_NODE}`,
      },
    ]
  },
];


const dataZh = [
  {
    id: '0',
    name: '仪表板',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboardh_Icon,
  },
  {
    id: '1',
    name: '审批人',
    Icon: Approverh_Icon,
    link: `${REACT_URI_APPROVER}`
  },
  {
    id: '3',
    name: '组织',
    Icon: Organizationh_Icon,
    items: [
      {
        id: '9',
        name: '人',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '10',
        name: '部门',
        link: `${REACT_URI_DEPARTMENT}`,
      },
      {
        id: '11',
        name: '指定',
        link: `${REACT_URI_DESIGNATION}`,
      },
      {
        id: '12',
        name: '用户角色',
        link: `${REACT_URI_APPLICATIONS}`,
      },
      {
        id: '35',
        name: '分支',
        link: `${REACT_URL_BRANCH}`,
      },
    ]
  },
  {
    id: '4',
    name: '报告',
    Icon: Reportsh_Icon,
    link: `${REACT_URI_REPORTS}`,
  },
  {
    id: '13',
    name: '设置',
    Icon: Settings_Icon,
    items: [
      {
        id: '14',
        name: '请求类型',
        link: `${REACT_URI_REQUEST_TYPE}`,
      },
      {
        id: '15',
        name: '分支配置',
        link: `${REACT_URI_BRANCH_CONFIGURATIONS}`,
      },
      {
        id: '16',
        name: '模板',
        link: `${REACT_URI_TEMPLATES}`,
      },
      {
        id: '17',
        name: '模板项目',
        link: `${REACT_URI_TEMPLATE_ITEMS}`,
      },
      {
        id: '18',
        name: '批准标准',
        link: `${REACT_URI_APPROVAL_CONDITION}`,
      },
      {
        id: '19',
        name: '审批工作流程',
        link: `${REACT_URI_APPROVAL_WORKFLOW}`,
      },
      {
        id: '20',
        name: '工作流节点',
        link: `${REACT_URI_WORKFLOW_NODE}`,
      },
    ]
  },
];




export default language === 'en' ? dataEn : language === 'es' ? dataEs : dataZh;
