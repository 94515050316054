import { Box, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCompanyById, updateCompanyProfile } from '../../actions/ProfileApiCalls';
import companyProfiles from '../../assets/images/companyprofile.svg';
import Breadcrumb from '../../components/Breadcrumb';
import CancelButtons from '../../components/CancelButtons';
import MobileInput from '../../components/MobileInput';
import SubmitButtons from '../../components/SubmitButtons';
import CommonUtil from '../../components/Util/CommonUtils';
import { countryList } from '../../components/Util/CountryList';
import ProfileUplod from './UploadImg';

const useStyles = makeStyles({
  paper: {
    height: "115px"
  }
});
const countries = countryList;

export default function CompanyProfileScreen(props) {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [payload, setPayload] = useState({
    id: '',
    name: '',
    industry: '',
    companySize: '',
    code: '',
    registrationNumber: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  });

  const [error, setError] = useState({
    name: false,
    industry: false,
    companySize: false,
    code: false,
    registrationNumber: false,
    email: false,
    phone: false,
    address: false,
    city: false,
    state: false,
    country: false,
    pincode: false,
  });

  const id = localStorage.getItem("companyId");

  useEffect(() => {
    loadCompanyData();
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!CommonUtil.isEmpty(store.company.company)) {
      setPayload({
        id: store.company.company.id,
        name: store.company.company.name,
        industry: store.company.company.industry,
        companySize: store.company.company.companySize,
        code: store.company.company.code,
        registrationNumber: store.company.company.registrationNumber,
        email: store.company.company.email,
        phone: store.company.company.phone,
        address: store.company.company.address,
        city: store.company.company.city,
        state: store.company.company.state,
        country: store.company.company.country,
        pincode: store.company.company.pincode,
      });
    }

  }, [store.company]);

  useEffect(() => {
    if (!CommonUtil.isEmptyString(payload.phone)) {
      if (payload.phone.includes('-')) {
        setCountryCode(payload.phone.split('-')[0]);
        setPayload({ ...payload, phone: payload.phone.split('-')[1] });
      }
    }
    //eslint-disable-next-line
  }, [payload.phone]);

  const loadCompanyData = () => {
    dispatch(
      getCompanyById(id, (data) => { }),
      () => { }
    );
  };

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length === 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    if (!CommonUtil.isEmptyString(event.target.value) && name === 'phone') {
      if (event.target.value.match(/^[0-9 ]+$/)) {
        setPayload({
          ...payload,
          [name]: event.target.value,
        });
      }
    } else {
      setPayload({
        ...payload,
        [name]: event.target.value,
      });
    }
    setError({
      ...error,
      [name]: false,
    });
  };
  const handleChangeCountry = (event) => {
    if (event === null) {
      setPayload({
        ...payload,
        country: ''
      });
    } else {
      setPayload({
        ...payload,
        country: event.country,
      });
    }
    setError({
      country: false,
    });
  }
  const handleClickEdit = () => {
    history.push({ pathname: '/fams/company-profile' });
  }
  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({ ...error, name: true });
      toast.error(t('COMPPROFSCREEN001'));
      return;
    } else if (CommonUtil.isEmptyString(payload.code)) {
      setError({ ...error, code: true });
      toast.error(t('COMPPROFSCREEN002'));
      return;
    }
    if (CommonUtil.isEmptyString(payload.country)) {
      setError({ ...error, country: true });
      toast.error(t('COMMON162'));
      return;
    }
    if (!CommonUtil.isEmptyString(payload.phone)) {
      if (payload.phone.length < 8 || payload.phone.length > 15) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      }
    }
    if (!CommonUtil.isEmptyString(payload.email) && !payload.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
      setError({ ...error, email: true });
      toast.error(t('COMP0007'));
      return;
    }
    if (!CommonUtil.isEmptyString(payload.pincode) && !CommonUtil.isAlphaNumericSlash(payload.pincode)) {
      setError({ pincode: true });
      toast.error(t('COMPPROFSCREEN004'));
      return false;
    }
    if (!CommonUtil.isAlphaNumericSlash(payload.pincode)) {
      setError({ pincode: true });
      toast.error(t('COMPPROFSCREEN004'));
      return false;
    }
    if (!CommonUtil.isEmptyString(payload.industry) && !CommonUtil.isAlphaNumericSpace(payload.industry)) {
      setError({ industry: true });
      toast.error(t('COMPPROFSCREEN006'));
      return false;
    }
    if (!CommonUtil.isEmptyString(payload.state) && !CommonUtil.isAlphaNumericSpace(payload.state)) {
      setError({ state: true });
      toast.error(t('ADD026'));
      return false;
    }
    if (!CommonUtil.isEmptyString(payload.city) && !CommonUtil.isAlphaNumericSpace(payload.city)) {
      setError({ city: true });
      toast.error(t('ADD025'));
      return false;
    }
    if (!CommonUtil.isAlphaNumericSpace(payload.registrationNumber)) {
      setError({ registrationNumber: true });
      toast.error(t('COMPPROFSCREEN007'));
      return false;
    }
    var data = {};
    if (!CommonUtil.isEmptyString(payload.phone)) {
      if (payload.phone.length < 8 || payload.phone.length > 23) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      } else {
        data = { ...payload, phone: countryCode + '-' + payload.phone };
      }
    } else {
      data = { ...payload };
      setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
    }
    dispatch(
      updateCompanyProfile(
        data,
        (data) => {
          handleClickEdit();
          toast.success(data.message);
        },
        (data) => {
          handleError(data);
        }
      )
    );
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'UASE0059':
        setError({ ...error, registrationNumber: true });
        break;
      case 'UASE0060':
        setError({ ...error, name: true });
        break;
      default:
        break;
    }
  };
  const breadcrumb = [
    { path: '/fams/company-profile', name: t('COMMON125') },
    { path: '/fams/company-profile-screen', name: t("PROF0012") },
  ];
  const handleCancel = () => {
    setPayload({
      id: store.company.company.id,
      name: store.company.company.name,
      industry: store.company.company.industry,
      companySize: store.company.company.companySize,
      code: store.company.company.code,
      registrationNumber: store.company.company.registrationNumber,
      email: store.company.company.email,
      phone: store.company.company.phone,
      address: store.company.company.address,
      city: store.company.company.city,
      state: store.company.company.state,
      country: store.company.company.country,
      pincode: store.company.company.pincode,
    });
    handleClickEdit();
    setError({
      name: false,
      industry: false,
      companySize: false,
      code: false,
      registrationNumber: false,
      email: false,
      phone: false,
      address: false,
      city: false,
      state: false,
      country: false,
      pincode: false,
    });
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

        }}
      >
        <Box>
          <Typography
            gutterBottom
            variant="h3"
            component="h2"
            color="secondary"
            style={{ paddingBottom: '15px' }}
          >
            <Breadcrumb links={breadcrumb} />
          </Typography>
        </Box>
        <Box textAlign='left' style={{ paddingBottom: '15px' }}>
          <div style={{ backgroundColor: '#F2F2F6' }}>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>{t('COMMON008')}</Typography>}>
              <IconButton onClick={handleClickEdit} >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      </Box>
      <Paper className='css-pepar-in-userprofile'>
        <Grid container p={5} spacing={3}>
          <Grid container justifyContent="center" alignItems='center' md={4} sm={12}>
            <Grid xl={6} lg={10} md={8} sm={4}>
              <ProfileUplod profilePhoto={companyProfiles} disabled={true} borderStyled={{ borderStyle: "none" }} heightimage='225px' />
            </Grid>
          </Grid>
          <Grid container md={8} sm={12} spacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON106')}
                  variant='outlined'
                  placeholder={t('COMMON107')}
                  helperText=''
                  type='text'
                  name='name'
                  fullWidth
                  value={payload.name}
                  onChange={handleChange}
                  error={error.name}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON108')}
                  variant='outlined'
                  placeholder={t('COMMON109')}
                  helperText=''
                  type='text'
                  name='industry'
                  fullWidth
                  value={payload.industry}
                  onChange={handleChange}
                  error={error.industry}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <FormControl variant='outlined' size='large' fullWidth>
                  <InputLabel>{t('COMMON110')}</InputLabel>
                  <Select
                    fullWidth
                    className='m-0 mb-2 text-left'
                    helperText=''
                    label={t('COMMON110')}
                    name='companySize'
                    value={payload.companySize}
                    onChange={handleChange}
                    error={error.companySize}
                  >
                    <MenuItem value='0' defaultChecked>
                      <em>{t('none')}</em>
                    </MenuItem>
                    <MenuItem value='1-50' selected>
                      1-50
                    </MenuItem>
                    <MenuItem value='50-100' selected>
                      50-100
                    </MenuItem>
                    <MenuItem value='100-250' selected>
                      100-250
                    </MenuItem>
                    <MenuItem value='250-500' selected>
                      250-500
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON112')}
                  variant='outlined'
                  type='text'
                  placeholder={t('COMMON113')}
                  helperText=''
                  name='code'
                  fullWidth
                  value={payload.code}
                  error={error.code}
                  disabled={true}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON114') + ' *'}
                  variant='outlined'
                  type='text'
                  placeholder={t('COMMON115')}
                  helperText=''
                  name='registrationNumber'
                  fullWidth
                  value={payload.registrationNumber}
                  onChange={handleChange}
                  error={error.registrationNumber}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON042')}
                  variant='outlined'
                  type='email'
                  placeholder={t('COMMON102')}
                  helperText=''
                  name='email'
                  fullWidth
                  value={payload.email}
                  onChange={handleChange}
                  error={error.email}
                />
              </div>
            </Grid>

            <MobileInput
              specialLabel={t('COMMON116')}
              error={error.phone}
              countryCode={countryCode}
              phone={payload.phone}
              enableSearch='true'


              countryCodeEditable={true}
              searchNotFound={t('SEARCH01')}
              searchPlaceholder={t('SEARCH02')}
              onMobileNoChange={(phone) => {
                setError({ phone: false });
                setPayload({ ...payload, phone: phone });
              }}
              onCounryCodeChange={(code) => setCountryCode(code)}
            />


            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON118')}
                  variant='outlined'
                  type='text'
                  placeholder={t('COMMON119')}
                  helperText=''
                  name='address'
                  fullWidth
                  value={payload.address}
                  onChange={handleChange}
                  error={error.address}
                  inputProps={{
                    maxlength: 255,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <Autocomplete
                  clearText={t('COMMON216')}
                  openText={t('COMMON169')}
                  closeText={t('COMMON170')}
                  noOptionsText={t('COMMON214')}
                  classes={{
                    paper: classes.paper
                  }}
                  fullWidth
                  name='country'
                  options={countries}
                  getOptionLabel={(option) => option.country}
                  onChange={(event, newValue) => {
                    handleChangeCountry(newValue);
                  }}
                  value={{ country: payload.country }}
                  renderInput={(params) => <TextField {...params}
                    name='country'
                    className='login_input'
                    variant='outlined'
                    fullWidth
                    label={t('COMMON189') + ' *'}
                    error={error.country}
                    size='large' />}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON121')}
                  variant='outlined'
                  type='text'
                  placeholder={t('COMMON121')}
                  helperText=''
                  name='state'
                  fullWidth
                  value={payload.state}
                  onChange={handleChange}
                  error={error.state}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON120')}
                  variant='outlined'
                  type='text'
                  placeholder={t('COMMON120')}
                  helperText=''
                  name='city'
                  fullWidth
                  value={payload.city}
                  onChange={handleChange}
                  error={error.city}
                  style={{ zIndex: 0 }}
                  inputProps={{
                    maxlength: 50,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  autoComplete='off'
                  className='m-0 mb-2'
                  size='large'
                  label={t('COMMON123')}
                  variant='outlined'
                  type='text'
                  placeholder={t('COMMON124')}
                  helperText=''
                  name='pincode'
                  fullWidth
                  value={payload.pincode}
                  onChange={handleChange}
                  error={error.pincode}
                  inputProps={{
                    maxlength: 50,
                  }}
                ></TextField>
              </div>
            </Grid>
          </Grid>
          <Grid container direction='row' justifyContent="flex-end" alignItems='center' display='flex'>
            <div className='p-1'>
              <CancelButtons color="primary" variant="outlined" className='m-3' onClick={() => handleCancel()} >
                {t('COMMON008')}
              </CancelButtons>
              <SubmitButtons
                onClick={() => handleSubmit()}
                color="primary" variant="contained" className='m-3'
              >
                {t('COMMON052')}
              </SubmitButtons>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>

  );
}

