import { Box, Drawer, Hidden, Paper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import SidebarHeader from '../SidebarHeader';
import branchNavItems from './branchNavItems';
import employeeNavItems from './employeeNavItems';
import navItems from './navItems';

const Sidebar = (props) => {
  const { setSidebarToggleMobile, sidebarToggleMobile, sidebarShadow } = props;
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("USER"));
  const roleCode = user.roleCode;
  const companyCode = user.companyCode;
  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);
  const pagePath = window.location.pathname;
  const [expand, setExpand] = useState(true);
  const [icon, setIcon] = React.useState(false);
  const [expandPage, setExpandPage] = React.useState('');
  const toggleIcon = (parent) => {
    setExpandPage(parent.name)
    if (!parent.items) {
      history.push({
        pathname: parent.link,
      });
    } else {
      setIcon(!icon);
    }
  };
  const handlenav = (child) => {
    history.push({
      pathname: child.link,
    });
  };

  const handleToggle = () => {
    setExpand(true);
    props.onchange(true);
  };

  const sidebarMenuContent = (
    <div>
      <Router>
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              <SideNav
                className='test-css'
                onSelect={(selected) => {
                  const to = selected;
                  if (location.pathname !== to) {
                    history.push(to);
                  }
                }}
                expanded={expand}
                onClick={() => handleToggle}
              >
                <SideNav.Nav defaultSelected={pagePath}>
                  {navItems.map((parent, index) => {
                    var eventKey = parent.items && parent.items.length > 0 ? parent.id : parent.link;
                    return (
                      <NavItem eventKey={eventKey} key={index}>
                        <NavIcon style={{ width: '85px', marginRight: '-20px', display: 'block', justifyContent: 'center' }}>
                          <parent.Icon onClick={() => toggleIcon(parent)} />
                        </NavIcon>
                        {!expand && (
                          <NavText onClick={() => toggleIcon(parent)}>
                            <Box className='navTextTitle' component='span' display='flex' alignItems='center' style={parent.link === pagePath && (expandPage === 'Organization' && expandPage === 'Settings') ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {parent.name} {!parent.items ? null : <ArrowDropDownIcon />}
                            </Box>
                          </NavText>
                        )}
                        {expand && (
                          <Box display='flex' flexDirection='row' onClick={() => toggleIcon(parent)}>
                            <Box flexGrow={1} className='navTextTitle' component='span' display='flex' alignItems='center' style={expandPage !== '' && ((parent.name === 'Organization' && expandPage === 'Organization') || (parent.name === 'Settings' && expandPage === 'Settings')) ? { color: '#36C96D', backgroundColor: '' } : parent.link === pagePath ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {parent.name}
                            </Box>
                            <Box className='navTextTitle' component='span' display='flex' alignItems='center' style={expandPage !== '' && ((parent.name === 'Organization' && expandPage === 'Organization') || (parent.name === 'Settings' && expandPage === 'Settings')) ? { color: '#36C96D', backgroundColor: '' } : parent.link === pagePath ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {!parent.items ? null : <ArrowDropDownIcon />}
                            </Box>
                          </Box>
                        )}
                        {parent.items && parent.items.length > 0
                          ? parent.items.map((child, index) => {
                            return (
                              <NavItem key={index} eventKey={`${child.link}`} onClick={() => handlenav(child)}>
                                <NavText>
                                  <Box
                                    className='navTextSubTitle'
                                    component='span'
                                    display='flex'
                                    alignItems='center'
                                    style={{ color: pagePath===child.link?'#3D4977':'#304669BF', display: 'flex', marginLeft: '10px', fontSize: '14px', padding: '0px' }}
                                  >
                                    <ul >
                                      <li style={{fontWeight:pagePath===child.link?'bold':400,}}>
                                        {child.name}
                                      </li>
                                    </ul>
                                  </Box>
                                </NavText>
                              </NavItem>
                            );
                          })
                          : null}
                      </NavItem>
                    );
                  })}
                </SideNav.Nav>
                <Box
                  component='div'
                  style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', position: 'absolute', bottom: '0', marginLeft: '22px' }}
                >
                </Box>
              </SideNav>
            </React.Fragment>
          )}
        />
      </Router>
    </div>
  );

  const branchNavItemsMenu = (
    <div>
      <Router>
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              <SideNav
                className='test-css'
                onSelect={(selected) => {
                  const to = selected;
                  if (location.pathname !== to) {
                    history.push(to);
                  }
                }}
                expanded={expand}
                onClick={() => handleToggle}
              >
                <SideNav.Nav defaultSelected={pagePath}>
                  {branchNavItems.map((parent, index) => {
                    var eventKey = parent.items && parent.items.length > 0 ? parent.id : parent.link;
                    return (
                      <NavItem eventKey={eventKey} key={index}>
                        <NavIcon style={{ width: '85px', marginRight: '-20px', display: 'block', justifyContent: 'center' }}>
                          <parent.Icon onClick={() => toggleIcon(parent)} />
                        </NavIcon>
                        {!expand && (
                          <NavText onClick={() => toggleIcon(parent)}>
                            <Box className='navTextTitle' component='span' display='flex' alignItems='center' style={parent.link === pagePath && (expandPage === 'Organization' && expandPage === 'Settings') ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {parent.name} {!parent.items ? null : <ArrowDropDownIcon />}
                            </Box>
                          </NavText>
                        )}
                        {expand && (
                          <Box display='flex' flexDirection='row' onClick={() => toggleIcon(parent)}>
                            <Box flexGrow={1} className='navTextTitle' component='span' display='flex' alignItems='center' style={expandPage !== '' && ((parent.name === 'Organization' && expandPage === 'Organization') || (parent.name === 'Settings' && expandPage === 'Settings')) ? { color: '#36C96D', backgroundColor: '' } : parent.link === pagePath ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {parent.name}
                            </Box>
                            <Box className='navTextTitle' component='span' display='flex' alignItems='center' style={expandPage !== '' && ((parent.name === 'Organization' && expandPage === 'Organization') || (parent.name === 'Settings' && expandPage === 'Settings')) ? { color: '#36C96D', backgroundColor: '' } : parent.link === pagePath ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {!parent.items ? null : <ArrowDropDownIcon />}
                            </Box>
                          </Box>
                        )}
                        {parent.items && parent.items.length > 0
                          ? parent.items.map((child, index) => {
                            return (
                              <NavItem key={index} eventKey={`${child.link}`} onClick={() => handlenav(child)}>
                                <NavText>
                                  <Box
                                    className='navTextSubTitle'
                                    component='span'
                                    display='flex'
                                    alignItems='center'
                                    style={{ color: pagePath===child.link?'#3D4977':'#304669BF', display: 'flex', marginLeft: '19px', fontSize: '14px', padding: '0px',fontWeight:'bold !important' }}
                                  >
                                    <ul >
                                      <li style={{fontWeight:pagePath===child.link?'bold':400,}}>
                                        {child.name}
                                      </li>
                                    </ul>
                                  </Box>
                                </NavText>
                              </NavItem>
                            );
                          })
                          : null}
                      </NavItem>
                    );
                  })}
                </SideNav.Nav>
                <Box
                  component='div'
                  style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', position: 'absolute', bottom: '0', marginLeft: '22px' }}
                >
                </Box>
              </SideNav>
            </React.Fragment>
          )}
        />
      </Router>
    </div>
  );

  const employeeLoginMenu = (
    <div>
      <Router>
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              <SideNav
                className='test-css'
                onSelect={(selected) => {
                  const to = selected;
                  if (location.pathname !== to) {
                    history.push(to);
                  }
                }}
                expanded={expand}
                onClick={() => handleToggle}
              >
                <SideNav.Nav defaultSelected={pagePath}>
                  {employeeNavItems.map((parent, index) => {
                    var eventKey = parent.items && parent.items.length > 0 ? parent.id : parent.link;
                    return (
                      <NavItem eventKey={eventKey} key={index}>
                        <NavIcon style={{ width: '85px', marginRight: '-20px', display: 'block', justifyContent: 'center' }}>
                          <parent.Icon onClick={() => toggleIcon(parent)} />
                        </NavIcon>
                        {!expand && (
                          <NavText onClick={() => toggleIcon(parent)}>
                            <Box className='navTextTitle' component='span' display='flex' alignItems='center' style={parent.link === pagePath ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {parent.name} <ArrowDropDownIcon style={{ display: 'none' }} />
                            </Box>
                          </NavText>
                        )}
                        {expand && (
                          <Box display='flex' flexDirection='row' onClick={() => toggleIcon(parent)}>
                            <Box flexGrow={1} className='navTextTitle' component='span' display='flex' alignItems='center' style={parent.link === pagePath ? { color: '#36C96D' } : { color: '#3D4977', fontWeight: 400 }}>
                              {parent.name} <ArrowDropDownIcon style={{ display: 'none' }} />
                            </Box>
                          </Box>
                        )}
                      </NavItem>
                    );
                  })}
                </SideNav.Nav>
                <Box
                  component='div'
                  style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', position: 'absolute', bottom: '0', marginLeft: '22px' }}
                >
                </Box>
              </SideNav>
            </React.Fragment>
          )}
        />
      </Router>
    </div>
  );

  const menu = ((roleCode.toLowerCase() === 'owner' || roleCode.toLowerCase() === 'superadmin' || roleCode.toLowerCase() === 'admin') && companyCode === 'ZKTECO') ? sidebarMenuContent : ((roleCode.toLowerCase() === 'owner' || roleCode.toLowerCase() === 'superadmin' || roleCode.toLowerCase() === 'admin') && companyCode !== 'ZKTECO') ? branchNavItemsMenu : employeeLoginMenu;
  return (
    <Fragment>
      <Drawer anchor='left' open={sidebarToggleMobile} onClose={closeDrawer} variant='temporary' elevation={4} className='app-sidebar-wrapper-lg'>
        <SidebarHeader />
        <PerfectScrollbar>{menu}</PerfectScrollbar>
      </Drawer>
      <Hidden smDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': '',
          })}
          square
          elevation={sidebarShadow ? 11 : 3}
        >
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': '',
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>{menu}</PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>

  );
};

const mapStateToProps = (state) => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
