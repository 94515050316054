import axios from 'axios';
import { toast } from 'react-toastify';
import { JAVA_API_TEMPLATES, JAVA_API_TEMPLATES_LIST } from '../BackendEndPoints';
import { TEMPLATE_LIST } from '../types';

const buildQuery = (filter) => {
  let query = '';
  if (filter.code) {
    query = query + `&code=${filter.code}`;
  }
  if (filter.name) {
    query = query + `&name=${filter.name}`;
  }
  if (filter.version) {
    query = query + `&version=${filter.version}`;
  }
  if (filter.company) {
    query = query + `&company=${filter.company}`;
  }
  if (filter.requestTypeId) {
    query = query + `&requestTypeId=${filter.requestTypeId}`;
  }
  if (filter.description) {
    query = query + `&version=${filter.description}`;
  }
  // if (filter.templateName) {
  //   query = query + `&templateName=${filter.templateName}`;
  // }
  if (filter.requestTypeName) {
    query = query + `&requestTypeName=${filter.requestTypeName}`;
  }
  if (filter.status) {
    query = query + `&status=${filter.status}`;
  }
  return query;
}

export const getTemplatesList = (pageNumber, pageSize, filter) => async (dispatch) => {
  if (filter === undefined || filter === '') {
    filter = {
      code: '',
      name: '',
      version: '',
      company: '',
      requestTypeId: '',
      description: '',
      // templateName: '',
      requestTypeName: '',
      status: ''
    }
  }
  const res = await axios.get(JAVA_API_TEMPLATES_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
  if (res.data.code === 'FHRMSI0001') {
    dispatch({
      type: TEMPLATE_LIST,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: TEMPLATE_LIST,
      payload: [],
    });
  }
};

export const addTemplateType = (data, onSuccess, onFailure) => async (dispatch) => {
  const res = await axios.post(JAVA_API_TEMPLATES, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {
    toast.error();
  }
};

export const updateTemplateType = (id, data, onSuccess, onFailure) => async (dispatch) => {
  const res = await axios.put(JAVA_API_TEMPLATES + '/' + id, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) {
    toast.error();
  }
};

export const deleteTemplate = (id, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.delete(JAVA_API_TEMPLATES + '/' + id);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess();
    } else {
      onFail(res.data);
    }
  } catch (error) {
    toast.error();
  }
};

export const getTemplates = (pageNumber, pageSize, filter, onSuccess, onFailure) => async (dispatch) => {
  if (filter === undefined || filter === '') {
    filter = {
      code: '',
      name: '',
      version: '',
      company: '',
      requestTypeId: '',
      description: '',
    }
  }
  const res = await axios.get(JAVA_API_TEMPLATES_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
  if (res.data.code === 'FHRMSI0001') {
    onSuccess(res.data);
  } else {
    onFailure(res.data);
  }
};