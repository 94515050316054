import axios from 'axios';
import { JAVA_API_TEMPLATE_ITEMS, JAVA_API_TEMPLATE_ITEMS_LIST } from '../BackendEndPoints';
import { TEMPLATE_ITEM_LIST } from '../types';

const buildQuery = (filter) => {
    let query = '';
    if (filter.code) {
        query = query+`&code=${filter.code}`;
    }
    if (filter.name) {
        query = query+`&templateName=${filter.name}`;
    }
    if (filter.hint) {
        query = query+`&hint=${filter.hint}`;
    }
    if (filter.valueType) {
        query = query+`&valueType=${filter.valueType}`;
    }
    if (filter.templateId) {
        query = query+`&templateId=${filter.templateId}`;
    }
    return query;
}

export const addTemplateItem = (data, onSuccess, onFail) => async (dispatch) => {
    const res = await axios.post(JAVA_API_TEMPLATE_ITEMS, data);
    try {
        if (res.data.code === 'FHRMSI0001') {
            onSuccess(res.data);
        } else {
            onFail(res.data);
        }
    } catch (error) {
    }
};

export const getTemplateItemList = (pageNumber, pageSize,filter ) => async (dispatch) => {
    if (filter === undefined || filter === '') {
        filter = {
            name: '',
        }
      }
   
    const res = await axios.get(JAVA_API_TEMPLATE_ITEMS_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
    if (res.data.code === 'FHRMSI0001') {
        dispatch({
            type: TEMPLATE_ITEM_LIST,
            payload: res.data.data,
        });
    } else {
        dispatch({
            type: TEMPLATE_ITEM_LIST,
            payload: [],
        });
    }
};


export const updateTemplateItem = (id, data, onSuccess, onFail) => async (dispatch) => {
    const res = await axios.put(JAVA_API_TEMPLATE_ITEMS + '/' + id, data);
    try {
        if (res.data.code === 'FHRMSI0001') {
            onSuccess();
        } else {
            onFail(res.data);
        }
    } catch (error) {
    }
};

export const deleteTemplateItem = (id, onSuccess, onFail) => async (dispatch) => {
    const res = await axios.delete(JAVA_API_TEMPLATE_ITEMS + '/' + id);
    try {
        if (res.data.code === 'FHRMSI0001') {
            onSuccess();
        } else {
            onFail();
        }
    } catch (error) {
    }
};


export const getTemplateItem = (pageNumber, pageSize, filter, onSuccess, onFailure) => async (dispatch) => {
    if (filter === undefined || filter === '') {
        filter = {
            code: '',
            name: '',
            valueType: '',
            templateId: '',
        }
    }
    const res = await axios.get(JAVA_API_TEMPLATE_ITEMS_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
    if (res.data.code === 'FHRMSI0001') {
        onSuccess(res.data);
    } else {
        onFailure(res.data);
    }
};