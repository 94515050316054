import { COMPANY_BRANCH_LIST, COMPANY_LIST } from "../../actions/types";

const initialState = {
    branchList: [],
    companyBranchList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMPANY_LIST:
            return {
                ...state,
                branchList: action.payload
            };
        case COMPANY_BRANCH_LIST:
            return {
                ...state,
                companyBranchList: action.payload
            };
        default:
            return state;
    }
}