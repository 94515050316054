import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URI_DESIGNATION } from '../../../actions/EndPoints';
import { getDesignations, updateDesignation } from '../../../actions/organization/DesignationApiCalls';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
  customContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 4px #00000029',
    border: '0.5px solid #00000029',
    opacity: 1,
    marginBottom: '5px',
    borderRadius: '14px',
    padding: '18px',
    margin: '24px',
    height: "auto",
    [theme.breakpoints.down("lg")]: {
      height: "auto",
    },
  },
}));

export default function AddDesignation(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector((state) => state);
  const [parentDesignations, setParentDesignations] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.history.location.state ? props.history.location.state?.id : '',
    name: props.history.location.state ? props.history.location.state?.name : '',
    code: props.history.location.state ? props.history.location.state?.code : '',
    description: props.history.location.state ? props.history.location.state?.description : '',
    parentDesignation: props.history.location.state.parentDesignation ? props.history.location.state?.parentDesignation : '',

  });
  // alert(JSON.stringify(props.history.location.state))
  const [error, setError] = React.useState({
    name: false,
    code: false,
    description: false,
    parentDesignation: false,
  });

  useEffect(() => {
    dispatch(
      getDesignations(1, 0, (data) => {
        setParentDesignations(data.designation);
      })
    );
    // eslint-disable-next-line
  }, []);
  const handleClose = (event) => {
    history.push(REACT_URI_DESIGNATION)
  }
  const breadcrumb = [
    { path: REACT_URI_DESIGNATION, name: t('DESI001') },
    { path: '/fams/create-designation', name: props.history.location.state.id === undefined ? t('DESI002') : t("DESI008") },
  ];
  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.designationError)) {
      const { designationError } = store.errors;
      switch (designationError.code) {
        case 'OMSE0081':
          setError({ name: true });
          break;
        case 'OMSE0082':
          setError({ code: true });
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [store.errors.designationError]);



  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let desigObj = {};
      let parentObj = {};
      desigObj.id = state.id;
      desigObj.name = state.name;
      desigObj.code = state.code;
      desigObj.description = state.description;
      parentObj.id = state.parentDesignation.id;
      desigObj.parentDesignation = parentObj;
      dispatch(
        updateDesignation(desigObj, (data) => {
          handlError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.name) && CommonUtil.isEmptyString(state.code)) {
      setError({ name: true });
      toast.error(t('DESI011'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({ code: true });
      toast.error(t('DESI010'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('DESI011'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.name)) {
      setError({ name: true });
      toast.error(t('DESI012'));
      return false;
    }
    if (!CommonUtil.isValidCode(state.code)) {
      setError({ code: true });
      toast.error(t('DESI013'));
      return false;
    }
    return true;
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'OMSI0003':
        history.push(REACT_URI_DESIGNATION);
        break;
      case 'OMSW0002':
        data = data.data.error[0];
        break;
      case 'OMSI0004':
        history.push(REACT_URI_DESIGNATION);
        break;
      default:
        break;
    }

    switch (data.code) {
      case 'OMSE0084':
        setError({ name: true });
        break;
      case 'OMSE0083':
        setError({ code: true });
        break;
      case 'OMSE0082':
        setError({ name: true });
        break;
      case 'OMSE0081':
        setError({ code: true });
        break;
      case 'OMSE0283':
        setError({ parentDesignation: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0003') {
      toast.success(t('DESGCRMG001'));
      handleClose();
    }
    else if (data.code === 'OMSI0004') {
      toast.success(t('DESGUPDSUC001'));
      handleClose();
    }
    else if (data.code === 'OMSE0083') {
      toast.error(t('DESGCRMG003'));
    } else if (data.code === 'OMSE0084') {
      toast.error(t('DESGCRMG002'));
    } else if (data.code === 'OMSE0216') {
      toast.error(t('DESGCRMG004'));
    } else if (data.code === 'OMSE0257') {
      toast.error(t('DESI014'));
    } else {
      toast.error(data.message);
    }
  };

  return (
    <div>
      <Grid  >
        <Breadcrumb links={breadcrumb} />
        <Grid fullWidth className={`${classes.customContainer} background-size`}>
          <Grid container sx={12} xs={12} alignItems="center">
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  required
                  error={error.name}
                  name='name'
                  label={t('COMMON044')}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  defaultValue={state.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 49 }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  required
                  error={error.code}
                  name='code'
                  label={t('COMMON045')}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  defaultValue={state.code}
                  onChange={handleChange}
                  inputProps={{ maxLength: 29 }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={12}
            xs={12}
            alignItems="center"
          >
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={'20px'}>
                <Autocomplete
                  style={{ width: '100%' }}
                  openText={t('COMMON169')}
                  closeText={t('COMMON170')}
                  clearText={t('COMMON215')}
                  noOptionsText={t('COMMON214')}
                  options={parentDesignations}
                  name={state.parentDesignation}
                  value={state.parentDesignation}
                  getOptionLabel={(parentDesignations) => (parentDesignations.name ? parentDesignations.name : '')}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      setState({ ...state, parentDesignation: newValue });
                    } else {
                      setState({ ...state, parentDesignation: '' });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} value={state.parentDesignation} name={state.parentDesignation} error={error.parentDesignation} variant='outlined' size="medium" fullWidth label={t('COMMON050')} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  size='medium'
                  inputProps={{ className: classes.textarea, maxLength: 140 }}
                  label={t('DESI009')}
                  variant='outlined'
                  aria-label='maximum height'
                  value={state.description}
                  name='description'
                  onChange={handleChange}
                  multiline
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Box display="flex" m={1} p={1}>
              <CancelButtons onClick={handleClose} color='primary' variant='outlined' size='small' className='mr-3'>
                {t('COMMON008')}
              </CancelButtons>
              <SubmitButtons onClick={handleSubmit} color='primary' variant='contained' size='small' className='ml-3'>
                {t('COMMON035')}
              </SubmitButtons>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
