import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateRequest } from "../../actions/myRequests/MyRequestsActions";
import CustomButton from "../../components/CustomButton";
import CommonUtil from "../../components/Util/CommonUtils";

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#0000000A',
        borderColor: '#0000000A',
        '&:hover': {
            borderColor: '#0000000A'
        },
    },
}));

export default function ApproveOrRejectRequestDialog(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(props.open);
    const classes = useStyles();
    const [isdisable, setdisable] = React.useState(false);
    const [state, setState] = React.useState({
        id: '',
        status: props.statuss,
        remarks: ''
    });

    const [error, setError] = React.useState({
        remarks: false
    });

    const handleClose = () => {
        props.onClose(false);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };

    useEffect(() => {
        setOpen(props.open);
        if (!CommonUtil.isEmpty(props.selectedObject)) {
            setState({
                ...state,
                id: props.selectedObject.auditLogId,
            });
        }
        //eslint-disable-next-line
    }, [props.open, props.selectedObject])

    const handleSubmit = () => {
        if (props.statuss === "REJECTED" && CommonUtil.isEmptyString(state.remarks)) {
            setError({ remarks: true })
            toast.error(t("APPROVER0006"))
        } else {
            const update = {
                status: state.status,
                remarks: state.remarks
            }
            if (!CommonUtil.isEmptyString(state.id)) {
                setdisable(true)
                dispatch(updateRequest(state.id, update,
                    (onSuccess) => {
                        handleClose();
                        switch (onSuccess.code) {
                            case 'FHRMSE0077':
                                toast.success(t('MYREQ00011'));
                                break;
                            case 'FHRMSE0078':
                                toast.success(t('MYREQ0009'));
                                break;
                            default:
                                break;
                        }
                    },
                    (onFail) => {
                        setdisable(false)
                        handleClose();
                        switch (onFail.code) {
                            case 'FHRMSE0094':
                                toast.error(onFail.message);
                                break;
                            default:
                                toast.error(onFail.message);
                        }
                    }
                ))
            }
        }
    }

    return (
        <>
            <Dialog maxWidth='xl' open={open} aria-labelledby='form-dialog-title' className='confirm-modals'>
                <DialogTitle id='form-dialog-title'>
                    <Box display='flex' mb={3}>
                        <Box flexGrow={1} display='flex' justifyContent={'left'}>
                            <Typography variant='h5' style={{ fontSize: '20px', color: '#304669', marginTop: '5px' }} >{props.title}</Typography>
                        </Box>
                        <Tooltip arrow title={t('COMMON170')}>
                            <CloseRoundedIcon onClick={handleClose} style={{ color: "#B7B7B7", background: "white", borderRadius: "50%" }} fontSize="small" className='close_icon' />
                        </Tooltip>
                    </Box>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Grid display='flex' xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Box display='flex' mb={5} mt={3}>
                                <Typography variant='h5' color="#304669">{props.text}</Typography>
                            </Box>
                        </Grid>
                        <form noValidate autoComplete='off'>
                            <Grid item xs={12} sm={12}>
                                <Box display='flex' justifyContent='center' >
                                    <TextField
                                        fullWidth
                                        error={error.remarks}
                                        name='remarks'
                                        label={props.lebal}
                                        helperText=''
                                        variant='outlined'
                                        rows={5}
                                        multiline
                                        className={classes.root}
                                        value={state.remarks}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 120, Width: 600 }}
                                    />
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                </DialogContent>
                <DialogActions >
                    <Grid container direction="row" justifyContent="center" alignItems="center" >
                        <CustomButton onClick={handleClose} borderColor={props.statuss === 'REJECTED' ? '#FC5F46' : '#36C96D'} disabled={isdisable} hoverborderColor={props.statuss === 'REJECTED' ? '#E5482F' : '#1AB755'} size='medium' variant='outlined' className='mr-2'
                            color={props.statuss === 'REJECTED' ? '#FC5F46' : '#36C96D'} hovercolor={props.statuss === 'REJECTED' ? '#E5482F' : '#1AB755'}>
                            {t('COMMON008')}
                        </CustomButton>
                        <CustomButton onClick={handleSubmit} color='#FFFFFF' backgroundColor={props.statuss === 'REJECTED' ? '#FC5F46' : '#36C96D'} disabled={isdisable} hoverbackgroundColor={props.statuss === 'REJECTED' ? '#E5482F' : '#1AB755'} size='medium' className='ml-2' >
                            {props.statuss === 'REJECTED' ? t('DASHBOARD0007') : t('DASHBOARD0006')}
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}