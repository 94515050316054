import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { REACT_URL_BRANCH } from '../../../actions/EndPoints';
import { createOwnerUser } from '../../../actions/organization/BranchApiCalls';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
import { countryList } from '../../../components/Util/CountryList';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: "700px",
        [theme.breakpoints.down("lg")]: {
            height: "460px",
        },
    },
}));
export default function AddBranch(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [isdisable, setdisable] = React.useState(false);
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        name: '',
        code: '',
        country: '',
        registrationNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        createSubReseller: false,
    });
    const countries = countryList;
    const handleCountryChange = (event) => {
        if (event === null) {
            setState({
                ...state,
                country: ''
            });
        }
        else {
            setState({
                ...state,
                country: event.country,
            });
        }
        setError({
            country: false,
        });
    };

    const [error, setError] = React.useState({
        code: false,
        firstName: false,
        description: false,
    });

    const handleClose = () => {
        history.push(REACT_URL_BRANCH);
    };
    const breadcrumb = [
        { path: REACT_URL_BRANCH, name: t('BRANCHCONFIG0007') },
        { path: '/fams/create-branch', name: t('BRANCHOO1') },
    ];
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };

    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(state.firstName)) {
            setError({ firstName: true })
            toast.error(t("REQUESTTYPES0009"))
        } else if (!CommonUtil.validateName(state.firstName)) {
            setError({ firstName: true })
            toast.error(t("BRANCH0001"))
        } else if (!CommonUtil.isEmptyString(state.lastName) && !CommonUtil.validateName(state.lastName)) {
            setError({ lastName: true })
            toast.error(t("lastnameValidation"))
        } else if (CommonUtil.isEmptyString(state.email)) {
            setError({ email: true })
            toast.error(t("REQUESTTYPES0013"))
        } else if (!CommonUtil.isValidEmail(state.email)) {
            setError({ email: true })
            toast.error(t("COMMON155"))
        } else if (CommonUtil.isEmptyString(state.name)) {
            setError({ name: true })
            toast.error(t("Branch0009"))
        } else if (!CommonUtil.validateName(state.name)) {
            setError({ name: true })
            toast.error(t("nameValidation"))
        }
        else if (CommonUtil.isEmptyString(state.country)) {
            setError({ country: true })
            toast.error(t("COMMON193"))
        } else {
            const update = {
                userFirstName: state.firstName,
                userLastName: state.lastName,
                userEmail: state.email,
                code: state.code,
                name: state.name,
                country: state.country,
                registrationNumber: 'mannu1234l',
            }
            setdisable(true)
            dispatch(createOwnerUser(update,
                () => {
                    handleClose();
                    toast.success(t('Branch0004'));
                },
                (error) => {
                    setdisable(false)
                    onFail(error);
                }
            ));
        }
    }

    const onFail = (data) => {
        if (data.code === '') {
            toast.error(t('REQUESTTYPES0009'));
            return;
        }
        if (data) {
            switch (data.code) {
                case "FHRMSE0042":
                    setError({ code: true });
                    break;
                case "FHRMSE0043":
                    setError({ name: true });
                    break;
                case "UASE0060":
                    setError({ name: true });
                    break;
                default:
                    setError({ ...error });
            }
            toast.error(data.message);
        }
    }

    return (
        <>
            <Grid  >
                <Breadcrumb links={breadcrumb} />
                <Grid fullWidth className={`${classes.customContainer} background-size`}>
                    <Grid item xs={12} sm={12} >
                        <Box display="flex" justifyContent="start" ml={1} pl={1}>
                            <Typography variant="h1" style={{ color: '#304669', fontSize: '15px' }} >{t("COMMON092")}</Typography>
                        </Box>
                    </Grid>
                    <Grid container sx={12} xs={12} alignItems="center" >
                        <Grid item xs={12} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.firstName}
                                    name="firstName"
                                    label={t("PERSON003") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.firstName}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.lastName}
                                    name="lastName"
                                    label={t("PERSON004")}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.lastName}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <TextField
                                fullWidth
                                error={error.email}
                                name="email"
                                label={t("PERSON005") + '*'}
                                helperText=""
                                variant="outlined"
                                size="medium"
                                value={state.email}
                                onChange={handleChange}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={12}  >
                        <Box display="flex" justifyContent="start" ml={1} mt={2} pl={1}>
                            <Typography variant="h1" style={{ color: '#304669', fontSize: '15px' }}>{t("BRANCH001")}</Typography>
                        </Box>
                    </Grid>
                    <Grid container sx={12} xs={12} alignItems="center">
                        <Grid item xs={12} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.name}
                                    name="name"
                                    label={t("Branch0012")}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.name}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <Autocomplete
                                    clearText={t('COMMON216')}
                                    openText={t('COMMON169')}
                                    closeText={t('COMMON170')}
                                    noOptionsText={t('COMMON214')}
                                    fullWidth
                                    size="medium"
                                    name='country'
                                    options={countries}
                                    getOptionLabel={(option) => option.country}
                                    onChange={(event, newValue) => {
                                        handleCountryChange(newValue);
                                    }}
                                    value={{ country: state.country }}
                                    renderInput={(params) => <TextField {...params}
                                        name='country'
                                        className='login_input'
                                        variant='outlined'
                                        fullWidth
                                        label={t('COMMON189') + ' *'}
                                        helperText=""
                                        error={error.country}
                                        value={state.country}
                                        size='small' />}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Box display="flex" m={1} p={1}>
                            <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
                                {t("COMMON008")}
                            </CancelButtons>
                            <SubmitButtons onClick={handleSubmit} disabled={isdisable} color="primary" variant="contained" className='m-2'>
                                {t("COMMON035")}
                            </SubmitButtons>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
