import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStatusCounts } from "../../actions/myRequests/MyRequestsActions";
import PendingIcon from "../../assets/images/dashboard/Group 8343.svg";
import ApprovedIcon from "../../assets/images/dashboard/Group 8365.svg";
import RejectedIcon from "../../assets/images/dashboard/Group 8366.svg";
import CencelIcon from "../../assets/images/dashboard/Group 8367.svg";
import DashboardTiles from "../dashboard/DashboardTiles";

function DashboardCards(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [counts, setCounts] = React.useState({});
  useEffect(() => {
    dispatch(getStatusCounts());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store.myRequest.requestsStatusCountsList && Object.keys(store.myRequest.requestsStatusCountsList).length !== 0) {
      setCounts(store.myRequest.requestsStatusCountsList);
    }
    //eslint-disable-next-line
  }, [store.myRequest.requestsStatusCountsList && Object.keys(store.myRequest.requestsStatusCountsList).length !== 0])
  return (
    <>
      <Grid container spacing={3}>
        <DashboardTiles >
          <div onClick={() => props.handleStatusClick("PENDING")}>
            <Card className="card mb-4">
              <CardContent className="p-4">
                <div className="align-box-row align-items-start">
                  <div id='0' className="font-weight-bold">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ color: "#30466980" }}
                    >
                      {t('DASHBOARD0001')}
                    </Typography>
                    <span
                      className="font-size-xxl mt-5"
                      style={{ color: "#3D4977", fontSize: "40px" }}
                    >
                      {counts.pending ? counts.pending : 0}
                    </span>
                  </div>
                  <div className="ml-auto">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={PendingIcon}
                      width="50px"
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </DashboardTiles>
        <DashboardTiles>
          <div onClick={() => props.handleStatusClick("APPROVED")}>
            <Card className="card mb-4">
              <CardContent className="p-4">
                <div className="align-box-row align-items-start">
                  <div id='1' className="font-weight-bold">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ color: "#30466980" }}
                    >
                      {t('DASHBOARD0002')}
                    </Typography>
                    <span
                      className="font-size-xxl mt-2"
                      style={{ color: "#3D4977", fontSize: "40px" }}
                    >
                      {counts.approved ? counts.approved : 0}
                    </span>
                  </div>
                  <div className="ml-auto">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={ApprovedIcon}
                      width="50px"
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </DashboardTiles>
        <DashboardTiles>
          <div onClick={() => props.handleStatusClick("REJECTED")}>
            <Card className='card mb-4'>
              <CardContent className='p-4'>
                <div className='align-box-row align-items-start'>
                  <div className='font-weight-bold'>
                    <Typography gutterBottom variant='h5' component='h2' style={{ color: "#30466980" }}>
                      {t('DASHBOARD0003')}
                    </Typography>
                    <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '40px' }}>
                      {counts.rejected ? counts.rejected : 0}
                    </span>
                  </div>
                  <div className='ml-auto'>
                    <img alt='...' className='img-fluid' src={RejectedIcon} width="50px" />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </DashboardTiles>
        <DashboardTiles>
          <div onClick={() => props.handleStatusClick("CANCELLED")}>
            <Card className='card mb-4'>
              <CardContent className='p-4'>
                <div className='align-box-row align-items-start'>
                  <div className='font-weight-bold'>
                    <Typography gutterBottom variant='h5' component='h2' style={{ color: "#30466980" }}>
                      {t('DASHBOARD0005')}
                    </Typography>
                    <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '40px' }}>
                      {counts.cancelled ? counts.cancelled : 0}
                    </span>
                  </div>
                  <div className='ml-auto'>
                    <img alt='...' className='img-fluid' src={CencelIcon} width="50px" />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </DashboardTiles>
      </Grid>
    </>
  )
}
export default DashboardCards;
