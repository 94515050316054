import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const DeleteIcon = (props) => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={props.disabled === true ?t('TEMP003'):t('COMMON004')}>
        {props.disabled === true ? <Grid>
          <svg xmlns="http://www.w3.org/2000/svg" width="17.114" height="22.004" viewBox="0 0 17.114 22.004">
            <path id="Path_6658" data-name="Path 6658" d="M18.447,10.335V22.559H8.667V10.335h9.78M16.613,3H10.5L9.279,4.222H5V6.667H22.114V4.222H17.836Zm4.279,4.89H6.222V22.559A2.452,2.452,0,0,0,8.667,25h9.78a2.452,2.452,0,0,0,2.445-2.445Z" transform="translate(-5 -3)" fill="#304669" opacity="0.6" />
          </svg>
        </Grid> :
          <Grid
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            {isShown ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="17.114" height="22.004" viewBox="0 0 17.114 22.004">
                <path id="Path_6658" data-name="Path 6658" d="M18.447,10.335V22.559H8.667V10.335h9.78M16.613,3H10.5L9.279,4.222H5V6.667H22.114V4.222H17.836Zm4.279,4.89H6.222V22.559A2.452,2.452,0,0,0,8.667,25h9.78a2.452,2.452,0,0,0,2.445-2.445Z" transform="translate(-5 -3)" fill="#304669" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="17.114" height="22.004" viewBox="0 0 17.114 22.004">
                <path id="Path_6658" data-name="Path 6658" d="M18.447,10.335V22.559H8.667V10.335h9.78M16.613,3H10.5L9.279,4.222H5V6.667H22.114V4.222H17.836Zm4.279,4.89H6.222V22.559A2.452,2.452,0,0,0,8.667,25h9.78a2.452,2.452,0,0,0,2.445-2.445Z" transform="translate(-5 -3)" fill="#304669" opacity="0.9" />
              </svg>
            )}
          </Grid>
        }
      </Tooltip>
    </>
  );
};
export default DeleteIcon;
