import axios from 'axios';

import { JAVA_API_DEPARTMENT } from './ApiEndPoints';
import { DEPARTMENT_LIST } from './types';

export const getDepartments = (pageNumber, pageSize, onSuccess) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_DEPARTMENT + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: DEPARTMENT_LIST,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: DEPARTMENT_LIST,
        payload: [],
      });
    }
  } catch { }
};

export const updateDepartment = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === undefined ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_DEPARTMENT, payload);
    } else {
      res = await axios.put(JAVA_API_DEPARTMENT + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch { }
};

export const deleteDepartment = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_DEPARTMENT, { data: deleteObj });
    callBack(res.data);
  } catch { }
};
