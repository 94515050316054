
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getApprovalWorkflowList } from '../../../actions/approvalWorkflow/ApprovalWorkflowActions';
import { REACT_URI_WORKFLOW_NODE } from '../../../actions/EndPoints';
import { addWorkflowNode, getApprovalConditionList, updateWorkflowNodeType } from '../../../actions/WorkflowNode/WorkflowNodeActions';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  customContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 4px #00000029',
    border: '0.5px solid #00000029',
    opacity: 1,
    marginBottom: '5px',
    borderRadius: '14px',
    padding: '18px',
    margin: '24px',
    height: "700px",
    [theme.breakpoints.down("lg")]: {
      height: "400px",
    },
  },
}));

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 5;
const MenuPropss = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 2,
    },
  },
};

export default function AddWorkflowNode(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [approvalworkflows, setApprovalworkflows] = React.useState([]);
  const invalidChars = ['-', '+', 'e', '.', '0'];// eslint-disable-next-line
  const [approverList, setApproverList] = React.useState([]);
  const [state, setState] = React.useState({
    id: '',
    code: "",
    name: "",
    nodeSequense: "",
    requestType: "",
    description: "",
    employeeId: "",
    approvalworkflow: "",
  });

  useEffect(() => {
    if (!CommonUtil.isEmpty(props.history.location.state)) {
      setState({
        id: props.history.location.state ? props.history.location.state?.id : '',
        code: props.history.location.state ? props.history.location.state?.code : "",
        name: props.history.location.state ? props.history.location.state?.name : "",
        nodeSequense: props.history.location.state ? props.history.location.state?.nodeSequence : "",
        requestType: props.history.location.state.requestTypeId ? props.history.location.state?.requestTypeId.id : "",
        description: props.history.location.state ? props.history.location.state?.description : "",
        employeeId: props.history.location.state.employeeId ? props.history.location.state?.employeeId : "",
        approvalworkflow: props.history.location.state.approvalWorkflowId ? props.history.location.state?.approvalWorkflowId.id : "",
      });
    }

  }, [props.history.location.state])

  const [error, setError] = React.useState({
    name: false,
    code: false,
    nodeSequense: false,
    approvalworkflow: false,
    employeeId: false,
  });

  const handleClose = () => {
    history.push(REACT_URI_WORKFLOW_NODE)
  };
  const breadcrumb = [
    { path: REACT_URI_WORKFLOW_NODE, name: t('APPROAVWORKFLNODEOW0007') },
    { path: '/fams/create-WorkflowNode', name: props.history.location.state?.id === undefined ? t("APPROAVWORKFLNODEOW0008") : t("APPROAVWORKFLNODEOW0009") },
  ];

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === 'approvalworkflow') {
      let reqTypeId = '';// eslint-disable-next-line
      approvalworkflows.map((data) => {
        if (data.id === event.target.value) {
          reqTypeId = data.requestTypeId.id;// eslint-disable-next-line
          return;
        }
      });
      loadApprovalConditions(reqTypeId);
      setApproverList([]);
      setState({
        ...state,
        [name]: event.target.value,
        employeeId: ''
      });
    } else {
      setState({
        ...state,
        [name]: event.target.value,
      });
    }
    setError({
      [name]: false,
    });
  };

  //dropdown approvalworkflows
  useEffect(() => {
    loadApprovalworkflows();
    if (props.history.location.state.requestTypeId) {
      loadApprovalConditions(props.history.location.state.requestTypeId.id);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.approvalWorkflow.approvalWorkflowList)) {
      setApprovalworkflows(store.approvalWorkflow.approvalWorkflowList.approval_workflows);
    } else {
      setApprovalworkflows([]);
    }
  }, [store.approvalWorkflow.approvalWorkflowList]);

  const loadApprovalworkflows = () => {
    dispatch(getApprovalWorkflowList(1, 0, ''));
  }

  //dropdown ApprovalCondition
  const loadApprovalConditions = (reqTypeId) => {
    const filter = {
      requestTypeId: reqTypeId,
    }
    dispatch(getApprovalConditionList(1, 0, filter, (resp) => {// eslint-disable-next-line
      const empList = resp.data.approval_conditions.map((data) => {
        return data.employeeId;
      })
      setApproverList(empList);
    }, () => {
    }));
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.approvalworkflow)) {
      setError({ approvalworkflow: true })
      toast.error(t("APPROAVWORKFLNODEOW0005"))
    } else if (CommonUtil.isEmptyString(state.employeeId)) {
      setError({ employeeId: true })
      toast.error(t("APPROAVWORKFLNODEOW006"))
    } else if (CommonUtil.isEmptyString(state.code)) {
      setError({ code: true })
      toast.error(t("REQUESTTYPES0003"))
    } else if (!CommonUtil.validateCode(state.code)) {
      setError({ code: true })
      toast.error(t("codeValidation"))
    } else if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true })
      toast.error(t("REQUESTTYPES0004"))
    } else if (!CommonUtil.validateName(state.name)) {
      setError({ name: true })
      toast.error(t("nameValidation"))
    } else if (CommonUtil.isEmptyString(state.nodeSequense)) {
      setError({ nodeSequense: true })
      toast.error(t("APPROAVWORKFLNODEOW0016"))
    }
    else if (CommonUtil.validateNode(state.nodeSequense)) {
      setError({ nodeSequense: true })
      toast.error(t("APPROAVWORKFLNODEOW0017"))
    }
    else {
      let reqTypeId = '';// eslint-disable-next-line
      approvalworkflows.map((data) => {
        if (data.id === state.approvalworkflow) {
          reqTypeId = data.requestTypeId.id;// eslint-disable-next-line
          return;
        }
      });
      const update = {
        name: state.name,
        nodeSequence: state.nodeSequense,
        code: state.code,
        approvalWorkflowId: state.approvalworkflow,
        description: state.description,
        userId: state.employeeId.userId,
        requestTypeId: reqTypeId,
        employeeId: state.employeeId.id,
      }
      if (!CommonUtil.isEmptyString(state.id)) {
        dispatch(updateWorkflowNodeType(state.id, update,
          () => {
            handleClose();
            toast.success(t('APPROAVWORKFLNODEOW0014'));
          },
          (error) => {
            switch (error.code) {
              case 'FHRMSE0042':
                setError({ code: true });
                break;
              case 'FHRMSE0043':
                setError({ name: true });
                break;
              case 'FHRMSE0056':
              case 'FHRMSE0065':
              case 'FHRMSE0108':
              case 'FHRMSE0109':
                setError({ employeeId: true });
                break;
              default:
                break;
            }
            if (error.employeeId) {
              toast.error(t('APPROAVWORKFLNODEOW006'));
            } else {
              toast.error(error.message);
            }
          }
        ));
      } else {
        dispatch(addWorkflowNode(update, () => {
          handleClose();
          toast.success(t('APPROAVWORKFLNODEOW0012'));
        },
          (error) => {
            switch (error.code) {
              case 'FHRMSE0042':
                setError({ code: true });
                break;
              case 'FHRMSE0043':
                setError({ name: true });
                break;
              case 'FHRMSE0065':
              case 'FHRMSE0108':
                setError({ employeeId: true });
                break;
              case 'FHRMSE0066':
              case 'FHRMSE0106':
                setError({ nodeSequense: true });
                break;
              default:
                break;
            }
            toast.error(error.message);
          }
        ));
      }
    }
  }

  return (
    <>
      <Grid>
        <Breadcrumb links={breadcrumb} />
        <Grid className={`${classes.customContainer} background-size`}>
          <Grid container sx={12} xs={12} alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FormControl variant='outlined' size='medium' fullWidth>
                  <InputLabel>{t('APPROAVWORKFLNODEOW0002') + '*'}</InputLabel>
                  <Select
                    MenuProps={MenuPropss}
                    error={error.approvalworkflow}
                    label={t('APPROAVWORKFLNODEOW0002') + '*'}
                    name='approvalworkflow' onChange={handleChange}
                    value={state.approvalworkflow}
                    disabled={props.history.location.state.id ? true : false}
                    defaultValue={state.approvalworkflow}>
                    <MenuItem value='' selected>
                      <em>{t('NAN001')}</em>
                    </MenuItem>
                    {approvalworkflows ? (
                      approvalworkflows.map((data, index) => (
                        <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                          <Typography variant='inherit' noWrap>
                            {data.name}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <Autocomplete
                  style={{ width: '100%' }}
                  openText={t('COMMON169')}
                  closeText={t('COMMON170')}
                  clearText={t('COMMON215')}
                  noOptionsText={t('COMMON214')}
                  name='employeeId'
                  value={state.employeeId}
                  options={CommonUtil.isEmptyArray(approverList) ? [] : approverList}
                  getOptionLabel={(option) => `${option.firstName ? option.firstName : ''}${option.lastName ? ' ' + option.lastName : ''}`}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                      setError(false);
                      setState({ ...state, employeeId: newValue });
                    } else {
                      setState({ ...state, employeeId: '' });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name='employeeId' value={state.employeeId} error={error.employeeId} variant='outlined' fullWidth label={t('APPROVER0001') + '*'} />
                  )}
                  renderOption={(option) => {
                    return <span style={{ fontWeight: 'bold' }}>{option.firstName} {option.lastName ? option.lastName : ''}<br /><span style={{ fontSize: '10px', fontWeight: 'normal' }} >{`${option.email}`}</span></span>;
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={12} xs={12} alignItems="center">
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  error={error.code}
                  name='code'
                  label={t('TEMTYPE001') + '*'}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  value={state.code}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 30 }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  error={error.name}
                  name='name'
                  label={t('COMMON014') + '*'}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  value={state.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={12} xs={12} alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  error={error.nodeSequense}
                  name='nodeSequense'
                  label={t('APPROAVWORKFLNODEOW0001') + '*'}
                  helperText=''
                  type="number"
                  variant='outlined'
                  className={classes.number}
                  size='medium'
                  value={state.nodeSequense}
                  disabled={props.history.location.state.id ? true : false}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (invalidChars.includes(e.key) || 'ArrowUp' === e.key || 'ArrowDown' === e.key) {
                      e.preventDefault();
                    }
                  }}
                  onWheel={e => {
                    e.target.blur();
                  }}
                  inputProps={{ maxLength: 50 }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  multiline
                  minrows={3}
                  fullWidth
                  error={error.description}
                  name='description'
                  label={t('REQUESTTYPES0002')}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  value={state.description}
                  onChange={handleChange}
                  inputProps={{ maxLength: 250 }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box display="flex" m={0.5} p={0.5}>
              <CancelButtons onClick={handleClose} color='primary' variant='outlined' className='m-2'>
                {t('COMMON008')}
              </CancelButtons>
              <SubmitButtons onClick={handleSubmit} color='primary' variant='contained' className='m-2'>
                {t('COMMON035')}
              </SubmitButtons>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

