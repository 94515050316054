export const CONSTANT = {
  LOCAL_STORAGE_LANG_KEY: 'RC_LANG_KEY',
  LANGUAGES: [
    { key: 'en', name: 'english' },
    { key: 'zh', name: 'chinese' },
    { key: 'es', name: 'spanish' },
  ],
  SUUPORTED_LANGUAGES: ['en', 'zh', 'es'],
};
export const DEBUG = true;
