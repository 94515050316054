
import React from 'react'
import { Button, withStyles } from '@material-ui/core';

export default function CancelButtons(props){
    const ColorButton = withStyles((theme) => ({
        root: {
            color: '#36C96D',
            minWidth:'150px',
            minHeight:'50px',
            opacity: 1,
            borderColor: '#36C96D',
            fontWeight: 100,  
             '&:hover': {
                borderColor: '#1AB755',  
                color:'#1AB755',
            },
        },
    }))(Button);
    return(
        <ColorButton {...props} />
    )
}
