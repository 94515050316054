import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StatusTab = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box style={{ display: 'flex', paddingLeft: '10px' }}>
        <div>
          {t(props.status)}
        </div>
        <span style={{ marginLeft: '10px' }}>
          {props.Icon}
        </span>
      </Box>
    </>
  )
}
const CustomColor = (props) => {
  function SwitchCase(props) {
    switch (props.row) {
      case 'APPROVED':
        return (<div style={{ color: '#36C96D', backgroundColor: '#36C96D26', opacity: 1 }}>
          <StatusTab status={'APPROVED'} />
        </div>)
      case 'REJECTED':
        return (<div style={{ color: '#E3393C', backgroundColor: '#E3393C26', opacity: 1 }}>
          <StatusTab status={'REJECTED'} />
        </div>)
      case 'CANCELLED':
        return (
          <div style={{ color: '#EDBD37', backgroundColor: '#EDBD3726', opacity: 1 }}>
            <StatusTab status={'CANCELLED'} />
          </div>
        );
      case 'PENDING':
        return (
          <div style={{ color: '#F89A2D', backgroundColor: '#F89A2D26', opacity: 1 }}>
            <StatusTab status={'PENDING'} />
          </div>
        );
      default:
        return <div>"not found"</div>
    }
  }
  return (
    <div>
      {
        <SwitchCase row={props.row} />
      }
    </div>
  )
}

export default CustomColor

