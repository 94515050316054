import { Tooltip } from "@material-ui/core";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { deleteApprovalWorkflow, getApprovalWorkflowList } from '../../../actions/approvalWorkflow/ApprovalWorkflowActions';
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from '../../../components/ConfirmModalNew';
import DataTable from '../../../components/DataTableNew';
import HeaderToolbar from "../../../components/HeaderTooltip";
import CommonUtil from '../../../components/Util/CommonUtils';
import DeleteIcon from '../../reusableIcons/DeleteIcon';
import EditIcon from '../../reusableIcons/EditIcon';

export default function ApprovalWorkflow() {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        loadData(page, rowsPerPage);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.approvalWorkflow.approvalWorkflowList)) {
            setPage(store.approvalWorkflow.approvalWorkflowList.currentPage - 1);
            setData(store.approvalWorkflow.approvalWorkflowList.approval_workflows);
            setTotalPages(store.approvalWorkflow.approvalWorkflowList.totalPages)
        } else {
            setPage(0);
            setData([]);
        }

    }, [store.approvalWorkflow.approvalWorkflowList]);

    const loadData = (page, rowsPerPage) => {
        dispatch(getApprovalWorkflowList(page, rowsPerPage, '', ''));
    };

    const handleChangeRowsPerPage = (newPage, size) => {
        setRowsPerPage(size);
        setPage(newPage);
        loadData(newPage, size);
    };
    const CreateApprovalWorkflow = () => {
        history.push
            ({
                pathname: '/fams/create-approvalWorkflow',
                state: data,
            });
    };
    const EditApprovalWorkflow = (data) => {
        history.push
            ({
                pathname: '/fams/create-approvalWorkflow',
                state: data,
            });
    };
    const breadcrumb = [{ path: '', name: t('APPROAVWORKFLOW0002') }];
    //eslint-disable-next-line
    const columns = React.useMemo(() => [
        {
            field: "code",
            headerName: t('APPROVALCONDITION0007'),
            flex: 1,
            width: 10,
            renderCell: (params) => (
                <Tooltip title={params.row.code} >
                    <span className="table-cell-trucate">{params.row.code}</span>
                </Tooltip>
            ),
        },
        {
            field: "name",
            headerName: t('APPROVALCONDITION0008'),
            flex: 1,
            width: 10,
            renderCell: (params) => (
                <Tooltip title={params.row.name} >
                    <span className="table-cell-trucate">{params.row.name}</span>
                </Tooltip>
            ),
        },
        {
            field: "requestTypeId.name",
            headerName: t('BRANCHCONFIG0004'),
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => params.row.requestTypeId.name,
            renderCell: (params) => (
                <Tooltip title={params.row.requestTypeId.name} >
                    <span className="table-cell-trucate">{params.row.requestTypeId.name}</span>
                </Tooltip>
            ),
        },
        {
            field: "description",
            headerName: t('RestrictTypeForm005'),
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={params.row.description} >
                    <span className="table-cell-trucate">{params.row.description}</span>
                </Tooltip>
            ),
        },
        {
            field: "Actions",
            headerName: t('dataTableAction'),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    onClick={() => EditApprovalWorkflow(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => deleteRecord(params.row)}
                />,
            ],
        },
    ]);

    const deleteRecord = (data) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmModal
                        open={true}
                        text={t('COMMON018')}
                        title={t('COMMON004')}
                        onConfirm={() => {
                            dispatch(
                                deleteApprovalWorkflow(data.id,
                                    (data) => {
                                        toast.success(t('APPROAVWORKFLOW0001'));
                                        loadData(0, rowsPerPage);
                                    },
                                    (data) => {
                                        switch (data.code) {
                                            case "FHRMSE0054":
                                                toast.error(t("WORKFLOWEDELETE"));
                                                break;
                                            default:
                                                toast.error(data.message);
                                        }
                                    }
                                ));
                            onClose();
                        }}
                        onClose={() => onClose()}
                    />
                );
            },
        });
    }

    return (
        <>
            <Breadcrumb links={breadcrumb}>
                <HeaderToolbar src={AddIcon} onClick={CreateApprovalWorkflow} tooltipTitle={t('COMMON009')} size='large' />
            </Breadcrumb>
            <DataTable
                columns={columns}
                rows={data}
                page={page}
                pageCount={totalPages}
                pageSize={rowsPerPage}
                handleChange={(newPage, size) => handleChangeRowsPerPage(newPage, size)}
            />
        </>
    );
}
