import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonUtil from "./Util/CommonUtils";

export default function CustomTextfield(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const [value, setValue] = useState(props.value);
  const { t } = useTranslation();

  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
    //eslint-disable-next-line
  }, [props.error]);

  const handleChange = (e) => {
    var val = e.target.value;
    if (val.trim() === "") {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length === 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === " " && val[val.length - 2] === " ") {
        return;
      }
      if (val[0] === " ") {
        return;
      }
    }
    if (props.regex === "none" && props.validation) {
      switch (props.validation) {
        case "alpha-numeric":
          handleCode(e);
          break;
        case "numeric":
          handleNumeric(e);
          break;
        case "email":
          handleEmail(e);
          break;
        case "code":
          handleCode(e);
          break;
        default:
          props.handleChange(e);
      }
    }
  };

  useEffect(() => {
    setValue(props.value);
    //eslint-disable-next-line
  }, [props.value]);

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('Only accepts code'));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("Only accepts numerics"));
    }
  };

  const handleEmail = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      props.isValid ?
        setHelperText(t("FORGOT0001")) : setHelperText(t('LOGIN0001'));
    }
  };

  return (
    <TextField
      fullWidth
      style={{ background: "" }}
      autoComplete="off"
      required={props.required}
      disabled={props.disabled}
      size="large"
      margin="normal"
      type={props.type}
      label={props.label}
      name={props.name}
      variant="outlined"
      value={value}
      onChange={(e) => handleChange(e)}
      onBlur={props.onBlur}
      helperText={helperText}
      error={error}
      className='login_input'
      InputLabelProps={props.InputLabelProps ? { shrink: props.InputLabelProps } : undefined}
      InputProps={
        props.InputProps
      }
    />
  );
}

CustomTextfield.defaultProps = {
  validation: "none",
  regex: "none",
  required: false,
  disabled: false,
};

CustomTextfield.propType = {
  validation: PropTypes.oneOf([
    "alpha-numeric",
    "alphabets",
    "numeric",
    "email",
    "password",
    "code",
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};
