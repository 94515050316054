import Grid from "@material-ui/core/Grid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { cancelMyRequest, getMyRequestsList } from "../../actions/myRequests/MyRequestsActions";
import AddIcon from '../../assets/images/Group 8073.svg';
import Breadcrumb from '../../components/Breadcrumb';
import HeaderToolbar from "../../components/HeaderTooltip";
import CommonUtil from "../../components/Util/CommonUtils";
import CancelIcon from "../reusableIcons/CancelIcon";
import CopyIcon from "../reusableIcons/CopyIcon";
import ViewIconA from "../reusableIcons/ViewIconA";
import ViewRequestDetails from "./ViewRequestDetails";
import EditIcon from '../reusableIcons/EditIcon';
import ConfirmModal from "../../components/ConfirmModalNew";
import DataTable from "../../components/DataTableNew";

export default function MyRequests() {
    const { t } = useTranslation();
    const store = useSelector((state) => state);
    const breadcrumb = [{ path: '', name: t('MYREQ0001') }];
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [totalPages, setTotalPages] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedObject, setSelectedObject] = React.useState();
    const [openView, setOpenView] = React.useState(false);
    const [isdisable, setdisable] = React.useState(false);
    //eslint-disable-next-line
    const columns = React.useMemo(() => [
        {
            field: "name",
            headerName: t('COMMON044'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.name,
        },

        {
            field: 'requestTypeId.name',
            headerName: t('BRANCHCONFIG0004'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.requestTypeId.name,
        },
        {
            field: 'createdAt',
            headerName: t('DEPT006'),
            flex: 1,
            width: 10,
            valueGetter: (params) => CommonUtil.getLocalDate(params.row.createdAt),
        },
        {
            field: 'status',
            headerName: t('COMMON001'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.status,
        },
        {
            field: "Actions",
            headerName: t('dataTableAction'),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<ViewIconA />}
                    onClick={() => openViewRequestDailog(params.row)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon disabled={params.row.status !== 'DRAFT'} />}
                    onClick={() => EditMyRequestpage(params.row)}
                    disabled={params.row.status !== 'DRAFT'}
                />,
                <GridActionsCellItem
                    icon={<CancelIcon disabled={isdisable}/>}
                    onClick={() => cancelMyrequest(params.row)}
                />,
                <GridActionsCellItem
                    icon={<CopyIcon />}
                    onClick={() => CopyMyrquest(params.row)}
                />,
            ],
        },
    ]);
    const EditMyRequestpage = (data) => {
        history.push
            ({
                pathname: "/fams/create-editmyrequest",
                state: data,
            });
    };
    const AddMyRequestpage = () => {
        history.push
            ({
                pathname: "/fams/create-myrequest",
                state: '',
            });
    };
    const openViewRequestDailog = (data) => {
        setSelectedObject(data);
        setOpenView(true);
    };
    const cancelMyrequest = (data) => {
        if (data.status !== 'PENDING' && data.status !== 'DRAFT') {
            switch (data.status) {
                case "APPROVED":
                    toast.error(t("MYREQ0010"));
                    break;
                case "CANCELLED":
                    toast.error(t("MYREQ0011"));
                    break;
                case "REJECTED":
                    toast.error(t("MYREQ0012"));
                    break;
                default:
                    break;
            }
        } else {
            cancelRecord(data);
        }
    };

    const CopyMyrquest = (data) => {
        history.push
            ({
                pathname: "/fams/create-myrequest",
                state: data,
            });
    };

    useEffect(() => {
        loadData(page, rowsPerPage);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.myRequest.myRequestsList)) {
            setPage(store.myRequest.myRequestsList.currentPage - 1);
            setData(store.myRequest.myRequestsList.requests);
            setTotalPages(store.myRequest.myRequestsList.totalPages);
        } else {
            setPage(0);
            setData([]);
        }

    }, [store.myRequest.myRequestsList]);

    const loadData = (page, rowsPerPage) => {
        dispatch(getMyRequestsList(page, rowsPerPage, ''));
    };

    const handleChange = (newPage, size) => {
        setRowsPerPage(size);
        setPage(newPage);
        loadData(newPage, size);
    };
    const cancelRecord = (data) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmModal
                        open={true}
                        title={t('MYREQ0013')}
                        text={t('MYREQ0007')}
                        onConfirm={() => {
                            setdisable(true);
                            dispatch(
                                cancelMyRequest(data.id,
                                    () => {
                                        toast.success(t('MYREQ0006'));
                                        loadData(0, rowsPerPage);
                                    },
                                    (data) => {
                                        setdisable(false)
                                        toast.error(data.message)
                                    }
                                ));
                            onClose();
                        }}
                        onClose={() => onClose()}
                    />
                );
            },
        });
    }

    return (
        <Grid>
            <Breadcrumb links={breadcrumb}>
                <HeaderToolbar src={AddIcon} onClick={AddMyRequestpage} tooltipTitle={t('COMMON009')} size='large' />
            </Breadcrumb>
            <DataTable
                columns={columns}
                rows={data}
                page={page}
                pageCount={totalPages}
                pageSize={rowsPerPage}
                handleChange={(newPage, size) => handleChange(newPage, size)}
            />

            {openView && (
                <ViewRequestDetails
                    myRequestPage={true}
                    open={openView}
                    selectedObject={selectedObject}
                    onClose={() => {
                        setSelectedObject();
                        setOpenView(false);
                        loadData(page, rowsPerPage);
                    }}
                />
            )}

        </Grid>
    )
}