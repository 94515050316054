import { Box } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumb(props) {
  const links = props.links.map((link, index) => {
    return (
      <Fragment key={index}>
        <Box className='breadcrumb_link' flexGrow={index !== props.links.length - 1 ? 0 : 1}>
          {index !== props.links.length - 1 && (
            <Link
              className='custom-link'
              to={{
                pathname: link.path,
              }}
            >
              {link.name}
            </Link>
          )}
          {index === props.links.length - 1 && <span class='is-active'>{link.name}</span>}
        </Box>
        {index !== props.links.length - 1 && (
          <Box className='breadcrumb_link'>
            <span style={{ lineHeight: '0.43', fontSize: '20px', color: '#858585' }}>&#8250;</span>
          </Box>
        )}
      </Fragment>
    );
  });
  return (
    <div style={{ width: '100%' }}>
      <Box display='flex' paddingLeft={1}>
        {links}
        {props.children}
      </Box>
    </div>
  );
}
