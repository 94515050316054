import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ViewIconA = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t('APPROVER0002')}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
 <svg xmlns="http://www.w3.org/2000/svg" width="27.015" height="17.936" viewBox="0 0 27.015 17.936">
 <g id="Group_7982" data-name="Group 7982" transform="translate(-1689.943 -245.032)" >
   <path id="Path_6643" data-name="Path 6643" d="M12.484,16.968a4.484,4.484,0,1,1,4.484-4.484A4.5,4.5,0,0,1,12.484,16.968Zm0-6.726a2.242,2.242,0,1,0,2.242,2.242A2.248,2.248,0,0,0,12.484,10.242Z" transform="translate(1690.966 241.516)" fill="#304669" opacity="1" />
   <path id="Path_6644" data-name="Path 6644" d="M13.457,21.935C5.05,21.935.342,13.864.118,13.528a1.018,1.018,0,0,1,0-1.121C.342,12.071,5.05,4,13.457,4S26.572,12.071,26.8,12.407a1.018,1.018,0,0,1,0,1.121C26.572,13.864,21.864,21.935,13.457,21.935ZM2.472,12.968c1.121,1.681,5.044,6.726,10.985,6.726s9.864-5.044,10.985-6.726C23.322,11.286,19.4,6.242,13.457,6.242S3.593,11.286,2.472,12.968Z" transform="translate(1689.993 241.032)" fill="#304669" opacity="1" />
 </g>
</svg>

          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="27.015" height="17.936" viewBox="0 0 27.015 17.936">
              <g id="Group_7982" data-name="Group 7982" transform="translate(-1689.943 -245.032)" >
                <path id="Path_6643" data-name="Path 6643" d="M12.484,16.968a4.484,4.484,0,1,1,4.484-4.484A4.5,4.5,0,0,1,12.484,16.968Zm0-6.726a2.242,2.242,0,1,0,2.242,2.242A2.248,2.248,0,0,0,12.484,10.242Z" transform="translate(1690.966 241.516)" fill="#304669" opacity="0.9" />
                <path id="Path_6644" data-name="Path 6644" d="M13.457,21.935C5.05,21.935.342,13.864.118,13.528a1.018,1.018,0,0,1,0-1.121C.342,12.071,5.05,4,13.457,4S26.572,12.071,26.8,12.407a1.018,1.018,0,0,1,0,1.121C26.572,13.864,21.864,21.935,13.457,21.935ZM2.472,12.968c1.121,1.681,5.044,6.726,10.985,6.726s9.864-5.044,10.985-6.726C23.322,11.286,19.4,6.242,13.457,6.242S3.593,11.286,2.472,12.968Z" transform="translate(1689.993 241.032)" fill="#304669" opacity="0.9" />
              </g>
            </svg>

          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default ViewIconA;
