import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

const SidebarHeader = props => {

  return (
    <Fragment>

    </Fragment>
  );
};
const mapStateToProps = state => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SidebarHeader);
