import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import back_button from '../../assets/images/onboard/back_button.svg';
import famsBackground from '../../assets/images/onboard/Group 7962.svg';
import HeaderI18n from '../../layout-components/HeaderI18n/I18n';
import './custom.css';
const useStyles = makeStyles((theme) => ({
  container: {
    height: '90%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  footer: {
    position: 'absolute',
    bottom: '12px',
    left: '35vh',
  },
  footers: {
    position: 'absolute',
    bottom: '12px',
  }
}));
function LoginLayout({ bgColor, color, width, height, lang, disableLeftpage, backbutton, fontFamily, login, ...props }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  function handleClick(e) {
    e.preventDefault();
    history.push('/fams/login');
    window.location.reload(false);
  }

  return (
    <Grid className='signin-container'>
      <Grid component='main' maxWidth='full' className={` ${classes.container}  background-image `} >
        <Grid container >
          {!disableLeftpage ?
            <Grid item sm={6} xs={12} className='paper_left1'>
              <img src={login} alt='logo' className='left_img' />
            </Grid> : <></>
          }
          {disableLeftpage ?
            <Grid item sm={12} xs={12} className='signin-container'>
              <Grid container direction='row'>
                <Grid item style={{ position: 'absolute', left: 10, top: '5%' }}>
                  <img src={famsBackground} alt='logo' style={{ width: '150px', marginLeft: '50px', }} />
                </Grid>
                <Grid sm={12} xs={12} container direction='row' justifyContent='center' alignItems='center'>
                  <Grid sm={6} xs={12} >
                    {props.children}
                  </Grid>
                </Grid>
                <Grid container className={classes.footers} sm={12} xs={12} display='flex' direction='row' justifyContent='center' alignItems='center'>
                  <Typography style={{ fontSize: '10px' }} color='textSecondary'>
                    Copyright © 2022 ZKTECO CO., LTD. All rights reserved
                  </Typography>
                </Grid>
              </Grid>
            </Grid> :
            <Grid item sm={6} xs={12} className='signIn-right'>
              <Grid container direction='row'>
                {backbutton ? (
                  <Grid item onClick={handleClick} style={{ top: '20px' }}>
                    <Tooltip title={t('SIGN001')} style={{ cursor: 'pointer' }} >
                      <img src={back_button} alt='logo' width={'36px'} />
                    </Tooltip>
                  </Grid>
                ) : (
                  <></>
                )}
                {lang ? (
                  <Grid item style={{ position: 'absolute', right: 12, top: '20px' }}>
                    <HeaderI18n />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              {props.children}
              <Grid className={classes.footer} >
                <Typography style={{ fontSize: '10px' }} color='textSecondary'>
                  Copyright © 2022 ZKTECO CO., LTD. All rights reserved
                </Typography>
              </Grid>
            </Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
}
LoginLayout.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
};
LoginLayout.defaultProps = {
  width: '100px',
};

export default LoginLayout;
