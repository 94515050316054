import { ListItem, makeStyles, MenuItem, Popover, Typography } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { IconFlagCN, IconFlagES, IconFlagUS } from 'material-ui-flags';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  icon_color: {
    color: '#36C96D',
  },
}));
export default function SelectLanguage(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState('en');
  useEffect(() => {
    const currentLanguage = localStorage.getItem('i18nextLng')
    setSelectedLang(currentLanguage)
  }, [])
  const handleCloseLangMenu = (event) => {
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
    } else {
      BrowserLanguage.setLanguage(event);
      i18n.changeLanguage(event);
      setSelectedLang(event);
    }
    setAnchorLanguage(null);
    window.location.reload(false);
  };

  return (
    <Fragment>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <ListItem variant='contained' {...bindTrigger(popupState)} style={{ cursor: 'pointer', marginRight: '20px', }}>
              {/* <LanguageIcon fontSize='medium' /> */}
              <img src={props.normalImage} alt={props.alt} className={classes.icon_color} sizes='small' />
              <Typography className='users-remark' style={{ color: '#304669BD' }}> {props.item}</Typography>
              {/* &nbsp;  <span style={{ textTransform: 'uppercase' }}>{' ' + localStorage.getItem('i18nextLng')}</span> */}
            </ListItem>
            <Popover  {...bindMenu(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                value={'en'}
                key={'en'}
                onClick={() => {
                  handleCloseLangMenu('en');
                }}
              >
                {' '}
                <IconFlagUS className='lang-flag' />
                &nbsp; {t('english')}
                &nbsp;
                {selectedLang === 'en' && <CheckIcon style={{ color: 'green' }} />}
              </MenuItem>
              <MenuItem
                value={'es'}
                key={'es'}
                onClick={() => {
                  handleCloseLangMenu('zh');
                }}
              >
                {' '}
                <IconFlagCN className='lang-flag' />
                &nbsp;  {t('chinese')}
                {selectedLang === 'zh' && <CheckIcon style={{ color: 'green' }} />}
              </MenuItem>
              <MenuItem
                value={'es'}
                key={'es'}
                onClick={() => {
                  handleCloseLangMenu('es');
                }}
              >
                {' '}
                <IconFlagES className='lang-flag' />
                &nbsp; {t('spanish')}
                {selectedLang === 'es' && <CheckIcon style={{ color: 'green' }} />}
              </MenuItem>

            </Popover >
          </React.Fragment>
        )}
      </PopupState>
    </Fragment>
  );
}
