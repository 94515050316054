import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import { COMPANY_BRANCH_LIST } from '../types';

const buildQuery = (filter) => {
    let query = '';
    if (filter.code) {
        query = `&code=${filter.code}`;
    }
    if (filter.name) {
        query = query + `&name=${filter.name}`;
    }
    if (filter.status) {
        query = query + `&status=${filter.status}`;
    }
    if (filter.country) {
        query = query + `&country=${filter.country}`;
    }

    if (filter.operator) {
        query = query + `&operator=${filter.operator}`;
    }
    if (filter.type) {
        query = query + `&type=${filter.type}`;
    }
    if (filter.partnerType) {
        query = query + `&partnerType=${filter.partnerType}`;
    }
    return query;
}


export const updateCompanyBranch = (id, data, onSuccess, onFail) => async (dispatch) => {
    const res = await axios.put("/company/" + id + "/profile", data);
    try {
        if (res.data.code === 'UASI0007') {
            onSuccess(res.data);
        } else {
            onFail(res.data);
        }
    } catch (error) {
    }
};
export const getCompaniesByPartnerTypeANDActive = (pageNumber, pageSize, filter) => async (dispatch) => {
    if (filter === undefined || filter === '') {
        filter = {
            code: '',
            name: '',
        }
    }
    try {
        const res = await axios.get('/company/user?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
        if (res.data.code === 'FHRMSI0001') {
            dispatch({
                type: COMPANY_BRANCH_LIST,
                payload: CommonUtil.isEmpty(res.data.data) ? [] : res.data.data,
            });
        } else {
            dispatch({
                type: COMPANY_BRANCH_LIST,
                payload: [],
            });
        }
    } catch (error) {
    }
};

