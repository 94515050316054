import { Avatar, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import FileBase64 from 'react-file-base64';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import cameraplus2 from '../../assets/images/AddPhotoIcon.svg';
// import {ReactComponent as cameraplus2} from '../../assets/AddPhotoIcon.svg'
import './phoneInput.css';

export default function ProfileUplodUser(props) {
    const { t } = useTranslation();
    const [profile, setProfile] = React.useState(cameraplus2);
    const [state, setState] = React.useState({
        profilePhoto: ''
    });
    const handleProfile = (file) => {
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/bmp') {
            let fileSize = file.size.split(' ')[0];
            let size = parseInt(fileSize);
            if (size <= 2000) {
                setState({
                    ...state,
                    profilePhoto: file.base64.split(',')[1],
                });
                props.onsetProfile(file.base64)
                setProfile(file.base64);
            } else {
                setProfile(state.profilePhoto);
                props.onsetProfile(state.profilePhoto)
                toast.error(t('REGISTRATIONFORM003'));
            }
        } else {
            setProfile(state.profilePhoto);
            props.onsetProfile("Error")
            toast.error(t('REGISTRATIONFORM004'));
        }
    };

    return (
        <>
            <Grid conatiner display={'flex'} direction='column' justifyContent='center'>

                <div style={{ borderStyle: props.border, borderRadius: '50%', width: '150px', color: '#D2D2D2' }}>
                    <Tooltip title={props.profilePhoto ? t('UPLOAD001') : ''}>
                        <IconButton
                            variant='contained'
                            component='label'
                            style={{
                                position: 'relative',
                                width: '100%', font: 'normal normal normal 18px/24px Roboto',
                                letterSpacing: '0px'
                            }}
                            disabled={props.disabled}
                        >

                            {props.profilePhoto ?
                                <Avatar
                                    className='ImageContent'
                                    alt='' src={props.profilePhoto}
                                    style={{ height: '130px', width: '130px', zIndex: '99', borderRadius: '50%' }}
                                /> :
                                <>
                                    <Avatar
                                        className='ImageContent'
                                        alt='' src={profile}
                                        style={{ height: '130px', width: '130px', borderRadius: '50%', backgroundColor: '#F4F6F8' }}
                                        sx={[
                                            () => ({
                                                "& .MuiAvatar-img": {
                                                    width: '49px',
                                                    height: '42px',
                                                    textAlign: 'center',
                                                    objectFit: 'cover',
                                                    color: 'transparent',
                                                    textIndent: '10000px'
                                                },
                                            }),
                                        ]}
                                    />
                                    <div style={{ position: 'absolute', top: '68%', left: '30%', zIndex: '1', color: '#C3C5C7', justifyContent: 'center' }}>Upload Photo </div>
                                    <Grid style={{ display: 'none' }}>
                                        <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                                    </Grid>
                                </>
                            }
                            <Grid style={{ display: 'none' }}>
                                <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                            </Grid>
                        </IconButton>
                    </Tooltip>
                </div>
                {props.disabled === false ?
                    <Grid direction='row' display='flex' alignItems='flex-end' style={{ color: '#C3C5C7', opacity: 1, fontSize: '12px', paddingTop: '34px', justifyContent: 'center' }}>
                        Allowed *.jpeg, *.jpg, *.png max size of 1 MB
                    </Grid> : ''}
            </Grid >
        </>
    );
}

ProfileUplodUser.defaultProps = {
    disabled: false,
}