import React from "react";
import { Tooltip, Box, Typography } from "@mui/material";

const HeaderToolbar = (props) => {
    return (
        <>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 0px",
                }}
            >
                <Box>
                    <Typography sx={{ fontWeight: 500 }}
                        variant="h5"
                        component="h2"
                        color="#3D4977"
                    >
                        {props.title}
                    </Typography>
                </Box>
                {props.src ?
                    <Box
                        style={{
                            textAlign: "-webkit-right",
                            borderRadius: "50%",
                        }}
                    >
                        <Tooltip title={props.tooltipTitle}>
                            <img
                                alt="..."
                                src={props.src}
                                onClick={props.onClick}
                                style={{ width: '60px', cursor: 'pointer', }}
                            />
                        </Tooltip>
                    </Box>
                    : <></>}
            </Box>
        </>
    );
};
export default HeaderToolbar;
