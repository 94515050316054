import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { REACT_URI_MY_REQUESTS, REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import projectLogo from '../../assets/images/Group 7962.svg';

const HeaderLogo = () => {
  const store = useSelector((state) => state.security);
  const url = 'EMPLOYEE' !== store.user.roleCode ? REACT_URL_DASHBOARD : REACT_URI_MY_REQUESTS;
  return (
    <Fragment>
      <span></span>
      <div className={clsx('app-header-logo', {})}>
        <Link to={url} className="header-logo-wrapper-link">
          <img className="header-logo" alt='Fhrms' src={projectLogo} />
        </Link>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
