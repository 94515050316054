import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { InputAdornment } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addApprovalCondition, getEmployeeListByUserId, updateApprovalCondition } from '../../../actions/approvalCondition/ApprovalConditionActions';
import { REACT_URI_APPROVAL_CONDITION } from '../../../actions/EndPoints';
import { getRequestTypeList } from '../../../actions/requestType/RequestTypeActions';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: "700px",
        [theme.breakpoints.down("lg")]: {
            height: "400px",
        },
    },
}));


export default function AddApprovalCondition(props) {
    const { t } = useTranslation();
    const store = useSelector((state) => state);
    const history = useHistory();
    const classes = useStyles();
    const [requestTypes, setRequestTypes] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const dispatch = useDispatch();
    const invalidChars = ['-', '+', 'e'];
    const [state, setState] = React.useState({
        id: '',
        employee: '',
        conditions: '',
        requestType: '',
    });

    useEffect(() => {
        if (!CommonUtil.isEmpty(props.history.location.state)) {
            setState({
                id: props.history.location.state ? props.history.location.state?.id : '',
                employee: props.history.location.state.employeeId ? props.history.location.state?.employeeId : '',
                conditions: props.history.location.state ? props.history.location.state?.condition : '',
                requestType: props.history.location.state.requestTypeId ? props.history.location.state?.requestTypeId : "",
            })
        }
    }, [props.history.location.state])

    const handleChange = (event) => {
        const name = event.target.name;
        if (name !== 'conditions') {
            setState({
                ...state,
                [name]: event.target.value,
            });
        }
        if (name === 'conditions' && event.target.value.length <= 10) {
            setState({
                ...state,
                [name]: event.target.value,
            });
        }
        setError({
            [name]: false,
        });
    };

    const [error, setError] = React.useState({
        requestType: false,
        employee: false,
        conditions: false,

    });

    const handleClose = () => {
        history.push(REACT_URI_APPROVAL_CONDITION)
    };
    const breadcrumb = [
        { path: REACT_URI_APPROVAL_CONDITION, name: t('BRANCHCONFIG0003') },
        { path: '/fams/create-approvalCondition', name: props.history.location.state?.id === undefined ? t("APPROVALCONDITION0003") : t("APPROVALCONDITION0004") },
    ];

    useEffect(() => {
        loadRequestTypes();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.requestType.requestTypeList)) {
            setRequestTypes(store.requestType.requestTypeList.request_types);
        } else {
            setRequestTypes([]);
        }

    }, [store.requestType.requestTypeList]);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.employeeV2.employeeUserIdList)) {
            setEmployees(store.employeeV2.employeeUserIdList.data);
        } else {
            setEmployees([]);
        }

    }, [store.employeeV2.employeeUserIdList]);

    useEffect(() => {
        loadEmployees();
        //eslint-disable-next-line
    }, []);

    const loadEmployees = () => {
        dispatch(getEmployeeListByUserId(1, 0, ''));
    }

    const loadRequestTypes = () => {
        dispatch(getRequestTypeList(1, 0, ''));
    }

    const handleChangeEmp = (value) => {
        setState({
            ...state,
            employee: value,
        })
    }

    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(state.employee.id)) {
            setError({ employee: true })
            toast.error(t("EMP019"))
        } else if (CommonUtil.isEmptyString(state.requestType.id)) {
            setError({ requestType: true })
            toast.error(t("BRANCHCONFIG0006"))
        } else if (CommonUtil.isEmptyString(state.conditions)) {
            setError({ conditions: true })
            toast.error(t("APPROVALCONDITION0005"))
        } else if (!CommonUtil.isValidNumeric(state.conditions)) {
            setError({ conditions: true })
            toast.error(t("APPROVALCONDITION0009"))
        } else {
            const update = {
                requestTypeId: state.requestType.id,
                employeeId: state.employee.id,
                condition: state.conditions,
                userId: state.employee.userId,
            }

            if (!CommonUtil.isEmptyString(state.id)) {
                dispatch(updateApprovalCondition(state.id, update,
                    () => {
                        handleClose();
                        toast.success(t('APPROVALCONDITION0006'));
                    }, (error) => {
                        switch (error.code) {
                            case 'FHRMSE0042':
                                setError({ code: true });
                                break;
                            case 'FHRMSE0043':
                                setError({ name: true });
                                break;
                            case 'FHRMSE0074':
                            case 'FHRMSE0100':
                            case 'FHRMSE0103':
                                setError({ conditions: true });
                                break;
                            default:
                                setError({ ...error });
                        }
                        toast.error(error.message);
                    }
                ));

            } else {
                const update = {
                    requestTypeId: state.requestType.id,
                    employeeId: state.employee.id,
                    condition: state.conditions,
                    userId: state.employee.userId
                }
                dispatch(addApprovalCondition(update,
                    () => {
                        handleClose();
                        toast.success(t('APPROVALCONDITION0001'));
                    },
                    (error) => {
                        onFail(error);
                    }
                ));

            }
        }
    }

    const onFail = (data) => {
        if (data) {
            //eslint-disable-next-line
            switch (data.code) {
                case 'FHRMSE0074':
                case 'FHRMSE0103':
                    setError({ conditions: true });
                    break;
                case "FHRMSE0042":
                    setError({ code: true });
                    break;
                case "FHRMSE0043":
                    setError({ name: true });
                    break;
                default:
                    setError({ ...error });
            }
            toast.error(data.message);
        }
    }

    return (
        <div>

            <Grid  >
                <Breadcrumb links={breadcrumb} />
                <Grid fullWidth className={`${classes.customContainer} background-size`}>
                    <Grid container sx={12} xs={12} alignItems="center">
                        <Grid item xs={6} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <Autocomplete
                                    style={{ width: '100%' }}
                                    id='company_code'
                                    openText={t('COMMON169')}
                                    closeText={t('COMMON170')}
                                    clearText={t('COMMON215')}
                                    noOptionsText={t('COMMON214')}
                                    name='employee'
                                    value={state.employee}
                                    options={employees}
                                    getOptionLabel={(option) => `${option.firstName ? option.firstName : ''}${option.lastName ? ' ' + option.lastName : ''}`}
                                    onChange={(event, newValue) => {
                                        if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                                            handleChangeEmp(newValue);
                                            setError(false);
                                        } else {
                                            setState({ ...state, employee: '' });
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} name='employee' value={state.employee} error={error.employee} variant='outlined' fullWidth label={t('APPROVER0001') + '*'} />
                                    )}
                                    renderOption={(option) => {
                                        return <span style={{ fontWeight: 'bold' }}>{option.firstName} {option.lastName ? option.lastName : ''}<br /><span style={{ fontSize: '10px', fontWeight: 'normal' }} >{`${option.email}`}</span></span>;
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <Autocomplete
                                    openText={t('COMMON169')}
                                    closeText={t('COMMON170')}
                                    clearText={t('COMMON215')}
                                    noOptionsText={t('COMMON214')}
                                    style={{ width: '100%' }}
                                    options={requestTypes}
                                    name={'requestType'}
                                    value={state.requestType}
                                    disabled={!CommonUtil.isEmptyString(state.id)}
                                    getOptionLabel={(reqType) => reqType.name}
                                    onChange={(event, newValue) => {
                                        if (!CommonUtil.isEmpty(newValue)) {
                                            if (newValue.name.toUpperCase().includes('HR')) {
                                                setState({ ...state, requestType: newValue, conditions: 0 });
                                                setError({ requestType: false })
                                            } else {
                                                setState({ ...state, requestType: newValue, conditions: '' });
                                                setError({ requestType: false })
                                            }
                                        } else {
                                            setState({ ...state, requestType: '' });
                                            setError({ requestType: false })
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} value={state.requestType} name={'requestType'} error={error.requestType} variant='outlined' size="medium" fullWidth label={t('BRANCHCONFIG0004') + '*'} />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} >
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <TextField
                                fullWidth
                                error={error.conditions}
                                name="conditions"
                                label={t("APPRVCONDTIONS0001") + '*'}
                                helperText=""
                                variant="outlined"
                                size="medium"
                                type="number"
                                value={state.conditions}
                                disabled={state.conditions === 0}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (invalidChars.includes(e.key) || 'ArrowUp' === e.key || 'ArrowDown' === e.key) {
                                        e.preventDefault();
                                    }
                                }}
                                onWheel={e => {
                                    e.target.blur();
                                }}
                                inputProps={{ maxLength: 50 }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">
                        <Box display="flex" m={1} p={1}>
                            <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
                                {t("COMMON008")}
                            </CancelButtons>
                            <SubmitButtons onClick={handleSubmit} color="primary" variant="contained" className='m-2'>
                                {t("COMMON035")}
                            </SubmitButtons>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
