import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

import React, { useEffect } from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStatusCounts } from "../../actions/myRequests/MyRequestsActions";
const useStyles = makeStyles((theme) => ({
    custompagination: {
        width: "100%",
        height: "800px",
        backgroundColor: 'white',
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            height: "448px",
            backgroundColor: 'white',
        },
    },
    circles: {
        width: "100%",
        height: "750px",
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            height: "400px",
            display: 'flex',
            alignItems: 'center',
        },
    }
}));

export default function Pichart() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const store = useSelector((state) => state);
    const [counts, setCounts] = React.useState({});
    const data = ([
        ["Task", "Request"],
        [t('DASHBOARD0005'), counts.cancelled],
        [t('DASHBOARD0003'), counts.rejected],
        [t("DASHBOARD0001"), counts.pending],
        [t('DASHBOARD0002'), counts.approved],
    ])
    const options = {
        title: t('MYREQ0001'),
    };
    useEffect(() => {
        dispatch(getStatusCounts());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.myRequest.requestsStatusCountsList && Object.keys(store.myRequest.requestsStatusCountsList).length !== 0) {
            setCounts(store.myRequest.requestsStatusCountsList);
        }
        //eslint-disable-next-line
    }, [store.myRequest.requestsStatusCountsList && Object.keys(store.myRequest.requestsStatusCountsList).length !== 0])
    return (
        <>
            {(counts.cancelled === null && counts.rejected === null && counts.pending === null && counts.approved === null)||(counts.cancelled === 0 && counts.rejected === 0 && counts.pending === 0 && counts.approved === 0) ?
                <Grid bgcolor='white' className={classes.custompagination}>
                    <div style={{ height: '20px', width: '100%', marginLeft: '20px', paddingTop: '20px' }}><Typography
                    >{t('MYREQ0001')}</Typography></div>
                    <Box justifyContent='center' className={classes.circles}>
                        <div style={{ height: '225px', width: '225px', borderRadius: '50%', backgroundColor: '#F4F6F8' }}><Typography style={{
                            height: '220px',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '100px'
                        }}>{t('NOD001')}</Typography></div>
                    </Box>
                </Grid>
                : <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    className={classes.custompagination}
                />}
        </>
    );
}
