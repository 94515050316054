import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URL_BRANCH } from '../../../actions/EndPoints';
import { changeOwner } from '../../../actions/organization/BranchApiCalls';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: "700px",
        [theme.breakpoints.down("lg")]: {
            height: "400px",
        },
    },
}));
export default function OwnerChange(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory()
    const classes = useStyles();
    const [state, setState] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const [error, setError] = React.useState({
        firstName: false,
        lastName: false,
        email: false,

    });

    const handleClose = () => {
        history.push(REACT_URL_BRANCH);
    };
    const breadcrumb = [
        { path: REACT_URL_BRANCH, name: t('BRANCHCONFIG0007') },
        { path: '/fams/create-OwnerChangebranch', name: t('Branch0013') },
    ];
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });

        setError({
            [name]: false,
        });
    };
    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(state.firstName)) {
            setError({ firstName: true })
            toast.error(t("REQUESTTYPES0009"))
        } else if (!CommonUtil.validateName(state.firstName)) {
            setError({ firstName: true })
            toast.error(t("BRANCH0001"))
        } else if (!CommonUtil.isEmptyString(state.lastName) && !CommonUtil.validateName(state.lastName)) {
            setError({ lastName: true })
            toast.error(t("lastnameValidation"))
        } else if (CommonUtil.isEmptyString(state.email)) {
            setError({ email: true })
            toast.error(t("REQUESTTYPES0013"))
        } else if (!CommonUtil.isValidEmail(state.email)) {
            setError({ email: true })
            toast.error(t("COMMON155"))
        } else {
            const update = {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                companyId: props.history.location.state.companyId,
                roleId: props.history.location.state.roleId,
                code: props.history.location.state.code,
                name: props.history.location.state.name,
            }
            dispatch(changeOwner(update, props.history.location.state.userId,
                (resp) => {
                    handleClose();
                    toast.success(resp.message);
                },
                (error) => {
                    onFail(error);
                }
            ));
        }
    }

    const onFail = (data) => {
        if (data) {
            if (data.code === 'FHRMSE0105') {
                setError({ email: true })
            }
            toast.error(data.message);
        }
    }

    return (
        <>
            <Grid  >
                <Breadcrumb links={breadcrumb} />
                <Grid fullWidth className={`${classes.customContainer} background-size`}>
                    <Grid item xs={12} sm={12} >
                        <Typography variant="h1" style={{ color: '#304669', fontSize: '15px' }} >{t("COMMON092")}</Typography>
                    </Grid>
                    <Grid container sx={12} xs={12} alignItems="center">
                        <Grid item xs={12} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.firstName}
                                    name="firstName"
                                    label={t("PERSON003") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.firstName}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.lastName}
                                    name="lastName"
                                    label={t("PERSON004")}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.lastName}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 25 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <TextField
                                fullWidth
                                error={error.email}
                                name="email"
                                label={t("PERSON005") + '*'}
                                helperText=""
                                variant="outlined"
                                size="medium"
                                value={state.email}
                                onChange={handleChange}
                                inputProps={{ maxLength: 36 }}
                            />
                        </Box>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Box display="flex" m={1} p={1}>
                            <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
                                {t("COMMON008")}
                            </CancelButtons>
                            <SubmitButtons onClick={handleSubmit} color="primary" variant="contained" className='m-2'>
                                {t("COMMON035")}
                            </SubmitButtons>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}