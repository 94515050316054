import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { REACT_URI_TEMPLATE_ITEMS } from '../../../actions/EndPoints';
import { addTemplateItem, updateTemplateItem } from '../../../actions/templateItems/TemplateItemsActions';
import { getTemplatesList } from '../../../actions/templates/TemplatesActions';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: "700px",
        [theme.breakpoints.down("lg")]: {
            height: "400px",
        },
    },
}));

export default function AddTemplateItem(props) {
    const { t } = useTranslation();
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [templates, setTemplates] = useState([]);
    const [role, setRole] = useState([]);
    const [state, setState] = React.useState({
        id: props.history.location.state ? props.history.location.state?.id : '',
        code: props.history.location.state ? props.history.location.state?.code : "",
        name: props.history.location.state ? props.history.location.state?.name : "",
        hint: props.history.location.state ? props.history.location.state?.hint : "",
        value_type: props.history.location.state ? props.history.location.state?.valueType : "",
        template_id: props.history.location.state.templateId ? props.history.location.state?.templateId : "",
    });

    const [error, setError] = React.useState({
        code: false,
        name: false,
        hint: false,
        value_type: false,
        template_id: false,

    });

    const handleClose = () => {
        history.push(REACT_URI_TEMPLATE_ITEMS)
    };

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };

    useEffect(() => {
        loadTemplates();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.template.templateList)) {
            setTemplates(store.template.templateList.templates);

        } else {
            setTemplates([]);
        }

    }, [store.template.templateList]);

    const loadTemplates = () => {
        dispatch(getTemplatesList(1, 0, ''));
    }

    useEffect(() => {
        setRole((store.security.user));
    }, [store.security.user]);

    const breadcrumb = [
        { path: REACT_URI_TEMPLATE_ITEMS, name: t('TEMPLATEITEMS0001') },
        { path: '/fams/create-templeteitems', name: props.history.location.state?.id === undefined ? t("TEMPLATEITEMS00015") : t("TEMPLATEITEMS0009") },
    ];
    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(state.template_id)) {
            setError({ template_id: true })
            toast.error(t("TEMPLATEITEMS0006"))
        } else if (CommonUtil.isEmptyString(state.code)) {
            setError({ code: true })
            toast.error(t("REQUESTTYPES0003"))
        } else if (!CommonUtil.validateCode(state.code)) {
            setError({ code: true })
            toast.error(t("codeValidation"))
        } else if (CommonUtil.isEmptyString(state.name)) {
            setError({ name: true })
            toast.error(t("REQUESTTYPES0004"))
        } else if (!CommonUtil.validateName(state.name)) {
            setError({ name: true })
            toast.error(t("nameValidation"))
        } else if (CommonUtil.isEmptyString(state.value_type)) {
            setError({ value_type: true })
            toast.error(t("TEMPLATEITEMS0005"))
        } else {
            const update = {
                code: state.code,
                name: state.name,
                hint: state.hint,
                valueType: state.value_type,
                templateId: state.template_id.id,
            }
            if (!CommonUtil.isEmptyString(state.id)) {
                dispatch(updateTemplateItem(state.id, update,
                    () => {
                        handleClose();
                        toast.success(t('TEMPLATEITEMS0007'));
                    }, (error) => {
                        onFail(error);
                    }
                ));
            } else {
                dispatch(addTemplateItem(update,
                    () => {
                        handleClose();
                        toast.success(t('TEMPLATEITEMS0008'));
                    },
                    (error) => {
                        onFail(error);
                    }
                ));
            }
        }
    }

    const onFail = (data) => {
        if (data) {
            switch (data.code) {
                case "FHRMSE0042":
                    setError({ code: true });
                    break;
                case "FHRMSE0043":
                    setError({ name: true });
                    break;
                case "FHRMSE0051":
                    setError({ value_type: true });
                    break;
                default:
                    setError({ ...error });
            }
            toast.error(data.message);
        }
    }
    return (
        <>
            <Grid   >
                <Breadcrumb links={breadcrumb} />
                <Grid fullWidth className={`${classes.customContainer} background-size`}>
                    <Grid container sx={12} xs={12} alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6} sm={6}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <Autocomplete
                                    style={{ width: '100%' }}
                                    openText={t('COMMON169')}
                                    closeText={t('COMMON170')}
                                    clearText={t('COMMON215')}
                                    noOptionsText={t('COMMON214')}
                                    options={templates.filter(person => person.status === 'ACTIVE' && person.companyId === role.companyId)}
                                    name={state.template_id}
                                    value={state.template_id}
                                    disabled={props.history.location.state.templateId ? true : false}
                                    getOptionLabel={(templete) => (templete.name ? templete.name : '')}
                                    onChange={(event, newValue) => {
                                        if (!CommonUtil.isEmpty(newValue)) {
                                            setState({ ...state, template_id: newValue });
                                        } else {
                                            setState({ ...state, template_id: '' });
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} value={state.template_id} name={state.template_id} error={error.template_id} variant='outlined' size="medium" fullWidth label={t('TEMPLATEITEMS0004') + '*'} />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.code}
                                    name="code"
                                    label={t("COMMON045") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.code}
                                    onChange={handleChange}
                                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 30 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={12} xs={12} alignItems="center">
                        <Grid item xs={6} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.name}
                                    name="name"
                                    label={t("COMMON044") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.name}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <FormControl variant='outlined' size='medium' fullWidth>
                                    <InputLabel>{t('TEMPLATEITEMS0003') + '*'}</InputLabel>
                                    <Select
                                        error={error.value_type}
                                        label={t('TEMPLATEITEMS0003') + '*'}
                                        name='value_type'
                                        onChange={handleChange}
                                        value={state.value_type}
                                    >
                                        <MenuItem value='' selected>{t('NAN001')}</MenuItem>
                                        <MenuItem value='CURRENCY'>CURRENCY</MenuItem>
                                        <MenuItem value='TEXT'>TEXT</MenuItem>
                                        <MenuItem value='INTEGER'>INTEGER</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                maxRows={2}
                                variant="outlined"
                                error={error.hint}
                                name="hint"
                                label={t("TEMPLATEITEMS0002")}
                                helperText=""
                                size="medium"
                                value={state.hint}
                                onChange={handleChange}
                                inputProps={{ maxLength: 250 }}
                            />
                        </Box>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Box display="flex" m={1} p={1}>
                            <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
                                {t("COMMON008")}
                            </CancelButtons>
                            <SubmitButtons onClick={handleSubmit} color="primary" variant="contained" className='m-2'>
                                {t("COMMON035")}
                            </SubmitButtons>
                        </Box>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}
