import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { REACT_URI_PERSON } from '../../../actions/EndPoints';
import { getDepartments } from '../../../actions/organization/DepartmentApiCalls';
import { getDesignations } from '../../../actions/organization/DesignationApiCalls';
import { addPerson, updatePerson } from '../../../actions/organization/PersonApiCalls';
import defaultProfile from '../../../assets/images/defaultProfile.png';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
import ProfileUplod from '../../profile/UploadImg';
import PersonMobileInput from './PersonMobileInput';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuPropss = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 2,
    },
  },
};

export default function AddPerson(props) {
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [departments, setDepartments] = React.useState([]);
  const [designations, setDesignations] = React.useState([]);
  const [error, setError] = React.useState({});
  let history = useHistory();

  const [state, setState] = React.useState({
    id: props.history.location.state ? props.history.location.state?.id : '',
    firstName: props.history.location.state.firstName,
    lastName: props.history.location.state ? props.history.location.state.lastName : '',
    email: props.history.location.state ? props.history.location.state.email : '',
    phone: props.history.location.state ? props.history.location.state.phone : '',
    employeeCode: props.history.location.state ? props.history.location.state.employeeCode : '',
    joinDate: props.history.location.state ? props.history.location.state.joinDate : '',
    gender: props.history.location.state ? props.history.location.state.gender : '',
    departmentId: props.history.location.state ? props.history.location.state.departmentId : '',
    designationId: props.history.location.state ? props.history.location.state.designationId : '',
    profilePhoto: props.history.location.state ? props.history.location.state.profilePhoto : '',
    userId: props.history.location.state ? props.history.location.state.userId : ''
  });

  const [profile, setProfile] = useState(defaultProfile);

  const handleClose = () => {
    history.push(REACT_URI_PERSON);
  };

  const breadcrumb = [
    { path: REACT_URI_PERSON, name: t('PERSON001') },
    { path: '/fams/Addperson', name: props.history.location.state.id === undefined ? t("PERSON002") : t('PERSON046') },
  ];
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };
  const handleProfilePicture = (data) => {
    if (data === "Error") {
      setProfile('')
      return;
    }
    setProfile(data);
    setState({
      ...state,
      profilePhoto: data.split(",")[1],
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON016'));
    }
    else if (!CommonUtil.isValidNumericName(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON017'));
    }
    else if (!CommonUtil.isValidNumericName(state.lastName)) {
      setError({ lastName: true });
      toast.error(t('PERSON018'));
      return false;
    }
    else if (CommonUtil.isEmptyString(state.email)) {
      setError({ email: true });
      toast.error(t('COMMON168'));
    }
    else if (!CommonUtil.isValidEmail(state.email)) {
      setError({ email: true });
      toast.error(t('PERSON022'));
      return false;
    }
    else if (!CommonUtil.isValidMobile(state.phone)) {
      setError({ phone: true });
      toast.error(t('PERSON026'));
    }
    else if (CommonUtil.isEmptyString(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON019'));
    }
    else if (!CommonUtil.isValidCode(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON020'));
    }
    else if (CommonUtil.isEmptyString(state.gender)) {
      setError({ gender: true });
      toast.error(t('PERSON025'));
    } else if (!CommonUtil.isEmptyString(state.phone) && CommonUtil.isEmptyString(countryCode)) {
      toast.error(t('COMMON193'));
      return;
    }
    else {
      let finalcountryCode = CommonUtil.isEmptyString(countryCode) ? CommonUtil.getDefaultCallingCountryCode() : countryCode;
      let personObj = {};
      personObj.firstName = state.firstName;
      personObj.lastName = state.lastName;
      personObj.email = state.email;
      personObj.userId = state.userId;
      personObj.phone = CommonUtil.isEmptyString(state.phone) ? '' : '+' + finalcountryCode.replace('+', '') + '-' + state.phone;
      personObj.employeeCode = state.employeeCode;
      personObj.joinDate = state.joinDate;
      personObj.gender = state.gender;
      personObj.departmentId = state.departmentId;
      personObj.designationId = state.designationId;
      personObj.profilePhoto = state.profilePhoto;
      personObj.createUser = false;
      if (state.id) {
        // personObj.id = state.id;
        dispatch(updatePerson(state.id, personObj, (data) => { handleError(data) }))
      } else {
        const personList = store.person.personList.employee;
        const obj = personList?.find(o => o.employeeCode === state.employeeCode);
        obj ? toast.error(t("PERSONER001")) : dispatch(addPerson(personObj, (data) => { handleError(data) }));
      }
    }
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'OMSI0006':
        history.push(REACT_URI_PERSON);
        break;
      case 'OMSE0097':
        setError({ employeeCode: true });
        break;
      case 'OMSE0213':
        setError({ phone: true });
        break;
      case 'OMSE0214':
        setError({ phone: true });
        break;
      case 'OMSE0160':
      case 'OMSE0184':
      case 'FHRMSE0112':
        setError({ email: true });
        break;
      default:
        break;
    }
    if (data.code === 'OMSI0006' || data.code === 'OMSI0007') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    dispatch(
      getDepartments(1, 0, (data) => {
        setDepartments(data.department);
      })
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      getDesignations(1, 0, (data) => {
        setDesignations(data.designation);
      })
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(props.history.location.state)) {
      if (!CommonUtil.isEmptyString(props.history.location.state.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + props.history.location.state.profilePhoto);
      }
    }
  }, [props.history.location.state]);
  useEffect(() => {
    if (!CommonUtil.isEmptyString(state.phone)) {
      if (state.phone.includes('-')) {
        setCountryCode(state.phone.split('-')[0]);
        setState({ ...state, phone: state.phone.split('-')[1] });
      }
    }
    //eslint-disable-next-line
  }, [state.phone]);
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

        }}
      >
        <Box>
          <Typography
            gutterBottom
            variant="h3"
            component="h2"
            color="secondary"
            style={{ paddingBottom: '15px' }}
          >
            <Breadcrumb links={breadcrumb} />
          </Typography>
        </Box>
      </Box>
      <Paper className='css-pepar-in-userprofile'>
        <Grid container p={5} spacing={3}>
          <Grid container justifyContent="center" alignItems='center' md={4} sm={12}>
            <Grid xl={6} lg={10} md={8} sm={4}>
              <ProfileUplod profilePhoto={profile} onsetProfile={(data) => handleProfilePicture(data)} border='dashed' heightimage='225px' />
            </Grid>
          </Grid>
          <Grid container md={8} sm={12} spacing={1}>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  fullWidth
                  required
                  error={error.firstName}
                  name='firstName'
                  label={t('PERSON003')}
                  variant='outlined'
                  size='medium'
                  defaultValue={state.firstName}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  fullWidth
                  error={error.lastName}
                  name='lastName'
                  label={t('PERSON004')}
                  variant='outlined'
                  size='medium'
                  defaultValue={state.lastName}
                  onChange={handleChange}
                  inputProps={{ maxLength: 25 }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField fullWidth error={error.email} name='email' label={t('PERSON005') + '*'} variant='outlined' size='medium' defaultValue={state.email} onChange={handleChange} inputProps={{ maxLength: 36 }} />
              </div>
            </Grid>
            <PersonMobileInput
              error={error.phone}
              countryCode={countryCode}
              phone={state.phone}
              editMode={true}
              onMobileNoChange={(phone) => {
                setError({ phone: false });
                setState({ ...state, phone: phone });
              }}
              onCounryCodeChange={(code) => setCountryCode(code)}
            />
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <TextField
                  fullWidth
                  required
                  error={error.employeeCode}
                  name='employeeCode'
                  label={t('PERSON008')}
                  variant='outlined'
                  size='medium'
                  defaultValue={state.employeeCode}
                  onChange={handleChange}
                  inputProps={{ maxLength: 20 }}
                />
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <FormControl variant='outlined' size='medium' fullWidth>
                  <InputLabel>{t('PERSON007') + ' *'}</InputLabel>
                  <Select
                    fullWidth
                    error={error.gender}
                    name='gender'
                    label={t('PERSON007') + ' *'}
                    variant='outlined'
                    size='medium'
                    value={state.gender}
                    defaultValue={state.gender}
                    onChange={handleChange}
                  >
                    <MenuItem value={'M'}>{"Male"}</MenuItem>
                    <MenuItem value={'F'}>{"Female"}</MenuItem>
                    <MenuItem value={'O'}>{"Others"}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <FormControl variant='outlined' size='medium' fullWidth>
                  <InputLabel>{t('PERSON010')}</InputLabel>
                  <Select
                    fullWidth
                    error={error.departmentId}
                    name='departmentId'
                    label={t('PERSON010')}
                    variant='outlined'
                    size='medium'
                    value={state.departmentId}
                    defaultValue={state.departmentId}
                    onChange={handleChange}
                    MenuProps={MenuPropss}
                  >
                    <MenuItem value='' selected>
                      <em>{t('NAN001')}</em>
                    </MenuItem>
                    {departments ? (
                      departments.map((data, index) => (
                        <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                          <Typography variant='inherit' noWrap>
                            {data.name}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <div className='p-3'>
                <FormControl variant='outlined' size='medium' fullWidth>
                  <InputLabel>{t('PERSON011')}</InputLabel>
                  <Select
                    fullWidth
                    error={error.designationId}
                    name='designationId'
                    label={t('PERSON011')}
                    variant='outlined'
                    size='medium'
                    value={state.designationId}
                    defaultValue={state.designationId}
                    onChange={handleChange}
                    MenuProps={MenuPropss}
                  >
                    <MenuItem value='' selected>
                      <em>{t('NAN001')}</em>
                    </MenuItem>
                    {designations ? (
                      designations.map((data, index) => (
                        <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                          <Typography variant='inherit' noWrap>
                            {data.name}
                          </Typography>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Box display="flex" m={1} p={1}>
            <CancelButtons onClick={handleClose} color='primary' variant='outlined' className='m-3'>
              {t('COMMON008')}
            </CancelButtons>
            <SubmitButtons onClick={handleSubmit} color='primary' variant='contained' className='m-3'>
              {t('COMMON035')}
            </SubmitButtons>
          </Box>
        </Grid>
      </Paper>
    </>
  );
}
