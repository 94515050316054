import axios from 'axios';
import { toast } from 'react-toastify';
import { JAVA_API_APPROVAL_WORKFLOW, JAVA_API_APPROVAL_WORKFLOW_LIST } from '../BackendEndPoints';
import { APPROVAL_WORKFLOW_LIST } from '../types';



export const addApprovalWorkflow = (data, onSuccess, onFail) => async (dispatch) => {
    const res = await axios.post(JAVA_API_APPROVAL_WORKFLOW, data);
    try {
        if (res.data.code === 'FHRMSI0001') {
            onSuccess(res.data);
        } else {
            onFail(res.data);
        }
    } catch (error) {
    }
};

export const getApprovalWorkflowList = (pageNumber, pageSize, filter) => async (dispatch) => {
    if (filter === undefined || filter ===  '') {
        filter = {
            code: '',
            name: ''
        }
    }

    const res = await axios.get(JAVA_API_APPROVAL_WORKFLOW_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&name=' + filter.name + '&code=' + filter.code);
    if (res.data.code === 'FHRMSI0001') {
        dispatch({
            type: APPROVAL_WORKFLOW_LIST,
            payload: res.data.data,
        });
    } else {
        dispatch({
            type: APPROVAL_WORKFLOW_LIST,
            payload: [],
        });
    }
};


export const updateApprovalWorkflow = (id, data, onSuccess, onFail) => async (dispatch) => {
    const res = await axios.put(JAVA_API_APPROVAL_WORKFLOW + '/' + id, data);
    try {
        if (res.data.code === 'FHRMSI0001') {
            onSuccess();
        } else {
            onFail(res.data);
        }
    } catch (error) {
        toast.error();
    }
};

export const deleteApprovalWorkflow = (id, onSuccess, onFail) => async (dispatch) => {
    const res = await axios.delete(JAVA_API_APPROVAL_WORKFLOW + '/' + id);
    try {
        if (res.data.code === 'FHRMSI0001') {
            onSuccess();
        } else {
            onFail(res.data);
        }
    } catch (error) {
        toast.error();
    }
};
