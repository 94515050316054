import { Box, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteApprovalCondition, getApprovalConditionList } from "../../../actions/approvalCondition/ApprovalConditionActions";
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import HeaderToolbar from "../../../components/HeaderTooltip";
import CommonUtil from "../../../components/Util/CommonUtils";
import DeleteIcon from "../../reusableIcons/DeleteIcon";
import EditIcon from "../../reusableIcons/EditIcon";
import ConfirmModal from "../../../components/ConfirmModalNew";
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import FilterButton from "../../../components/FilterButtom";
import DataTable from "../../../components/DataTableNew";
export default function ApprovalCondition() {
  const { t } = useTranslation();
  const history = useHistory();
  const store = useSelector((state) => state);
  const breadcrumb = [{ path: '', name: t('BRANCHCONFIG0003') }];
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editMode, setEditMode] = useState(false);
  const [clraefilterButton, setClraefilterButton] = useState(false);
  const [filter, setFilter] = useState({
    requestTypeName: '',
    employeeEmail: '',
  });
  //eslint-disable-next-line
  const columns = React.useMemo(() => [

    {
      field: "employeeId.email",
      headerName: t('EMP001'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.employeeId.firstName + '\n' + params.row.employeeId.email,
      renderCell: (params) => (
        <Tooltip title={params.row.employeeId.firstName + '\n' + params.row.employeeId.email} >
          <div >
            <Typography className="table-cell-trucate" style={{ display: 'flex', margin: '0px 0px', padding: '0px 0px', fontWeight: 'bold' }}>{params.row.employeeId.firstName}</Typography>
            <Typography className="table-cell-trucate" style={{ fontSize: '12px' }}>{params.row.employeeId.email}</Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'condition',
      headerName: t('BRANCHCONFIG0003'),
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip title={params.row.condition} >
          <span className="table-cell-trucate">{params.row.condition}</span>
        </Tooltip>
      ),
    },
    {
      field: 'requestTypeId.name',
      headerName: t('BRANCHCONFIG0015'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.requestTypeId.name,
      renderCell: (params) => (
        <Tooltip title={params.row.requestTypeId.name} >
          <span className="table-cell-trucate">{params.row.requestTypeId.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => EditApproverConditionPage(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => deleteRecord(params.row)}
        />,
      ],
    },
  ]);
  const CreateApprovalCondition = () => {
    history.push
      ({
        pathname: '/fams/create-approvalCondition',
        state: data,
      });
  };
  const EditApproverConditionPage = (data) => {
    history.push
      ({
        pathname: '/fams/create-approvalCondition',
        state: data,
      });
  };

  useEffect(() => {
    loadData(page, rowsPerPage, filter);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.approvalCondition.approvalConditionList)) {
      setPage(store.approvalCondition.approvalConditionList.currentPage - 1);
      setData(store.approvalCondition.approvalConditionList.approval_conditions);
      setTotalPages(store.approvalCondition.approvalConditionList.totalPages)
    } else {
      setPage(0);
      setData([]);
    }

  }, [store.approvalCondition.approvalConditionList]);

  const loadData = (page, rowsPerPage, filter) => {
    dispatch(getApprovalConditionList(page, rowsPerPage, filter));
  };

  const handleChangePage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size, filter);
  }

  const deleteRecord = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            title={t('COMMON004')}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteApprovalCondition(data.id,
                  (data) => {
                    toast.success(t('APPROVALCONDITION0002'));
                    loadData(0, rowsPerPage, filter);
                  },
                  (data) => {
                    toast.error(data.message)
                  }
                ));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({
      ...filter,
      [name]: event.target.value,
    });
  };
  const handleCancel = () => {
    setFilter({
      requestTypeName: '',
      employeeEmail: '',
    });
    setEditMode(!editMode);
  };

  const clearFilter = () => {
    setFilter({
      requestTypeName: '',
      employeeEmail: '',
    });
    setClraefilterButton(false);
    loadData(1, rowsPerPage, '');

  }
  const handleClose = () => {
    setEditMode(false)
  };
  const reset = () => {
    if (filter.requestTypeName.trim() === '' && filter.employeeEmail.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setFilter({
      requestTypeName: '',
      employeeEmail: '',
    });
  };
  const handlefilter = () => {
    if (filter.requestTypeName.trim() === '' && filter.employeeEmail.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    loadData(1, rowsPerPage, filter);
    setClraefilterButton(true)
    handleClose();
  };
  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Box p={1} alignItems="center" style={{ alignSelf: 'center' }}>
          {clraefilterButton === false ?
            <div >
              <Tooltip arrow title={t('COMMON006')}>
                <IconButton size='small' variant='contained' color='secondary' onClick={() => handleCancel()}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div> : ''}
        </Box>
        <HeaderToolbar src={AddIcon} onClick={CreateApprovalCondition} tooltipTitle={t('COMMON009')} size='large' />
      </Breadcrumb>
      {editMode ? <>
        <Box style={{ boxShadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%)', backgroundColor: '#FFFFFF', marginBottom: '13px' }} display='flex'>
          <Grid container xs={12} sm={12} >
            <Grid item sm={3}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='requestTypeName' label={t('BRANCHCONFIG0015')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.requestTypeName} />
              </Box>
            </Grid>
            <Grid item sm={3} >
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='employeeEmail' label={t('COMMON042')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.employeeEmail} />
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={handlefilter} color="primary" variant="contained" className='mt-2' backgroundColor={'#36C96D'} hoverbackgroundColor={'#1AB755'}>
                  {t("APPLY001")}
                </FilterButton>
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={reset} variant="outlined" className='mt-2' borderColor={'#36C96D'} hoverborderColor={'#1AB755'} color={'#36C96D'} hovercolor={'#1AB755'} >
                  {t("CLEAR001")}
                </FilterButton>
              </Box>
            </Grid>

          </Grid>
          <Box marginTop='0px'>
            <Tooltip arrow title={t('COMMON170')}>
              <IconButton size='small' variant='contained' color='primary' onClick={() => handleCancel()}>
                <span className='btn-wrapper--icon rounded'>
                  <CloseIcon style={{ color: '#B7B7B7' }} />
                </span>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </> : <>
        {clraefilterButton ?
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FilterButton onClick={clearFilter} variant="outlined" className='m-2' borderColor={'#FC5F4699'} hoverborderColor={'#E5482F99'} hoverbackgroundColor={'#FC5F4608'} color={'#FC5F46'} hovercolor={'#E5482F'} >
                {t("ClearFilters001")}
              </FilterButton>
            </Box>
          </Grid> : ''}
      </>
      }
      <DataTable
        columns={columns}
        rows={data}
        page={page}
        pageCount={totalPages}
        pageSize={rowsPerPage}
        handleChange={(newPage, size) => handleChangePage(newPage, size)}
      />
    </Grid>

  )
}
