import { Box, Grid, IconButton, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { deleteTemplate, getTemplatesList, updateTemplateType } from '../../../actions/templates/TemplatesActions';
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from '../../../components/ConfirmModalNew';
import DataTable from '../../../components/DataTableNew';
import FilterButton from '../../../components/FilterButtom';
import HeaderToolbar from '../../../components/HeaderTooltip';
import CommonUtil from '../../../components/Util/CommonUtils';
import CopyIcon from '../../reusableIcons/CopyIcon';
import DeleteIcon from '../../reusableIcons/DeleteIcon';
import EditIcon from '../../reusableIcons/EditIcon';
import { MaterialUISwitch } from '../../reusableIcons/SwitchIcon';

export default function Templates(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = React.useState(1);
  const store = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [role, setRole] = useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editMode, setEditMode] = useState(false);
  const [clraefilterButton, setClraefilterButton] = useState(false);

  useEffect(() => {
    loadData(page, rowsPerPage);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.template.templateList)) {
      setPage(store.template.templateList.currentPage - 1);
      setData(store.template.templateList.templates);
      setTotalPages(store.template.templateList.totalPages)
    } else {
      setPage(0);
      setData([]);
    }

  }, [store.template.templateList]);

  const loadData = (page, rowsPerPage) => {
    dispatch(getTemplatesList(page, rowsPerPage, '', () => { }));
  };

  useEffect(() => {
    setRole((store.security.user));
  }, [store.security.user]);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangePage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size);
  };

  const CreateTemplate = () => {
    history.push
      ({
        pathname: '/fams/create-templete',
        state: '',
      });
  };

  const EditTemplate = (data) => {
    history.push
      ({
        pathname: '/fams/create-templete',
        state: data,
      });
  };

  const CopyTemplete = (data) => {
    const sampledata = {
      id: data.id,
      code: data.code + "_Copy",
      name: data.name + "_Copy",
      version: data.version,
      company: data.companyId,
      requestTypeId: data.requestTypeId,
      description: data.description,
    }
    history.push
      ({
        pathname: '/fams/create-templete',
        state: sampledata,
        copy: "COPY"
      });
  };

  const handleChanges = (event, data) => {
    const name = (event.target.checked);
    let statusValue = '';
    if (name === true) {
      statusValue = 'ACTIVE';
    } else {
      statusValue = 'INACTIVE';
    }
    if (data.status !== 'DRAFT') {
      const update = {
        requestTypeId: data.requestTypeId.id,
        name: data.name,
        code: data.code,
        status: statusValue,
        version: data.version,
        description: data.description,
      }
      handleSubmit(data.id, update);
    }
  };
  const breadcrumb = [{ path: '', name: t('COMMON195') }];
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: "code",
      headerName: t('TEMTYPE004'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.code,
      renderCell: (params) => (
        <Tooltip title={params.row.code} >
          <span className="table-cell-trucate">{params.row.code}</span>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: t('TEMTYPE005'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={params.row.name} >
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "description",
      headerName: t('REQUESTTYPES0002'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
      renderCell: (params) => (
        <Tooltip title={params.row.description} >
          <span className="table-cell-trucate">{params.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: "requestTypeId.name",
      headerName: t('BRANCHCONFIG0004'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.requestTypeId.name,
      renderCell: (params) => (
        <Tooltip title={params.row.requestTypeId.name} >
          <span className="table-cell-trucate">{params.row.requestTypeId.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.status,
    },
    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon disabled={role.roleCode.toLowerCase() !== "superadmin" && params.row.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") : (role.roleCode.toLowerCase() !== "superadmin")} name={"templete"} />}
          onClick={() => role.roleCode.toLowerCase() !== "superadmin" && params.row.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") ? '' : (EditTemplate(params.row)) : (role.roleCode.toLowerCase() !== "superadmin") ? '' : EditTemplate(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon disabled={role.roleCode.toLowerCase() !== "superadmin" && params.row.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") : (role.roleCode.toLowerCase() !== "superadmin")} />}
          onClick={() => role.roleCode.toLowerCase() !== "superadmin" && params.row.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") ? '' : (deleteRecord(params.row)) : (role.roleCode.toLowerCase() !== "superadmin") ? '' : deleteRecord(params.row)}
        />,
        <GridActionsCellItem
          icon={<CopyIcon disabled={params.row.status === "DRAFT"} />}
          onClick={() => CopyTemplete(params.row)}
          disabled={params.row.status === "DRAFT"}
        />,
        <GridActionsCellItem
          icon={<Tooltip title={role.roleCode !== "SUPERADMIN" && params.row.companyId === role.companyId && params.row.status !== "DRAFT" ? (role.roleCode !== "OWNER" && params.row.status !== "DRAFT") ? '' : params.row.status === "ACTIVE" ? t('COMMON200') : t('COMMON199') : (role.roleCode !== "SUPERADMIN") ?(params.row.status === "DRAFT"&&role.roleCode !== "SUPERADMIN"&&params.row.companyId === role.companyId)?t('TEMP005'): t('TEMP003') :(params.row.status === "DRAFT"&&role.roleCode === "SUPERADMIN"&&params.row.companyId === role.companyId)?t('TEMP005'): params.row.status === "ACTIVE" ? t('COMMON200') : t('COMMON199')}><MaterialUISwitch checked={params.row.status === "ACTIVE"} onChange={(event) => role.roleCode !== "SUPERADMIN" && params.row.companyId === role.companyId && params.row.status !== "DRAFT" ? (role.roleCode !== "OWNER" && params.row.status !== "DRAFT") ? '' : (handleChanges(event, params.row)) : (role.roleCode !== "SUPERADMIN") ? '' : handleChanges(event, params.row)} /></Tooltip>}
        />,
      ],
    },
  ]);

  const handleSubmit = (id, data) => {
    if (!CommonUtil.isEmptyString(id)) {
      dispatch(updateTemplateType(id, data,
        () => {
          if (data.status === "ACTIVE") {
            loadData(page, rowsPerPage);
            toast.success(t('COMMON197'));
          } else if (data.status === "INACTIVE") {
            loadData(page, rowsPerPage);
            toast.success(t('COMMON198'));
          }
        },
        (error) => {
          toast.error(error.message);
        }
      ));
    }
  }

  const deleteRecord = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            title={t('COMMON004')}
            onConfirm={() => {
              dispatch(
                deleteTemplate(data.id,
                  (data) => {
                    toast.success(t('TEMPLATE0001'));
                    loadData(0, rowsPerPage);
                  },
                  (data) => {
                    switch (data.code) {
                      case "FHRMSE0054":
                        toast.error(t("TEMPLETEDELETE"));
                        break;
                      default:
                        toast.error(data.message);
                    }
                  }
                ));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  }
  const [state, setState] = React.useState({
    name: '',
    requestTypeName: '',
    status: ''
  });
  const handleCancel = () => {
    setEditMode(!editMode);
  };
  const clearFilter = () => {
    setClraefilterButton(false);
    loadData(1, rowsPerPage);
  }
  const handleClose = () => {
    setState({
      name: '',
      requestTypeName: '',
      status: ''
    });
    setEditMode(false)
  };
  const reset = () => {
    if (state.name.trim() === '' && state.requestTypeName.trim() === '' && state.status.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setState({
      name: '',
      requestTypeName: '',
      status: ''
    });
  };
  const loadDatas = () => {
    dispatch(getTemplatesList(1, rowsPerPage, state));
  };

  const handlefilter = () => {
    if (state.name.trim() === '' && state.requestTypeName.trim() === '' && state.status.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    loadDatas();
    setClraefilterButton(true)
    setState({
      name: '',
      requestTypeName: '',
      status: ''
    });
    handleClose();
  };

  return (
    <>
      <Breadcrumb links={breadcrumb}>
        <Box p={1} alignItems="center" style={{ alignSelf: 'center' }}>
          {clraefilterButton === false ?
            <Box >
              <Tooltip arrow title={t('COMMON006')}>
                <IconButton size='small' variant='contained' color='secondary' onClick={() => handleCancel()}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Box> : ''}
        </Box>
        <HeaderToolbar src={AddIcon} onClick={CreateTemplate} tooltipTitle={t('COMMON009')} size='large' />
      </Breadcrumb>
      {editMode ? <>
        <Box style={{ boxShadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%)', backgroundColor: '#FFFFFF', marginBottom: '13px' }} display='flex'>
          <Grid container xs={12} sm={12} >
            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='name' label={t('TEMPLATEITEMS00016')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={state.name} />
              </Box>
            </Grid>
            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='requestTypeName' label={t('BRANCHCONFIG0004')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={state.requestTypeName} />
              </Box>
            </Grid>
            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='status' label={t('COMMON001')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={state.status} />
              </Box>
            </Grid>
            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={handlefilter} color="primary" variant="contained" className='mt-2' backgroundColor={'#36C96D'} hoverbackgroundColor={'#1AB755'}>
                  {t("APPLY001")}
                </FilterButton>
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={reset} variant="outlined" className='mt-2' borderColor={'#36C96D'} hoverborderColor={'#1AB755'} color={'#36C96D'} hovercolor={'#1AB755'} >
                  {t("CLEAR001")}
                </FilterButton>
              </Box>
            </Grid>
          </Grid>
          <Box marginTop='0px'>
            <Tooltip arrow title={t('COMMON170')}>
              <IconButton size='small' variant='contained' color='primary' onClick={() => handleCancel()}>
                <span className='btn-wrapper--icon rounded'>
                  <CloseIcon style={{ color: '#B7B7B7' }} />
                </span>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </> : <>
        {clraefilterButton ?
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FilterButton onClick={clearFilter} variant="outlined" className='m-2' borderColor={'#FC5F4699'} hoverborderColor={'#E5482F99'} hoverbackgroundColor={'#FC5F4608'} color={'#FC5F46'} hovercolor={'#E5482F'} >
                {t("ClearFilters001")}
              </FilterButton>
            </Box>
          </Grid> : ''}
      </>
      }
      <DataTable
        columns={columns}
        rows={data}
        page={page}
        pageCount={totalPages}
        pageSize={rowsPerPage}
        handleChange={(newPage, size) => handleChangePage(newPage, size)}
      />
    </>
  );
}
