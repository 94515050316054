import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Reports from './Reports';

export default function ReportsItems() {
    const { t } = useTranslation();
    const breadcrumb = [
        { path: "", name: t("REPORT0001") },
    ];
    return (
        <>
            <Breadcrumb links={breadcrumb} />
            <Reports />
        </>
    );
}
