import axios from 'axios';
import { toast } from 'react-toastify';
import { JAVA_API_REQUEST_TYPE, JAVA_API_REQUEST_TYPE_LIST } from '../BackendEndPoints';
import { REQUEST_TYPE_LIST } from '../types';

export const addRequestType = (data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.post(JAVA_API_REQUEST_TYPE, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const getRequestTypeList = (pageNumber, pageSize, filter) => async (dispatch) => {
  if (filter === undefined || filter === '') {
    filter = {
      code: '',
      name: ''
    }
  }

  const res = await axios.get(JAVA_API_REQUEST_TYPE_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
  if (res.data.code === 'FHRMSI0001') {
    dispatch({
      type: REQUEST_TYPE_LIST,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: REQUEST_TYPE_LIST,
      payload: [],
    });
  }
};


export const updateRequestType = (id, data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.put(JAVA_API_REQUEST_TYPE + '/' + id, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess();
    } else {
      onFail(res.data);
    }
  } catch (error) {
    toast.error();
  }
};

export const deleteRequestType = (id, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.delete(JAVA_API_REQUEST_TYPE + '/' + id);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess();
    } else {
      onFail(res.data);
    }
  } catch (error) {
    toast.error();
  }
};
