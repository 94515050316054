import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ApprovedReports from './ApprovedReports';
import CancelledReports from './CancelledReports';
import RejectedReports from './RejectedReports';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <div {...other}>{value === index && <Box pt={3}>{children}</Box>}</div>;
}
const tabsStyle = {
    textTransform: "none",
    backgroundColor: '#FFFFFF',
}

export default function Reports(props) {
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container
                style={{ display: 'flex' }}
            >
                <Grid item xl={12} md={12} sm={12} style={{ marginTop: '5px', backgroundColor: '#fffff' }} xs={12}>
                    <Tabs value={value} onChange={handleChange}
                        TabIndicatorProps={{ style: { background: '#304669', color: '#304669', height: 3 } }}
                        style={{ color: '#304669' }}
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab label={t('DASHBOARD0002')} style={tabsStyle} />
                        <Tab label={t('DASHBOARD0003')} style={tabsStyle} />
                        <Tab label={t('DASHBOARD0004')} style={tabsStyle} />
                    </Tabs>
                </Grid>
                <Grid md={12} lg={12} sm={12}>
                    {value === 0 && (
                        <TabPanel value={value} index={value}>
                            <ApprovedReports />
                        </TabPanel>
                    )}
                    {value === 1 && (
                        <TabPanel value={value} index={value}>
                            <RejectedReports />
                        </TabPanel>
                    )}
                    {value === 2 && (
                        <TabPanel value={value} index={value}>
                            <CancelledReports />
                        </TabPanel>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
