import { Grid, Hidden } from '@material-ui/core';
import React from 'react';

export default function DashboardTiles(props) {
  return (
    <>
      <Hidden lgUp>
        <Grid style={{ minWidth: '50%', minHeight: '100px' }} item xl={1} lg={2} md={1} sm={1} xs={1}>
          {props.children}
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid style={{ minWidth: '20%', minHeight: '80px' }} item xl={1} lg={2} md={1} sm={1} xs={1}>
          {props.children}
        </Grid>
      </Hidden>
    </>
  );
}
