import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DomainIcon from '@material-ui/icons/Domain';
import Autocomplete from '@material-ui/lab/Autocomplete';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCompaniesByPartnerTypeANDActive } from '../../actions/branch/BranchActions';
import { REACT_URI_MY_REQUESTS, REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import CompanyBackground from '../../assets/images/onboard/BACKGROUND.svg';
import loginImg from '../../assets/images/onboard/Group 230.svg';
import SubmitButtons from '../../components/SubmitButtons';
import CommonUtil from '../../components/Util/CommonUtils';
import { switchCompany } from '../../oauth-client/Onboard';
import setJWTToken from '../../oauth-client/setJWTToken';
import './custom.css';
import LoginLayout from './LoginLayout';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
}));

function VerifyCompany() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setCompany] = useState('');
  const store = useSelector((state) => state);
  const jwtToken = localStorage.getItem("temp_token");
  const [error, setError] = React.useState(false);

  useEffect(() => {
    if (store.security.validToken && !CommonUtil.isEmptyString(store.security.AccJwtToken)) {
      history.push('/fams/dashboard');
    }
    if (!jwtToken) history.push('/fams/login');
    else loadCompanies();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.branch.companyBranchList)) {
      setCompanies(store.branch.companyBranchList.company);
    } else {
      setCompanies([]);
    }
  }, [store.branch.companyBranchList]);

  const loadCompanies = () => {
    setJWTToken(localStorage.getItem("temp_token"));
    const filter = {
      status: '',
    }
    dispatch(getCompaniesByPartnerTypeANDActive(1, 0, filter));
  };
  const VerifyCompany = () => {
    const payload = {
      companyCode: selectedCompany,
      companyId: '',
    };
    dispatch(
      switchCompany(
        payload,
        (successData) => {
          const decoded = jwt_decode(successData.data.access_token);
          localStorage.setItem('USER', JSON.stringify(decoded));
          if (decoded.roleCode === 'EMPLOYEE') {
            history.push({
              pathname: REACT_URI_MY_REQUESTS,
            });
          } else {
            history.push({
              pathname: REACT_URL_DASHBOARD,
            });
          }
        },
        (error) => {
          toast.error(error.message);
          setError(true);
        }
      )
    );
  };

  return (
    <LoginLayout login={loginImg} lang={true} backbutton={true}>
      <Grid container className='center_div' >
        <Grid container className={classes.form}>
          <Grid container spacing={5}
            direction="row"
            justifyContent="center"
            alignItems="center" >
            <Grid container item xs={12} className='logo-center' display='flex'
              justifyContent="center" alignItems='center' style={{ marginTop: '10%' }}>
              <img src={CompanyBackground} alt='logo' style={{ width: '100px' }} />
            </Grid>
            <Grid item xs={12}  >
              <Typography className='signin_title' variant='h3' color='textPrimary' align='left' style={{ margin: '15px' }}>
                {t('COMMON146')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <form className={classes.form} noValidate>
          <Grid container spacing={2} alignItems='center' style={{ marginRight: '20px' }}>
            <Grid item>
              <DomainIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <Autocomplete
                id='company_code'
                openText={t('COMMON169')}
                closeText={t('COMMON170')}
                clearText={t('COMMON215')}
                noOptionsText={t('COMMON214')}
                options={companies}
                getOptionLabel={(option) => option.code}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    setError(false);
                    setCompany(newValue.code);
                  } else {
                    setCompany('');
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} className='login_input' style={{ paddingTop: '2.4px' }} error={error} variant='outlined' fullWidth label={t('COMMON147')} autoFocus onChange={(e) => setCompany(e.target.value)} />
                )}
              />
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={5} alignItems='center' className={classes.form}>
          <Grid item></Grid>
          <Grid item xs style={{ marginLeft: '16px', paddingLeft: '4px', paddingRight: '7px' }}>
            <SubmitButtons type='submit' fullWidth variant='contained' color='primary' className={classes.submit} onClick={VerifyCompany}>
              {t('COMMON148')}
            </SubmitButtons>
          </Grid>
        </Grid>
      </Grid>
    </LoginLayout>
  );
}
export default VerifyCompany;
