import { Box, Grid, Tooltip } from '@material-ui/core';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDesignation, getDesignations } from '../../../actions/organization/DesignationApiCalls';
import addIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from '../../../components/ConfirmModalNew';
import DataTable from '../../../components/DataTableNew';
import HeaderToolbar from '../../../components/HeaderTooltip';
import CommonUtil from '../../../components/Util/CommonUtils';
import DeleteIcon from '../../reusableIcons/DeleteIcon';
import EditIcon from '../../reusableIcons/EditIcon';

export default function Designation() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();

  useEffect(() => {
    loadDesignations(page, rowsPerPage);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.designation.designationList)) {
      setPage(store.designation.designationList.currentPage);
      setTotalPages(store.designation.designationList.totalPages)
    } else {
      setPage(0);
    }
    setData(store.designation.designationList.designation);
  }, [store.designation.designationList]);

  const loadDesignations = (page, rowsPerPage) => {
    dispatch(getDesignations(page, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadDesignations(newPage, size);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => (
      idsToDelete[index] = record.id
    ));
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            title={t('COMMON004')}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteDesignation(idsToDelete, (data) => {
                  handlError(data);
                  loadDesignations(page, rowsPerPage)
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'OMSI0005':
        toast.success(t('DESGDELSUC001'));
        loadDesignations();
        break;
      case 'OMSI0000':
        toast.success(t('DESGDELSUC001'));
        loadDesignations();
        break;
      case 'OMSE0213':
        toast.error(t('DESGPARDELERR001'));
        loadDesignations();
        break;
      case 'OMSW0014':
        const ids = []
        const test = []
        const delList = data.data.error;
        const existinglist = store.designation.designationList.designation;
        delList.map((item) => (
          ids.push(item.data.idOrCode)
        ))
        existinglist.map((item) => (
          ids.map((id) => item.id === id ?
            test.push(<span>{item.code} : {data.data.error[0].message}</span>)
            : '')
        ));
        var toastMessage = () => {
          var hh = test.map((i) => {
            return <><span>{i}</span><br /></>
          })
          return <>{hh}</>;
        }
        toast.error(toastMessage());
        loadDesignations();
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  const breadcrumb = [{ path: '', name: t('DESI001') }];
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: "name",
      headerName: t('DESI004'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={params.row.name} >
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t('DESI003'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.code,
      renderCell: (params) => (
        <Tooltip title={params.row.code} >
          <span className="table-cell-trucate">{params.row.code}</span>
        </Tooltip>
      ),
    },
    {
      field: "description",
      headerName: t('DESI009'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
      renderCell: (params) => (
        <Tooltip title={params.row.description} >
          <span className="table-cell-trucate">{params.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: "parentDesignation.name",
      headerName: t('COMMON050'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.parentDesignation ? params.row?.parentDesignation.name : '',
      renderCell: (params) => (
        <Tooltip title={params.row.parentDesignation ? params.row?.parentDesignation.name : ''} >
          <span className="table-cell-trucate">{params.row.parentDesignation ? params.row?.parentDesignation.name : ''}</span>
        </Tooltip>
      ),
    },

    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => EditDesignation(params.row)}
          disabled={params.row.code.toLowerCase() === "default"}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => deleteRecord([params.row])}
          disabled={params.row.code.toLowerCase() === "default"}
        />,
      ],
    },
  ]);

  const createDesignation = () => {
    history.push
      ({
        pathname: '/fams/create-designation',
        state: data,
      });
  };
  const EditDesignation = (data) => {
    history.push
      ({
        pathname: '/fams/create-designation',
        state: data,
      });
  };

  return (
    <Grid>
      <Box display='flex' justifyContent='right'>
        <Breadcrumb links={breadcrumb}>
          <HeaderToolbar src={addIcon} onClick={createDesignation} tooltipTitle={t('COMMON009')} size='large' />
        </Breadcrumb>
      </Box>
      <div style={{ height: '100%', width: "100%" }}>
        <DataTable
          columns={columns}
          rows={data}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChangeRowsPerPage(newPage, size)}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </Grid>
  );
}
