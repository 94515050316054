import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_LOGIN } from '../../actions/EndPoints';
import forgetImgm from '../../assets/images/onboard/Group 5404.svg';
import famsBackground from '../../assets/images/onboard/Group 7962.svg';
import resetpwdimg from '../../assets/images/onboard/Group 8404.svg';
import SubmitButtons from '../../components/SubmitButtons';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import CommonUtil from '../../components/Util/CommonUtils';
import { validateActivationCode } from '../../oauth-client/Onboard';
import './custom.css';
import LoginLayout from './LoginLayout';
const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  }, label: {
    color: '#999',
    fontSize: '12px',
    marginLeft: '20px',
    textAlign: 'start',
  },
  labelActive: {
    color: '#4C6A9F',
    fontSize: '12px',
    margin: '20px'
  },
  email_icon: {
    height: '10%',
    width: '15%',
  },
}));
function ActivateAccount() {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    confirmPassword: '',
    confirmShowPassword: false,
  });

  const [error, setError] = useState({
    password: false,
    confirmPassword: false,
  });
  const [code, setCode] = useState('');
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setError({ ...error, [prop]: false });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickConfirmShowPassword = () => {
    setValues({ ...values, confirmShowPassword: !values.confirmShowPassword });
  };

  useEffect(() => {
    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get('code');
    if (code != null) {
      setCode(code);
    }
    var lang = url.searchParams.get('lang');
    if (lang != null) {
      BrowserLanguage.setLanguage(lang);
      i18n.changeLanguage(lang);
    }
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (CommonUtil.isEmptyString(values.password)) {
      setError({ ...error, password: true });
      toast.error(t('RESET0003'));
      return;
    }
    if (!CommonUtil.isValidPassword(values.password)) {
      setError({ ...error, password: true });
      toast.error(t('RESET0004'));
      return;
    }
    if (CommonUtil.isEmptyString(values.confirmPassword)) {
      setError({ ...error, confirmPassword: true })
      toast.error(t('RESET0005'));
      return;
    }
    if (values.password !== values.confirmPassword) {
      setError({ ...error, confirmPassword: true })
      toast.error(t('RESET0002'));
      return;
    }
    const user = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    dispatch(
      validateActivationCode(
        code,
        user,
        (successData) => {
          setSuccess(successData);
        },
        (errorData) => {
          setError(errorData);
          toast.error(errorData.message);
          handleError(errorData);
        }
      )
    );
    event.preventDefault();
  };

  const handleError = (error) => {
    switch (error.code) {
      case 'CAAE0605':
        setError({ ...error, confirmPassword: true });
        break;
      case 'CAAE0603':
        setError({ ...error, password: true });
        break;
      case 'CAAE0606':
        setError({ ...error, password: true });
        break;
      case 'CAAE0607':
        setError({ ...error, confirmPassword: true });
        break;
        default:
          break;
    }
  };

  return CommonUtil.isEmptyString(code) ? (
    <></>
  ) : (
    <LoginLayout login={resetpwdimg} disableLeftpage={success}>
      <Grid className='center_div' >
        <Grid container rowSpacing={1} direction='row' justifyContent='center' alignItems='center' className={classes.form}>
          <Grid item className='logo-center'>
            {success ? <img src={forgetImgm} alt='logo' style={{ width: '250px' }} /> :
              <img src={famsBackground} alt='logo' style={{ width: '180px', marginBottom: '60px' }} />}
          </Grid>
          <Grid item xs={12} >
            <Typography variant='h2' align='left' style={{ color: '#3D4977', fontWeight: '500', marginLeft: '20px' }}>
              {success ? <Typography style={{ marginBottom: '10px' }}>{t('success')}</Typography> : t('RPW001')}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            {success ? (
              <Typography variant='h3' className={classes.label} color='#30466987' style={{ marginBottom: '10px' }} >
                {t('RESET0006')}
              </Typography>
            ) : <></>}
          </Grid>
        </Grid>{!success ? (
          <form className={classes.form} noValidate /*onSubmit={handleSubmit} */ autoComplete='off'>
            <Grid container alignItems='center' style={{ marginBottom: '5px' }}>
              <Grid item>
                <PersonIcon className='login_icons' />
              </Grid>
              <Grid item xs>
                <TextField
                  className='text_input'
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  label={t('FPW002')}
                  id='password'
                  autoComplete='off'
                  name='password'
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  error={error.password}
                  onChange={handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                          {values.showPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems='center' style={{ marginBottom: '30px' }}>
              <Grid item>
                <LockIcon className='login_icons' />
              </Grid>
              <Grid item xs>
                <TextField
                  className='text_input'
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  label={t('FPW003')}
                  id='confirmpassword'
                  autoComplete='off'
                  name='password'
                  type={values.confirmShowPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  error={error.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleClickConfirmShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                          {values.confirmShowPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems='center'>
              <Grid item></Grid>
              <Grid item xs>
                <SubmitButtons type='submit' fullWidth variant='contained' onClick={handleSubmit} href={REACT_LOGIN} color='primary' className={classes.submit}>
                  {t('RPW001')}
                </SubmitButtons>
              </Grid>
            </Grid>
            <Grid className='signin_title' variant='h1' align='center' style={{ paddingTop: '20px', marginLeft: '27px' }}>
              <Button color='primary' className='forgot_password' href={REACT_LOGIN} size={'small'}>
                {t('FPW005')}
              </Button>
            </Grid>
          </form>
        ) : (
          <Grid className='signin_title' variant='h1' align='left' >
            {success ? <SubmitButtons type='submit' fullWidth variant='contained' color='primary' href={REACT_LOGIN} className={classes.submit} size='medium'>
              {t('COMMON145')}
            </SubmitButtons> : <></>}
          </Grid>
        )}
      </Grid>
    </LoginLayout>
  );
}
export default withRouter(ActivateAccount);
