import Dialog from '@mui/material/Dialog';
import * as React from 'react';

function SimpleDialog(props) {
    const { onClose, open } = props;
    return (
        <Dialog onClose={onClose} open={open}>
            {props.data}
        </Dialog>
    );
}
export default function SimpleDialogDemo(props) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
    };
    return (
        <div>
            <div onClick={handleClickOpen}>
                {props.button}
            </div>
            <SimpleDialog
                data={props.child}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}
