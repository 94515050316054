import { DEPARTMENT_ERRORS, DESIGNATION_ERRORS, EMPLOYEE_ERRORS, GET_ERRORS, RESIGNED_EMPLOYEE_ERRORS } from "../actions/types";

const initialState = {
    employeeError: {},
    departmentError: {},
    designationError: {},
    resignedEmployeeError: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            return action.payload;
        case EMPLOYEE_ERRORS:
            return {
                ...state,
                employeeError: action.payload
            }
        case DEPARTMENT_ERRORS:
            return {
                ...state,
                departmentError: action.payload
            }
        case DESIGNATION_ERRORS:
            return {
                ...state,
                designationError: action.payload
            }
        case RESIGNED_EMPLOYEE_ERRORS:
            return {
                ...state,
                resignedEmployeeError: action.payload
            }
        default:
            return {
                ...initialState
            };
    }
}
