import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';
import { REACT_LOGIN } from '../../actions/EndPoints';
import forgetImgm from '../../assets/images/onboard/Group 5404.svg';
import SubmitButtons from '../../components/SubmitButtons';
import './custom.css';
import LoginLayout from './LoginLayout';
const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
    height: '100%'
  },
  submit: {
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
  label: {
    color: '#999',
    fontSize: '12px',
  },
  labelActive: {
    color: '#4C6A9F',
    fontSize: '12px',
  },
  email_icon: {
    height: '10%',
    width: '350px',
    marginBottom: '20px',
    marginTop: '30px',
  },
}));
function CommonResultPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <LoginLayout disableLeftpage={true}>
      <Grid container className='center_div' >
        <Grid container alignItems='center' >
          <Grid item xs>
            <Typography align='center'>
              <img src={forgetImgm} alt='logo' className={classes.email_icon} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Typography variant='h2' align='left' style={{ color: '#3D4977', fontWeight: '500', marginLeft: '20px', marginBottom: '10px' }}>{t('success')}</Typography>
        </Grid>
        <Grid container spacing={2} align='center'>
          <Grid item xs>
            <Typography variant='h2' className={classes.labelActive} color='#30466987' style={{ marginBottom: '10px' }} >
              {t(location.state.message)}
            </Typography>
          </Grid>
        </Grid>
        {location.state.redirectLogin ? (
          <Grid container alignItems='flex-end' justify='center'>
            <Grid item alignItems='flex-end' justify='center'>
              <SubmitButtons className='forgot_passwords' href={REACT_LOGIN} size={'medium'} style={{ width: '350px', height: '20px' }}>
                {t('COMMON133')}
              </SubmitButtons>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </LoginLayout>
  );
}
export default withRouter(CommonResultPage);
