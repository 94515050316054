export const GET_ERRORS = 'GET_ERRORS';
export const SUCCESS = 'SUCCESS';

//OAuth-client
export const GET_COMPANIES = 'GET_COMPANIES';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const DASHBOARD_DATA = 'DASHBOARD_DATA';

export const PERSON_LIST = 'PERSON_LIST';
export const PERSON_ERRORS = 'PERSON_ERRORS';

export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const DEPARTMENT_ERRORS = 'DEPARTMENT_ERRORS';

export const DESIGNATION_LIST = 'DESIGNATION_LIST';
export const DESIGNATION_ERRORS = 'DESIGNATION_ERRORS';

export const USER_ROLE_LIST = 'USER_ROLE_LIST';

export const USER_FILTER_LIST = 'USER_FILTER_LIST';
