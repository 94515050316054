import { GET_FAMS_EMPLOYEE_LIST, GET_FAMS_EMPLOYEE_LIST_USER_ID } from "../actions/types";

const initialState = {
    employeeList: [],
    employeeUserIdList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FAMS_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: action.payload
            };
        case GET_FAMS_EMPLOYEE_LIST_USER_ID:
            return {
                ...state,
                employeeUserIdList: action.payload
            };
        default:
            return state;
    }
}