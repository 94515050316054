import axios from 'axios';
import { JAVA_API_MY_REQUESTS_LIST } from '../BackendEndPoints';
import { GET_STATUS_REPORT_LIST } from "../types";

export const getStatusReportLogList = (pageNumber, pageSize, payload) => async (dispatch) => {

	const res = await axios.get(JAVA_API_MY_REQUESTS_LIST + '?isPagable=true&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&status=' + payload.status);
	if (res.data.code === 'FHRMSI0001') {
		dispatch({
			type: GET_STATUS_REPORT_LIST,
			payload: res.data.data,
		});
	} else {
		dispatch({
			type: GET_STATUS_REPORT_LIST,
			payload: [],
		});
	}
};




