import { Avatar, Box, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { GridActionsCellItem } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getEmployeeListByUserId } from '../../../actions/approvalCondition/ApprovalConditionActions';
import { activateEmployee, checkIsMemeberActive, deactivateEmployee, deletePerson } from '../../../actions/organization/PersonApiCalls';
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from '../../../components/ConfirmModalNew';
import DataTable from '../../../components/DataTableNew';
import FilterButton from '../../../components/FilterButtom';
import HeaderToolbar from '../../../components/HeaderTooltip';
import CommonUtil from '../../../components/Util/CommonUtils';
import ActivatePersonIcon from '../../reusableIcons/ActivatePersonIcon';
import DectivatePersonIcon from '../../reusableIcons/DeactivatePerson';
import DeleteIcon from '../../reusableIcons/DeleteIcon';
import EditIcon from '../../reusableIcons/EditIcon';
import SimpleDialogDemo from './imagedailogbox';
export default function Person() {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [clraefilterButton, setClraefilterButton] = useState(false);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const breadcrumb = [{ path: '', name: t('PERSON001') }];
  const [filter, setFilter] = useState({
    firstName: '',
    email: '',
  });
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem("access_token")
  );

  useEffect(() => {
    loadEmployees(page, rowsPerPage, filter);
    // eslint-disable-next-line
  }, []);

  const loadEmployees = (page, rowsPerPage, filter) => {
    dispatch(getEmployeeListByUserId(page, rowsPerPage, filter));
  }
  useEffect(() => {
    if (!CommonUtil.isEmpty(store.employeeV2.employeeUserIdList)) {
      setPage(store.employeeV2.employeeUserIdList.data.currentPage - 1);
      setData(store.employeeV2.employeeUserIdList.data.employee);
      setTotalPages(store.employeeV2.employeeUserIdList.data.totalPages);
    } else {
      setPage(0);
      setData([]);
    }
  }, [store.employeeV2.employeeUserIdList]);

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];

    var memberShipActiveUsers = [];
    // eslint-disable-next-line 
    data.map((record, index) => {
      if (record.userId === undefined) {
        idsToDelete[index] = record.id;
      } else {
        if (record.email !== decoded_jwtToken.email) {
          dispatch(checkIsMemeberActive(record.userId, index, record, (inde, recor, response) => { handleCallBack(inde, recor, response) }));
        }
      }
    });

    const handleCallBack = (index, record, response) => {
      if (response.code === 'UASI0000') {
        memberShipActiveUsers[index] = record.id
      } else {
        idsToDelete[index] = record.id
      }
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            title={t('COMMON004')}
            note={t('NOTEPERSONDELETE')}
            onConfirm={() => {
              if (idsToDelete.length > 0) {
                dispatch(
                  deletePerson(idsToDelete.join(','), (data) => {
                    handlError(data);
                    loadEmployees(page, rowsPerPage, filter);
                  })
                );
              } else {
                toast.error(t('PERSON0010'));
              }
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'OMSI0008':
        toast.success(data.message);
        loadEmployees(page, rowsPerPage, filter);
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  const activatePerson = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var personsToActivate = [];
    // eslint-disable-next-line 
    data.map((record, index) => {
      let person = {
        id: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email,
        phone: record.phone,
        userId: record.userId,
        companyCode: store.security.user.companyCode,
        employeeCode: record.employeeCode,
        gender: record.gender,
      };
      personsToActivate[index] = person;
    });

    dispatch(
      activateEmployee(personsToActivate, (successData) => {
        if (successData.code === 'FHRMSE0087') {
          toast.success(t("PERSON0011"));
        } else if (successData.code === 'FHRMSE0090') {
          toast.warning(successData.message);
        } else if (successData.code === 'FHRMSE0088') {
          toast.error(t('PERSON045'));
        } else if (successData) {
          toast.success(successData.message);
        } else {
          toast.error(successData.message);
        }
        loadEmployees(page, rowsPerPage, filter);
      })
    );
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({
      ...filter,
      [name]: event.target.value,
    });
  };
  const handleChangess = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadEmployees(newPage, size, filter);
  }

  const deactivateMembership = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    if (data.email !== decoded_jwtToken.email) {
      const deactivate = {
        email: data.email,
        id: data.id
      }
      dispatch(
        deactivateEmployee(deactivate, (successData) => {
          if (successData.code === 'UASI0010') {
            toast.success(t('DEACTIVATEUSER'));
          } else if (successData.code === 'UASW0001') {
            toast.warning(t('MEMNOTFND001'));
          } else {
            toast.error(successData.message);
          }
          loadEmployees(page, rowsPerPage, filter);
        })
      );
    } else {
      toast.error(t('DEACTIVATINGADMINACCOUNT'))
    }
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.firstName}
          src={`data:image/png;base64,${params.row.profilePhoto}`}
        ></Avatar>
      }
      child={<Avatar
        style={{
          width: '300px', height: '300px', border: "12px solid #FFFF",
          fontSize: '100px',
          borderRadius: '1px'
        }}
        alt={params.row.firstName}
        src={`data:image/png;base64,${params.row.profilePhoto}`}
      ></Avatar>}
    />
  )
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: "employees.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          ZoomHandaler(params)
        );
      },
    },
    {
      field: "employees.firstName",
      headerName: t('COMMON014'),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.firstName +
        " " +
        (params.row.lastName === undefined ? "" : params.row.lastName) + (params.row.employeeCode === undefined ? "" : params.row.employeeCode),
      renderCell: (params) => (
        <Tooltip title={params.row.firstName + '\n' + params.row.employeeCode} >
          <span className="table-cell-trucate">{params.row.firstName} <span style={{ fontSize: '10px' }}>{'[' + params.row.employeeCode + ']'}</span></span>
        </Tooltip>
      ),
    },
    {
      field: 'roleName',
      headerName: t('MEMBER004'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.roleName === undefined ? 'Inactive' : params.row.roleName,
      renderCell: (params) => (
        <Tooltip title={params.row.roleName === undefined ? 'Inactive' : params.row.roleName} >
          <span className="table-cell-trucate">{params.row.roleName === undefined ? 'Inactive' : params.row.roleName}</span>
        </Tooltip>
      ),
    },
    {
      headerName: t('PERSON014'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.email + '\n' + params.row.phone,
      renderCell: (params) => (
        <Tooltip title={params.row.email + '\n' + params.row.phone} >
          <span className="table-cell-trucate">{params.row.email + '\n' + params.row.phone}</span>
        </Tooltip>
      ),
    },
    {
      field: 'departmentName',
      headerName: t('PERSON015'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.departmentName,
      renderCell: (params) => (
        <Tooltip title={params.row.departmentName} >
          <span className="table-cell-trucate">{params.row.departmentName}</span>
        </Tooltip>
      ),
    },

    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => openEditPerson(params.row)}
        />,
        <GridActionsCellItem
          icon={<ActivatePersonIcon />}
          onClick={() => activatePerson([params.row])}
        />,
        <GridActionsCellItem
          icon={<DectivatePersonIcon />}
          onClick={() => deactivateMembership(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => deleteRecord([params.row])}
        />,
      ],
    },
  ]);
  const openCreateRequest = () => {
    history.push
      ({
        pathname: '/fams/Addperson',
        state: data,
      });
  };
  const openEditPerson = (data) => {
    history.push
      ({
        pathname: '/fams/Addperson',
        state: data,
      });
  };
  const handleCancel = () => {
    setFilter({
      firstName: '',
      email: '',
    });
    setEditMode(!editMode);
  };

  const clearFilter = () => {
    setFilter({
      firstName: '',
      email: '',
    });
    setClraefilterButton(false);
    loadEmployees(1, rowsPerPage, '');
  }
  const handleClose = () => {
    setEditMode(false)
  };
  const reset = () => {
    if (filter.firstName.trim() === '' && filter.email.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setFilter({
      firstName: '',
      email: '',
    });
  };

  const handleSubmit = () => {
    if (filter.firstName.trim() === '' && filter.email.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    loadEmployees(1, rowsPerPage, filter);
    setClraefilterButton(true)
    handleClose();
  };
  return (<>
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Box p={1} alignItems="center" style={{ alignSelf: 'center' }}>
          {clraefilterButton === false ?
            <div >
              <Tooltip arrow title={t('COMMON006')}>
                <IconButton size='small' variant='contained' color='secondary' onClick={() => handleCancel()}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div> : ''}
        </Box>
        <HeaderToolbar src={AddIcon} onClick={openCreateRequest} tooltipTitle={t('COMMON009')} size='large' />
      </Breadcrumb>
      {editMode ? <>
        <Box style={{ boxShadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%)', backgroundColor: '#FFFFFF', marginBottom: '13px' }} display='flex'>
          <Grid container xs={12} sm={12} >
            <Grid item sm={3}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='firstName' label={t('USER0001')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.firstName} />
              </Box>
            </Grid>
            <Grid item sm={3} >
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='email' label={t('PERSON005')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.email} />
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={handleSubmit} color="primary" variant="contained" className='mt-2' backgroundColor={'#36C96D'} hoverbackgroundColor={'#1AB755'}>
                  {t("APPLY001")}
                </FilterButton>
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={reset} variant="outlined" className='mt-2' borderColor={'#36C96D'} hoverborderColor={'#1AB755'} color={'#36C96D'} hovercolor={'#1AB755'} >
                  {t("CLEAR001")}
                </FilterButton>
              </Box>
            </Grid>

          </Grid>
          <Box marginTop='0px'>
            <Tooltip arrow title={t('COMMON170')}>
              <IconButton size='small' variant='contained' color='primary' onClick={() => handleCancel()}>
                <span className='btn-wrapper--icon rounded'>
                  <CloseIcon style={{ color: '#B7B7B7' }} />
                </span>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </> : <>
        {clraefilterButton ?
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FilterButton onClick={clearFilter} variant="outlined" className='m-2' borderColor={'#FC5F4699'} hoverborderColor={'#E5482F99'} hoverbackgroundColor={'#FC5F4608'} color={'#FC5F46'} hovercolor={'#E5482F'} >
                {t("ClearFilters001")}
              </FilterButton>
            </Box>
          </Grid> : ''}
      </>
      }
      <div style={{ height: '100%', width: "100%" }}>
        <DataTable
          columns={columns}
          rows={data}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChangess(newPage, size)}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </Grid>
  </>
  );
}

