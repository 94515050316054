import { Box, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteTemplateItem, getTemplateItemList } from "../../../actions/templateItems/TemplateItemsActions";
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from '../../../components/ConfirmModalNew';
import DataTable from '../../../components/DataTableNew';
import FilterButton from "../../../components/FilterButtom";
import HeaderToolbar from "../../../components/HeaderTooltip";
import CommonUtil from "../../../components/Util/CommonUtils";
import DeleteIcon from "../../reusableIcons/DeleteIcon";
import EditIcon from "../../reusableIcons/EditIcon";

export default function TemplateItems() {
  const { t } = useTranslation();
  const history = useHistory();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const breadcrumb = [{ path: '', name: t('TEMPLATEITEMS0001') }];
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(1);
  const [role, setRole] = useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editMode, setEditMode] = useState(false);
  const [clraefilterButton, setClraefilterButton] = useState(false);
  const [filter, setFilter] = useState({
    name: '',
  });
  // eslint-disable-next-line 
  const columns = React.useMemo(() => [
    {
      field: "code",
      headerName: t('TEMPLATEITEMS0016'),
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip title={params.row.code} >
          <span className="table-cell-trucate">{params.row.code}</span>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: t('TEMPLATEITEMS0017'),
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip title={params.row.name} >
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "templateId.name",
      headerName: t('TEMPLATEITEMS0004'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.templateId.name,
      renderCell: (params) => (
        <Tooltip title={params.row.templateId.name} >
          <span className="table-cell-trucate">{params.row.templateId.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "hint",
      headerName: t('TEMPLATEITEMS0002'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.hint} >
          <span className="table-cell-trucate">{params.row.hint}</span>
        </Tooltip>
      ),
    }, {
      field: "valueType",
      headerName: t('TEMPLATEITEMS0003'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.valueType} >
          <span className="table-cell-trucate">{params.row.valueType}</span>
        </Tooltip>
      ),
    },
    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon disabled={role.roleCode.toLowerCase() !== "superadmin" && params.row.templateId.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") : (role.roleCode.toLowerCase() !== "superadmin")} name={"templete"} />}
          onClick={() => role.roleCode.toLowerCase() !== "superadmin" && params.row.templateId.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") ? '' : (EditTemplateItem(params.row)) : (role.roleCode.toLowerCase() !== "superadmin") ? '' : EditTemplateItem(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon disabled={role.roleCode.toLowerCase() !== "superadmin" && params.row.templateId.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") : (role.roleCode.toLowerCase() !== "superadmin")} />}
          onClick={() => role.roleCode.toLowerCase() !== "superadmin" && params.row.templateId.companyId === role.companyId ? (role.roleCode.toLowerCase() !== "owner") ? '' : (deleteRecord(params.row)) : (role.roleCode.toLowerCase() !== "superadmin") ? '' : deleteRecord(params.row)}
        />,
      ],
    },
  ]);


  const CreateTemplateItem = () => {
    history.push
      ({
        pathname: '/fams/create-templeteitems',
        state: '',
      });
  };
  const EditTemplateItem = (data) => {
    history.push
      ({
        pathname: '/fams/create-templeteitems',
        state: data,
      });
  };
  // eslint-disable-next-line 
  useEffect(() => {
    setRole((store.security.user));
  });
  const handleChangePage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size, filter);
  };
  useEffect(() => {
    loadData(page, rowsPerPage, filter);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.templateItem.templateItemList)) {
      setPage(store.templateItem.templateItemList.currentPage - 1);
      setData(store.templateItem.templateItemList.template_items);
      setTotalPages(store.templateItem.templateItemList.totalPages)
    } else {
      setPage(0);
      setData([]);
    }

  }, [store.templateItem.templateItemList]);

  const loadData = (page, rowsPerPage, filter) => {
    dispatch(getTemplateItemList(page, rowsPerPage, filter));
  };

  const deleteRecord = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            title={t('COMMON004')}
            onConfirm={() => {
              dispatch(
                deleteTemplateItem(data.id,
                  (data) => {
                    loadData(0, rowsPerPage, filter);
                    toast.success(t('TEMPLATEITEMS0010'));
                  },
                  (data) => {
                    switch (data.code) {
                      case "FHRMSE0054":
                        toast.error(t("TEMPLEITEMTEDELETE"));
                        break;
                      default:
                        toast.error(data.message);
                    }
                  }
                ));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({
      ...filter,
      [name]: event.target.value,
    });
  };
  const handleCancel = () => {
    setFilter({
      name: '',
    });
    setEditMode(!editMode);
  };
  const clearFilter = () => {
    setFilter({
      name: '',
    });
    setClraefilterButton(false);
    loadData(1, rowsPerPage, '');
  }

  const handleClose = () => {
    setEditMode(false)
  };
  const reset = () => {
    if (filter.name.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setFilter({
      name: '',
    });
  };

  const handlefilter = () => {
    if (filter.name.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    loadData(1, rowsPerPage, filter);
    setClraefilterButton(true);
    handleClose();
  };
  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Box p={1} alignItems="center" style={{ alignSelf: 'center' }}>
          {clraefilterButton === false ?
            <Box >
              <Tooltip arrow title={t('COMMON006')}>
                <IconButton size='small' variant='contained' color='secondary' onClick={() => handleCancel()}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Box> : ''}
        </Box>
        <HeaderToolbar src={AddIcon} onClick={CreateTemplateItem} tooltipTitle={t('COMMON009')} size='large' />
      </Breadcrumb>
      {editMode ? <>
        <Box style={{ boxShadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%)', backgroundColor: '#FFFFFF', marginBottom: '13px' }} display='flex'>
          <Grid container xs={12} sm={12} >
            <Grid item sm={3}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='name' label={t('TEMTYPE005')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.name} />
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={handlefilter} color="primary" variant="contained" className='mt-2' backgroundColor={'#36C96D'} hoverbackgroundColor={'#1AB755'}>
                  {t("APPLY001")}
                </FilterButton>
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={reset} variant="outlined" className='mt-2' borderColor={'#36C96D'} hoverborderColor={'#1AB755'} color={'#36C96D'} hovercolor={'#1AB755'} >
                  {t("CLEAR001")}
                </FilterButton>
              </Box>
            </Grid>
          </Grid>
          <Box marginTop='0px'>
            <Tooltip arrow title={t('COMMON170')}>
              <IconButton size='small' variant='contained' color='primary' onClick={() => handleCancel()}>
                <span className='btn-wrapper--icon rounded'>
                  <CloseIcon style={{ color: '#B7B7B7' }} />
                </span>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </> : <>
        {clraefilterButton ?
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FilterButton onClick={clearFilter} variant="outlined" className='m-2' borderColor={'#FC5F4699'} hoverborderColor={'#E5482F99'} hoverbackgroundColor={'#FC5F4608'} color={'#FC5F46'} hovercolor={'#E5482F'} >
                {t("ClearFilters001")}
              </FilterButton>
            </Box>
          </Grid> : ''}
      </>
      }
      <DataTable
        columns={columns}
        rows={data}
        page={page}
        pageCount={totalPages}
        pageSize={rowsPerPage}
        handleChange={(newPage, size) => handleChangePage(newPage, size)}
      />
    </Grid>
  )
}