import { Box, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { REACT_URI_REQUEST_TYPE } from '../../../actions/EndPoints';
import { addRequestType, updateRequestType } from '../../../actions/requestType/RequestTypeActions';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: "700px",
        [theme.breakpoints.down("lg")]: {

            height: "400px",
        },

    },
}));
export default function CreateRequestType(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        id: props.history.location.state ? props.history.location.state.id : '',
        code: props.history.location.state ? props.history.location.state.code : '',
        name: props.history.location.state ? props.history.location.state.name : '',
        description: props.history.location.state ? props.history.location.state.description : '',
    });
    const [error, setError] = React.useState({
        code: false,
        name: false,
        description: false,
    });

    const handleClose = (event) => {
        history.push(REACT_URI_REQUEST_TYPE)
    }
    const breadcrumb = [
        { path: REACT_URI_REQUEST_TYPE, name: t('BRANCHCONFIG0004') },
        { path: '/fams/create-request', name: props.history.location.state.id === undefined ? t("REQUESTTYPES0001") : t("REQUESTTYPES0007") },
    ];
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };
    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(state.code)) {
            setError({ code: true })
            toast.error(t("REQUESTTYPES0003"))
        } else if (!CommonUtil.validateCode(state.code)) {
            setError({ code: true })
            toast.error(t("codeValidation"))
        } else if (CommonUtil.isEmptyString(state.name)) {
            setError({ name: true })
            toast.error(t("REQUESTTYPES0004"))
        } else if (!CommonUtil.validateName(state.name)) {
            setError({ name: true })
            toast.error(t("nameValidation"))
        } else {
            const update = {
                code: state.code,
                name: state.name,
                description: state.description,
            }
            if (!CommonUtil.isEmptyString(state.id)) {
                dispatch(updateRequestType(state.id, update,
                    () => {
                        handleClose();
                        toast.success(t('REQUESTTYPES0006'));
                    }, (error) => {
                        switch (error.code) {
                            case 'FHRMSE0042':
                                setError({ code: true });
                                break;
                            case 'FHRMSE0043':
                                setError({ name: true });
                                break;
                            default:
                                setError({ ...error });
                        }
                        toast.error(error.message);
                    }
                ));
            } else {
                dispatch(addRequestType(update,
                    () => {
                        handleClose();
                        toast.success(t('REQUESTTYPES0005'));
                    },
                    (error) => {
                        onFail(error);

                    }
                ));
            }
        }
    }

    const onFail = (data) => {
        if (data) {
            switch (data.code) {
                case "FHRMSE0042":
                    setError({ code: true });
                    break;
                case "FHRMSE0043":
                    setError({ name: true });
                    break;
                case "FHRMSE0083":
                    setError({ name: true });
                    break;
                default:
                    setError({ ...error });
            }
            toast.error(data.message);
        }
    }
    return (
        <>
            <Grid  >
                <Breadcrumb links={breadcrumb} />
                <Grid fullWidth className={`${classes.customContainer} background-size`}>
                    <Grid container sx={12} xs={12} alignItems="center">
                        <Grid item xs={12} sm={6} >
                            <Box display="flex" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.code}
                                    name="code"
                                    label={t("COMMON045") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.code}
                                    onChange={handleChange}
                                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 20 }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Box display="flex" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.name}
                                    name="name"
                                    label={t("COMMON044") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.name}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 40 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                maxRows={4}
                                variant="outlined"
                                error={error.description}
                                name="description"
                                label={t("REQUESTTYPES0002")}
                                helperText=""
                                size="medium"
                                value={state.description}
                                onChange={handleChange}
                                inputProps={{ maxLength: 250 }}
                            />
                        </Box>
                    </Grid>
                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">
                        <Box display="flex" m={1} p={1}>
                            <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-3'>
                                {t("COMMON008")}
                            </CancelButtons>
                            <SubmitButtons onClick={handleSubmit} color="primary" variant="contained" className='m-3'>
                                {t("COMMON035")}
                            </SubmitButtons>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
