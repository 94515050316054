import { Box, DialogActions, Tooltip } from '@material-ui/core';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Divider, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { Typography } from 'antd';
import Axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JAVA_API_FILE_DOWNLOAD } from '../../actions/BackendEndPoints';
import fileIcon from "../../assets/images/file.svg";
import CustomButton from '../../components/CustomButton';
import CommonUtil from '../../components/Util/CommonUtils';
import ApproveOrRejectButtonsDialog from '../approver/ApproveOrRejectButtonsDialog';

function ViewRequestDetails(props) {
    const [open, setOpen] = React.useState(props.open);
    const requestObj = props.selectedObject;
    const requestDetailsJson = requestObj?.requestDetails;
    const arrObj = JSON.parse(requestDetailsJson);
    const [selectedObject, setSelectedObject] = React.useState(props.selectedObject);
    const { t } = useTranslation();
    const [statuss, setStatuss] = useState([]);
    const [approveOrReject, setApproveOrReject] = useState(false);
    const [title, seTitle] = React.useState([]);
    const [lebal, setLebal] = React.useState([]);
    const [text, setText] = React.useState([]);
    const handleClose = () => {
        props.onClose();
    };

    const handleReject = () => {
        setSelectedObject(selectedObject);
        setApproveOrReject(true);
        seTitle(t("DASHBOARD0007"));
        setText(t("MYREQ00010"));
        setStatuss("REJECTED")
        setLebal(t('COMMON016') + '*')
        setOpen(false);
    }
    const handleapproved = () => {
        setSelectedObject(selectedObject);
        setApproveOrReject(true);
        seTitle(t("DASHBOARD0006"));
        setText(t("MYREQ0008"));
        setStatuss("APPROVED")
        setLebal(t('COMMON016'))
        setOpen(false);
    };

    const getDownloadLink = async () => {
        Axios
            .post(JAVA_API_FILE_DOWNLOAD, { objectKey: props.selectedObject.attachments }, {

            })
            .then(async (res) => {
                // window.open(res.data.data.preSignedUrl); 
                let a = document.createElement("a");
                a.href = res.data.data.preSignedUrl;
                a.setAttribute("download", props.selectedObject.attachments);
                a.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const splitByComma = (data) => {
        let remarks = data.split(',');
        if (remarks.length > 0) {
            return (
                <div className="users-remarks">
                    {remarks.map((remark) => (
                        <div>{remark}</div>
                    ))}
                </div>
            );
        }
    };

    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" className="custom-modalsss" open={open} >
                <DialogTitle id="form-dialog-title">
                    <Box display="flex" justifyContent={'left'} m={1}>
                        <Box flexGrow={1} display="flex" justifyContent={'left'}>
                            <Typography variant="h5" color='block' style={{ color: '#304669', size: '18px' }}>
                                {t("BRANCHCONFIG0018")}
                            </Typography>
                        </Box>
                        <Tooltip arrow title={t('COMMON170')}>
                            <CloseRoundedIcon onClick={handleClose} style={{ color: "#B7B7B7", background: "white", borderRadius: "50%" }} fontSize="small" className='close_icon' />
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <Divider className='MuiDividerCss' />
                <DialogContent justifyContent="center" style={{ marginLeft: '30px', marginRight: '30px' }}  >
                    < Grid container display="flex"
                        justifyContent="center"
                        alignItems="center" >
                        <Grid container spacing={2} p={2} xs={12}>
                            <Grid item xs={5} display='flex' justifyContent="flex-start">{t('COMMON014')}</Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                            <Grid item xs={5} display='flex' justifyContent="flex-start" > <Typography>{requestObj.name}</Typography></Grid>
                        </Grid>
                        <Grid container spacing={2} p={2} xs={12}>
                            <Grid item xs={5} display='flex' justifyContent="flex-start">{t('DEPT009')}</Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                            <Grid item xs={5} justifyContent="flex-start"  ><Typography style={{ width: '255px', wordWrap: 'break-word' }}>{requestObj.description}</Typography> </Grid>
                        </Grid>
                        <Grid container spacing={2} p={2} xs={12}>
                            <Grid item xs={5} display='flex' justifyContent="flex-start">{t('BRANCHCONFIG0004')}</Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                            <Grid item xs={5} display='flex' justifyContent="flex-start" > {requestObj.requestTypeId.name}</Grid>
                        </Grid>
                        <Grid container spacing={2} p={2} xs={12}>
                            <Grid item xs={5} display='flex' justifyContent="flex-start">{t('COMMON001')}</Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                            <Grid item xs={5} display='flex' justifyContent="flex-start" >{requestObj.status}</Grid>
                        </Grid>
                        {props.myRequestPage ? ('') : (
                            <Grid container spacing={2} p={2} xs={12}>
                                <Grid item xs={5} display='flex' justifyContent="flex-start">{t('VRD001')}</Grid>
                                <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                                <Grid item xs={5} display='flex' justifyContent="flex-start" > <Typography>{CommonUtil.getLocalDate(requestObj.requestCreatedAt)}</Typography></Grid>
                            </Grid>
                        )}
                        <Grid container spacing={2} p={2} xs={12}>
                            <Grid item xs={5} display='flex' justifyContent="flex-start">{t('VRD002')}</Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                            <Grid item xs={5} justifyContent="flex-start" >
                                {requestObj.previousApprover?.firstName ? (<span><b>{requestObj.previousApprover.firstName}</b></span>) : 'None'}<br />
                                <span >{requestObj.previousApprover?.email}</span>
                            </Grid>
                        </Grid>
                        {props.myRequestPage ? <> <Grid container spacing={2} p={2} xs={12}>
                            <Grid item xs={5} display='flex' justifyContent="flex-start">{t('VRD003')}</Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                            <Grid item xs={5} justifyContent="flex-start" >
                                {requestObj.nextApprover?.firstName ? (<span><b>{requestObj.nextApprover.firstName}</b></span>) : 'None'} <br />
                                <span>{requestObj.nextApprover?.email}</span>
                            </Grid>
                        </Grid> </> : ('')}
                        {requestObj.status === 'APPROVED' ? (
                            <Grid container spacing={2} p={2} xs={12}>
                                <Grid item xs={5} display='flex' justifyContent="flex-start">{t('VRD004')}</Grid>
                                <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                                <Grid item xs={5} display='flex' justifyContent="flex-start" > <Typography>{CommonUtil.getLocalDate(requestObj.updatedAt)}</Typography></Grid>
                            </Grid>
                        ) : ('')}
                        {requestObj.status === 'REJECTED' ? (
                            <Grid container spacing={2} p={2} xs={12}>
                                <Grid item xs={5} display='flex' justifyContent="flex-start">{t('VRD005')}</Grid>
                                <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                                <Grid item xs={5} display='flex' justifyContent="flex-start" > <Typography>{CommonUtil.getLocalDate(requestObj.updatedAt)}</Typography></Grid>
                            </Grid>
                        ) : ('')}
                        <Grid container spacing={2} p={2} xs={12}>

                            {arrObj.map((data) => {
                                return (
                                    <>
                                        <Grid item xs={5} display='flex' justifyContent="flex-start" className='textbreakk'>{Object.keys(data)[0]}</Grid>
                                        <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                                        {Object.values(data)[1] === "CURRENCY" || Object.keys(data)[0] === "Total Amount" ? (
                                            <Grid item xs={5} display='flex' justifyContent="flex-start" ><Typography style={{ marginRight: "4%" }}>$</Typography>{Object.values(data)[0]}</Grid>
                                        ) : (<Grid item xs={5} display='flex' justifyContent="flex-start" >{Object.values(data)[0]}</Grid>)
                                        }
                                    </>
                                )
                            }
                            )}
                        </Grid>
                        {props.myRequestPage ? <><Grid container spacing={2} p={2} xs={12}>
                            <Grid item xs={5} display='flex' justifyContent="flex-start">{t('COMMON016')}</Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">:</Grid>
                            <Grid item xs={5}>{!CommonUtil.isEmptyString(requestObj.remarks) ? splitByComma(requestObj.remarks) : null}</Grid>
                        </Grid></> : ('')}

                        <Grid container spacing={2} p={2} xs={10} display="flex" justifyContent="left" className='view-Request-Dailog-file-download' mt={1}>
                            <Grid mr={1}><img alt="..." src={fileIcon} width="20px" /></Grid>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    getDownloadLink(props.selectedObject.attachments)
                                }}
                            ><Typography> {t('DWNL02')}</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                </DialogContent>
                {requestObj.auditLogId ? (
                    <DialogActions   >
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center" spacing={2} p={1.25} xl={12} mt={2}>
                            <CustomButton onClick={handleReject} variant="contained" className='m-2' color='#FFFFFF' backgroundColor='#FC5F46' hoverbackgroundColor='#E5482F'> {t("DASHBOARD0007")}</CustomButton>
                            <CustomButton onClick={handleapproved} variant="contained" className='m-2' color='#FFFFFF' backgroundColor='#36C96D' hoverbackgroundColor='#1AB755'>{t("DASHBOARD0006")}</CustomButton>
                        </Grid>
                    </DialogActions>
                ) : ('')}
            </Dialog>
            {approveOrReject && (
                <ApproveOrRejectButtonsDialog
                    selectedObject={selectedObject}
                    open={approveOrReject}
                    title={title}
                    text={text}
                    statuss={statuss}
                    lebal={lebal}
                    onClose={() => {
                        setApproveOrReject(false)
                        setOpen(true);
                    }}
                    submithandleClose={() => {
                        setApproveOrReject(false)
                        props.onClose();
                    }}
                />
            )}
        </>
    );
}

export default ViewRequestDetails