import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ApproveIcon = () => {
    const [isShown, setIsShown] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Tooltip title={t("DASHBOARD0006")}>
                <Grid
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    {isShown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                            <g id="Group_7983" data-name="Group 7983" transform="translate(-1753.531 -242.53)" opacity="1">
                                <path id="Path_6641" data-name="Path 6641" d="M13.5,2A11.5,11.5,0,1,0,25,13.5,11.5,11.5,0,0,0,13.5,2Zm0,20.7a9.2,9.2,0,1,1,9.2-9.2A9.2,9.2,0,0,1,13.5,22.7Z" transform="translate(1751.531 240.531)" fill="#36c96d" opacity="1"/>
                                <path id="Path_6642" data-name="Path 6642" d="M15.855,8.454l-4.386,5.8L9.577,11.807a1.162,1.162,0,1,0-1.833,1.427l2.82,3.609a1.164,1.164,0,0,0,1.833-.012l5.3-6.963a1.167,1.167,0,0,0-1.857-1.416Z" transform="translate(1752.309 241.388)" fill="#36c96d" opacity="1"/>
                            </g>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                            <g id="Group_7983" data-name="Group 7983" transform="translate(-1753.531 -242.53)" opacity="0.9">
                                <path id="Path_6641" data-name="Path 6641" d="M13.5,2A11.5,11.5,0,1,0,25,13.5,11.5,11.5,0,0,0,13.5,2Zm0,20.7a9.2,9.2,0,1,1,9.2-9.2A9.2,9.2,0,0,1,13.5,22.7Z" transform="translate(1751.531 240.531)" fill="#36c96d" opacity="0.9" />
                                <path id="Path_6642" data-name="Path 6642" d="M15.855,8.454l-4.386,5.8L9.577,11.807a1.162,1.162,0,1,0-1.833,1.427l2.82,3.609a1.164,1.164,0,0,0,1.833-.012l5.3-6.963a1.167,1.167,0,0,0-1.857-1.416Z" transform="translate(1752.309 241.388)" fill="#36c96d" opacity="0.9" />
                            </g>
                        </svg>
                    )}
                </Grid>
            </Tooltip>
        </>
    );
};
export default ApproveIcon;
