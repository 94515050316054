import { Box, IconButton, TextField, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteBranchConfiguration, getBranchConfigurationList } from "../../../actions/branchConfiguration/BranchConfigurationActions";
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from "../../../components/ConfirmModalNew";
import DataTable from '../../../components/DataTableNew';
import FilterButton from "../../../components/FilterButtom";
import HeaderToolbar from "../../../components/HeaderTooltip";
import CommonUtil from "../../../components/Util/CommonUtils";
import DeleteIcon from "../../reusableIcons/DeleteIcon";
import EditIcon from "../../reusableIcons/EditIcon";

export default function BranchConfigurations() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [totalPages, setTotalPages] = React.useState(1);
    const store = useSelector((state) => state);
    const breadcrumb = [{ path: '', name: t('BRANCHCONFIG0001') }];
    const [data, setData] = useState([]);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [editMode, setEditMode] = useState(false);
    const [clraefilterButton, setClraefilterButton] = useState(false);
    const [filter, setFilter] = useState({
        branchName: '',
        requestTypeName: '',
    });
    //eslint-disable-next-line
    const columns = React.useMemo(() => [
        {
            field: "approvalCriteria",
            headerName: t('BRANCHCONFIG0011'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.approvalCriteria,
            renderCell: (params) => (
                <Tooltip title={params.row.approvalCriteria} >
                    <span className="table-cell-trucate">{params.row.approvalCriteria}</span>
                </Tooltip>
            ),
        },
        {
            field: 'companyId.name',
            headerName: t('BRANCHCONFIG0014'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.companyId.name,
            renderCell: (params) => (
                <Tooltip title={params.row.companyId.name} >
                    <span className="table-cell-trucate">{params.row.companyId.name}</span>
                </Tooltip>
            ),
        },
        {
            field: 'requestTypeId.name',
            headerName: t('BRANCHCONFIG0015'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.requestTypeId.name,
            renderCell: (params) => (
                <Tooltip title={params.row.requestTypeId.name} >
                    <span className="table-cell-trucate">{params.row.requestTypeId.name}</span>
                </Tooltip>
            ),
        },
        {
            field: "Actions",
            headerName: t('dataTableAction'),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    onClick={() => openEditBranchConfPage(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => deleteRecord(params.row)}
                />,
            ],
        },
    ]);

    useEffect(() => {
        loadData(page, rowsPerPage, filter);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.branchConfiguration.branchConfigurationList)) {
            setPage(store.branchConfiguration.branchConfigurationList.currentPage - 1);
            setData(store.branchConfiguration.branchConfigurationList.branch_configurations);
            setTotalPages(store.branchConfiguration.branchConfigurationList.totalPages)
        } else {
            setTotalPages(0)
            setPage(0);
            setData([]);
        }

    }, [store.branchConfiguration.branchConfigurationList]);

    const openCreatebranchf = () => {
        history.push
            ({
                pathname: '/fams/create-branchConfiguration',
                state: '',
            });
    };
    const openEditBranchConfPage = (data) => {
        history.push
            ({
                pathname: '/fams/create-branchConfiguration',
                state: data,
            });
    };

    const handleChanges = (newPage, size) => {
        setRowsPerPage(size);
        setPage(newPage);
        loadData(newPage, size, filter);
    };

    const loadData = (page, rowsPerPage, filter) => {
        dispatch(getBranchConfigurationList(page, rowsPerPage, filter));
    };

    const deleteRecord = (data) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmModal
                        open={true}
                        title={t('COMMON004')}
                        text={t('COMMON018')}
                        onConfirm={() => {
                            dispatch(
                                deleteBranchConfiguration(data.id,
                                    (data) => {
                                        loadData(0, rowsPerPage, filter);
                                        toast.success(t('BRANCHCONFIG0016'));
                                    },
                                    (data) => {
                                        toast.error(data.message)
                                    }
                                ));
                            onClose();
                        }}
                        onClose={() => onClose()}
                    />
                );
            },
        });
    }
    const handleChange = (event) => {
        const name = event.target.name;
        setFilter({
            ...filter,
            [name]: event.target.value,
        });
    };
    const handleCancel = () => {
        setFilter({
            branchName: '',
            requestTypeName: '',
        });
        setEditMode(!editMode);
    };

    const clearFilter = () => {
        setFilter({
            branchName: '',
            requestTypeName: '',
        });
        setClraefilterButton(false);
        loadData(1, rowsPerPage, '');

    }
    const handleClose = () => {
        setEditMode(false)
    };
    const reset = () => {
        if (filter.branchName.trim() === '' && filter.requestTypeName.trim() === '') {
            toast.error(t('COMMON027'));
            return;
        }
        setFilter({
            branchName: '',
            requestTypeName: '',
        });
    };

    const handlefilter = () => {
        if (filter.branchName.trim() === '' && filter.requestTypeName.trim() === '') {
            toast.error(t('COMMON015'));
            return;
        }
        loadData(1, rowsPerPage, filter);
        setClraefilterButton(true);
        handleClose();
    };
    return (
        <Grid>
            <Breadcrumb links={breadcrumb}>
                <Box p={1} alignItems="center" style={{ alignSelf: 'center' }}>
                    {clraefilterButton === false ?
                        <div >
                            <Tooltip arrow title={t('COMMON006')}>
                                <IconButton size='small' variant='contained' color='secondary' onClick={() => handleCancel()}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </div> : ''}
                </Box>
                <HeaderToolbar src={AddIcon} onClick={openCreatebranchf} tooltipTitle={t('COMMON009')} size='large' />
            </Breadcrumb>
            {editMode ? <>
                <Box style={{ boxShadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%)', backgroundColor: '#FFFFFF', marginBottom: '13px' }} display='flex'>
                    <Grid container xs={12} sm={12} >
                        <Grid item sm={3}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <TextField fullWidth name='branchName' label={t('BRANCHCONFIG0014')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.branchName} />
                            </Box>
                        </Grid>
                        <Grid item sm={3} >
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <TextField fullWidth name='requestTypeName' label={t('BRANCHCONFIG0015')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.requestTypeName} />
                            </Box>
                        </Grid>

                        <Grid item sm={2}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <FilterButton onClick={handlefilter} color="primary" variant="contained" className='mt-2' backgroundColor={'#36C96D'} hoverbackgroundColor={'#1AB755'}>
                                    {t("APPLY001")}
                                </FilterButton>
                            </Box>
                        </Grid>

                        <Grid item sm={2}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <FilterButton onClick={reset} variant="outlined" className='mt-2' borderColor={'#36C96D'} hoverborderColor={'#1AB755'} color={'#36C96D'} hovercolor={'#1AB755'} >
                                    {t("CLEAR001")}
                                </FilterButton>
                            </Box>
                        </Grid>

                    </Grid>
                    <Box marginTop='0px'>
                        <Tooltip arrow title={t('COMMON170')}>
                            <IconButton size='small' variant='contained' color='primary' onClick={() => handleCancel()}>
                                <span className='btn-wrapper--icon rounded'>
                                    <CloseIcon style={{ color: '#B7B7B7' }} />
                                </span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </> : <>
                {clraefilterButton ?
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                            <FilterButton onClick={clearFilter} variant="outlined" className='m-2' borderColor={'#FC5F4699'} hoverborderColor={'#E5482F99'} hoverbackgroundColor={'#FC5F4608'} color={'#FC5F46'} hovercolor={'#E5482F'} >
                                {t("ClearFilters001")}
                            </FilterButton>
                        </Box>
                    </Grid> : ''}
            </>
            }
            <div style={{ height: '100%', width: "100%" }}>
                <DataTable
                    columns={columns}
                    rows={data}
                    page={page}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    handleChange={(newPage, size) => handleChanges(newPage, size)}
                />
            </div>
        </Grid>
    )
}