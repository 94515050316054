import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const CancelIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("COMMON008")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
              <g id="Group_7984" data-name="Group 7984" transform="translate(-1814 -243)" opacity="1">
                <path id="Path_6645" data-name="Path 6645" d="M13.5,2A11.5,11.5,0,1,0,25,13.5,11.5,11.5,0,0,0,13.5,2Zm0,20.7a9.2,9.2,0,1,1,9.2-9.2A9.2,9.2,0,0,1,13.5,22.7Z" transform="translate(1812 241)" fill="#fc5f46" opacity="1"/>
                <path id="Path_6646" data-name="Path 6646" d="M15.067,9.308a1.062,1.062,0,0,0-1.509,0L12.188,10.69,10.817,9.308a1.067,1.067,0,0,0-1.509,1.509l1.381,1.371L9.308,13.558a1.067,1.067,0,1,0,1.509,1.509l1.371-1.381,1.371,1.381a1.067,1.067,0,1,0,1.509-1.509l-1.381-1.371,1.381-1.371a1.062,1.062,0,0,0,0-1.509Z" transform="translate(1813.313 242.312)" fill="#fc5f46" opacity="1"/>
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
              <g id="Group_7984" data-name="Group 7984" transform="translate(-1814 -243)" opacity="1">
                <path id="Path_6645" data-name="Path 6645" d="M13.5,2A11.5,11.5,0,1,0,25,13.5,11.5,11.5,0,0,0,13.5,2Zm0,20.7a9.2,9.2,0,1,1,9.2-9.2A9.2,9.2,0,0,1,13.5,22.7Z" transform="translate(1812 241)" fill="#fc5f46" opacity="0.9" />
                <path id="Path_6646" data-name="Path 6646" d="M15.067,9.308a1.062,1.062,0,0,0-1.509,0L12.188,10.69,10.817,9.308a1.067,1.067,0,0,0-1.509,1.509l1.381,1.371L9.308,13.558a1.067,1.067,0,1,0,1.509,1.509l1.371-1.381,1.371,1.381a1.067,1.067,0,1,0,1.509-1.509l-1.381-1.371,1.381-1.371a1.062,1.062,0,0,0,0-1.509Z" transform="translate(1813.313 242.312)" fill="#fc5f46" opacity="0.9" />
              </g>
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default CancelIcon;
