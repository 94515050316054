import { Box, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URI_MY_REQUESTS } from '../../actions/EndPoints';
import { addRequest } from '../../actions/myRequests/MyRequestsActions';
import { getRequestTypeList } from '../../actions/requestType/RequestTypeActions';
import { getTemplateItem } from '../../actions/templateItems/TemplateItemsActions';
import { getTemplates } from '../../actions/templates/TemplatesActions';
import { ReactComponent as Uploadfilsname } from '../../assets/images/myrequest/file.svg';
import Breadcrumb from '../../components/Breadcrumb';
import CancelButtons from '../../components/CancelButtons';
import CustomButton from '../../components/CustomButton';
import SubmitButtons from '../../components/SubmitButtons';
import CommonUtil from '../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: 'auto',
    },
}));

export default function AddMyRequest(props) {
    const { t } = useTranslation();
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const [templates, setTemplates] = React.useState([]);
    const [templatesList, setTemplatesList] = React.useState([]);
    const [templatesfilyterList, setTemplatesfilyterList] = React.useState([]);
    const [tamplatesss, setTamplatesss] = React.useState([]);
    const [requestTypes, setRequestTypes] = React.useState([]);
    const [requestTypesList, setRequestTypesList] = React.useState([]);
    const [templateItems, setTemplateItems] = React.useState([]);
    const [templateItemList, setTemplateItemList] = React.useState([]);
    const [file, setFile] = React.useState("");
    const classes = useStyles();
    const history = useHistory();
    const [fileName, setFileName] = React.useState("");
    const [templateItemsReq, setTemplateItemsReq] = React.useState([]);
    const [flag, setFlag] = React.useState(true);
    const [state, setState] = React.useState({
        id: '',
        requestTypeId: '',
        templateId: '',
        name: '',
        description: '',
    });

    const [error, setError] = React.useState({
        requestTypeId: false,
        templateId: false,
        name: false,
    });
    const isRequired = (state.requestTypeId.name !== 'HR' && state.requestTypeId.name !== undefined);
    let totalItemsAmount = 0;
    const getTotalCosts = () => {
        return templateItemsReq.reduce((total, item) => {
            if (Object.keys(item)[0] === 'Total Amount' || Object.values(item)[1] === 'TEXT' || Object.values(item)[1] === 'INTEGER') {

            } else {
                totalItemsAmount = total + Number(Object.values(item)[0])
            }
            return totalItemsAmount;
        }, 0);
    };

    useEffect(() => {
        loadRequestTypes();
        //eslint-disable-next-line
    }, []);

    const loadRequestTypes = () => {
        dispatch(getRequestTypeList(1, 0, ''));
    }

    useEffect(() => {
        loadtempleteList();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        loadTemplteItemList();
        //eslint-disable-next-line
    }, []);
    useEffect(()=>{
        if(templates.length>0&&templateItemList.length>0){
          const templatesList = templates.filter(templt => {    
                return templateItemList.some(templtit => templt.id === templtit.templateId.id)
            })
            setTemplatesfilyterList(templatesList)
    }
    //eslint-disable-next-line    
  },[templateItemList,templates])

      //Request Type exist then returns templete
    useEffect(()=>{
        if(tamplatesss.length>0&&requestTypes.length>0){
          const filterrequestsstempl = requestTypes.filter(reqs => {    
                return tamplatesss.some(temp => reqs.id === temp.requestTypeId.id&&temp.status==='ACTIVE')
            })
            setRequestTypesList(filterrequestsstempl)
    }
    //eslint-disable-next-line    
  },[tamplatesss])

  //Templete item exist then returns templete
    useEffect(()=>{
        if(templatesList.length>0&&templateItemList.length>0){
          const templatesLists = templatesList.filter(temp => {    
                return templateItemList.some(tempit => temp.id === tempit.templateId.id)
            })
            setTamplatesss(templatesLists)
    }
    //eslint-disable-next-line    
  },[templatesList,templateItemList])
      
    const loadtempleteList= () => {
        dispatch(getTemplates(1, 0, '', (resp) => { 
            setTemplatesList(resp.data.templates);
        }, () => {
            setTemplatesList([]);
        }));
    }
    
    const loadTemplates = (reqId) => {
        const filter = {
            code: '',
            name: '',
            version: '',
            company: '',
            requestTypeId: reqId,
            description: '',
        }
        dispatch(getTemplates(1, 0, filter, (resp) => {
            setTemplates(resp.data.templates);
        }, () => {
            setTemplates([]);
        }));
    };
    const loadTemplteItemList = () => {
        dispatch(getTemplateItem(1, 0, '', (data) => {
            setTemplateItemList(data.data.template_items);
        },
            () => {
                setTemplateItemList([]);
            }
        ));
    };
    const loadTemplteItems = (tempId) => {
        const filterTempItems = {
            code: '',
            name: '',
            valueType: '',
            templateId: tempId,
        }

        dispatch(getTemplateItem(1, 0, filterTempItems, (data) => {
            setTemplateItems(data.data.template_items);
        },
            () => {
                setTemplateItems([]);
            }
        ));
    };

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.requestType.requestTypeList)) {
            setRequestTypes(store.requestType.requestTypeList.request_types);
        } else {
            setRequestTypes([]);
        }
    }, [store.requestType.requestTypeList]);

    const handleChange = (event, objectData) => {
        const name = event.target.name;
        if (name !== "file" && name !== "name" && name !== "description") {
            if (event.target.value === '') {
                // eslint-disable-next-line
                templateItemsReq.map((data, index) => {
                    if (Object.keys(data)[0] === event.target.name) {
                        templateItemsReq.splice(index, 1);// eslint-disable-next-line
                        return;
                    }
                })
            } else {// eslint-disable-next-line
                templateItemsReq.length > 0 ? templateItemsReq.map((data, index) => {

                    if (Object.keys(data)[0] === event.target.name) {
                        templateItemsReq.splice(index, 1);
                        templateItemsReq.push({ [name]: event.target.value, "valueType": objectData.valueType });// eslint-disable-next-line
                        return;
                    }
                    else {
                        let keyFind = false;
                        for (var i = 0; i < templateItemsReq.length; i++) {
                            if (Object.keys(templateItemsReq[i])[0] === event.target.name) {
                                keyFind = true;
                            }
                        }
                        if (keyFind === false) {
                            templateItemsReq.push({ [name]: event.target.value, "valueType": objectData.valueType });
                        }
                    }
                })
                    : templateItemsReq.push({ [name]: event.target.value, "valueType": objectData.valueType });
            }
        }

        if (event.target.name === "file") {
            setFile(event.target.files[0])
            setFileName(event.target.files[0].name)
        }
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };

    const handleClose = () => {
        setTemplates([]);
        history.push(REACT_URI_MY_REQUESTS)
    };
    const breadcrumb = [
        { path: REACT_URI_MY_REQUESTS, name: t('MYREQ0001') },
        { path: '/fams/create-myrequest', name: props.history.location.state?.id === undefined ? t("MYREQ0002") : t("MYREQ0002") },
    ];

    const handleSubmit = (statusVal) => {
        if (templateItemsReq.length > 0 && flag && isRequired) {
            templateItemsReq.push({ 'Total Amount': totalItemsAmount });
        }
        if (CommonUtil.isEmptyString(state.name)) {
            setError({ name: true });
            toast.error(t('MYRQST001'))
        } else if (!CommonUtil.validateName(state.name)) {
            setError({ name: true })
            toast.error(t("nameValidation"))
        } else if (CommonUtil.checkCharactersGreaterThan50(state.name)) {
            setError({ name: true })
            toast.error(t("NAMELENGTH"))
        } else if (CommonUtil.isEmptyString(state.requestTypeId)) {
            setError({ requestTypeId: true });
            toast.error(t('BRANCHCONFIG0006'))
        } else if (CommonUtil.isEmptyString(state.templateId)) {
            setError({ templateId: true });
            toast.error(t('TEMPLATEITEMS0006'))
        } else if (CommonUtil.isEmptyString(file) && (statusVal === 'RAISED')) {
            toast.error(t('MYREQ0005'))
        } else if (CommonUtil.isEmpty(templateItemsReq)) {
            toast.error(t('MYREQ0004'))
        } else {
            const formData = new FormData();
            formData.append('file', file);
            const payload = {
                name: state.name,
                requestTypeId: state.requestTypeId.id,
                templateId: state.templateId.id,
                requestDetails: encodeURIComponent(JSON.stringify(templateItemsReq)),
                description: state.description,
                status: statusVal,
            };
            setFlag(false);
            dispatch(addRequest(formData, payload,
                (resp) => {
                    handleClose();
                    toast.success(resp.message);
                },
                (error) => {
                    setFlag(true);
                    onFail(error);
                }
            ));
        }
    }

    const onFail = (error) => {
        if (error.code) {
            switch (error) {
                case '':
                    setError({});
                    break;
                case 'FHRMSE0040':
                    setError();
                    break;
                default:
                    setError({ ...error });
            }
            toast.error(error.message);
        }
    }
    useEffect(() => {
        if (!CommonUtil.isEmpty(props.history.location.state)) {
            setState({
                id: props.history.location.state ? props.history.location.state?.id : '',
                name: '',
                templateId: props.history.location.state.templateId ? props.history.location.state?.templateId : '',
                requestTypeId: props.history.location.state.requestTypeId ? props.history.location.state?.requestTypeId : "",
                description: '',
                file: props.history.location.state ? props.history.location.state?.attachments : '',
            });
            loadTemplates(props.history.location.state.requestTypeId ? props.history.location.state?.requestTypeId.id : '');
            loadTemplteItems(props.history.location.state.templateId ? props.history.location.state?.templateId.id : '')
        }
        //eslint-disable-next-line
    }, [props.history.location.state]);

    const handleReqTypeChange = (event, value) => {
        if (value.id !== state.requestTypeId.id) {
            setState({ ...state, templateId: "", requestTypeId: value });
            loadTemplates(value.id);
            setTemplateItems([]);
            setTemplateItemsReq([]);
        }
    }

    const handleTemplateChange = (event, value) => {
        setState({ ...state, templateId: value });
        loadTemplteItems(value.id);
    }

    return (
        <>
            <Grid  >
                <Breadcrumb links={breadcrumb} />
                <Grid fullWidth onClose={handleClose} className={`${classes.customContainer} background-size`}>
                    <Grid container sx={12} xs={12} alignItems="center">
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.name}
                                    name="name"
                                    label={t("COMMON044") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.name}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.description}
                                    name="description"
                                    label={t("DEPT009")}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.description}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 250 }}
                                />
                            </Box>
                        </Grid>
                        <Grid container sx={12} xs={12} alignItems="center">
                            <Grid item xs={12} sm={12} lg={6}>
                                <Box display='flex' justifyContent='center' m={1} p={1}>
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        openText={t('COMMON169')}
                                        closeText={t('COMMON170')}
                                        clearText={t('COMMON215')}
                                        noOptionsText={t('COMMON214')}
                                        options={requestTypesList}
                                        name={state.requestTypeId}
                                        value={state.requestTypeId}
                                        disabled={props.history.location.state ? true : false}
                                        getOptionLabel={(reqType) => reqType.name?reqType.name:''}
                                        onChange={(event, newValue) => {
                                            if (!CommonUtil.isEmpty(newValue)) {
                                                handleReqTypeChange(event, newValue);
                                            } else {
                                                setState({ ...state, requestTypeId: '', templateId: '' });
                                                setTemplates([]);
                                                setTemplateItems([]);
                                                setTemplateItemsReq([]);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} value={state.requestTypeId} name={state.requestTypeId} error={error.requestTypeId} variant='outlined' size="medium" fullWidth label={t('BRANCHCONFIG0004') + '*'} />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <Box display='flex' justifyContent='center' m={1} p={1}>
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        openText={t('COMMON169')}
                                        closeText={t('COMMON170')}
                                        clearText={t('COMMON215')}
                                        noOptionsText={t('COMMON214')}
                                        options={templatesfilyterList.filter(person => person.status === 'ACTIVE')}
                                        name={state.templateId}
                                        value={state.templateId}
                                        getOptionLabel={(template) => template.name?template.name:''}
                                        onChange={(event, newValue) => {
                                            if (!CommonUtil.isEmpty(newValue)) {
                                                handleTemplateChange(event, newValue);
                                            } else {
                                                setState({ ...state, templateId: '' });
                                                setTemplateItems([]);
                                                setTemplateItemsReq([]);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} name={state.templateId} value={state.templateId} error={error.templateId} variant='outlined' size="medium" fullWidth label={t('TEMPLATEITEMS0004') + '*'} />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container sx={12} xs={12} alignItems="center">
                            {templateItems?.map((item, index) => (
                                <Grid container key={index} spacing={1}>
                                    <Grid item xs={4} lg={4}>
                                        <div className='p-4' variant="outlined" >
                                            <Typography style={{ variant: 'outlined', color: 'blue', wordWrap: 'break-word' }}>{item.name + ":"}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={8} lg={8}>
                                        <div className='p-3'>
                                            <TextField
                                                autoComplete='off'
                                                index={index}
                                                className='m-0 mb-2'
                                                size='medium'
                                                InputProps={item.valueType === 'CURRENCY' ? {
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                } : null}
                                                variant='outlined'
                                                helperText=''
                                                type={item.valueType === 'TEXT' ? 'text' : 'number'}
                                                name={item.name}
                                                fullWidth
                                                value={templateItemsReq.name}
                                                onChange={(e) => handleChange(e, item)}
                                                inputProps={{
                                                    maxlength: 100,
                                                }}
                                                onKeyDown={(e) => {
                                                    if ('ArrowUp' === e.key || 'ArrowDown' === e.key) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onWheel={e => {
                                                    e.target.blur();
                                                }}
                                                onInput={(e) => {
                                                    if (item.valueType === 'CURRENCY' || item.valueType === 'INTEGER') {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                            {isRequired &&
                                <Grid container
                                    direction="row"
                                    justifyContent="space-evenly"
                                >
                                    <Box className="table-data">
                                        Total
                                    </Box>
                                    <Box className="table-data" >
                                        <div>$  {getTotalCosts()}</div>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                        <Grid container sx={12} xs={12} alignItems="center">
                            {isRequired ?
                                <Grid item xs={12} lg={6} m={0}>
                                    <Grid className='border-download' >
                                        <Box textAlign='center'>
                                            <CustomButton variant="outlined" fullWidth style={{ width: '60%', height: '50px', margin: '15px' }} borderColor='#1492E640' backgroundColor='#1492E60D' hoverbackgroundColor='#1492E61A' className='MuiButtonBase-rootss'>
                                                <GetApp style={{ color: '#1492E6CC' }} />
                                                <p><a href="TestFile/ExpenseDetails.xlsx" download nowrap> {t('ClickDownload')}</a></p>
                                            </CustomButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                                : ''
                            }
                            {isRequired ?
                                <Grid item xs={12} lg={6} m={0}>
                                    <Grid className='border-download' >
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            {state.file && flag === true ?
                                                <CustomButton variant="outlined" fullWidth style={{ width: '40%', height: '50px', margin: '15px' }} borderColor='#30466908' backgroundColor='#30466908' hoverbackgroundColor='#30466908' className='MuiButtonBase-rootss'>
                                                    <Uploadfilsname />
                                                    <Typography className='textfieldhidetext' style={{ marginLeft: '10px' }}>{fileName}</Typography>
                                                </CustomButton> : ''}
                                            <CustomButton variant="outlined" fullWidth style={{ width: '35%', height: '50px', margin: '15px' }} borderColor='#36C96D4D' backgroundColor='#34C36A0D' hoverbackgroundColor='#34C36A1A' className='MuiButtonBase-rootss'>
                                                <IconButton variant='contained' component='label' style={{ color: '#36C96DCC', fontSize: '14px' }}>
                                                    <FileUploadIcon style={{ color: '#36C96D' }} />
                                                    <div style={{ display: 'none' }}>
                                                        <input type="file" name="file" id="fileToUpload" onChange={handleChange} style={{ marginLeft: '9px', wordWrap: 'break-word', width: '100%' }} />
                                                    </div>
                                                    <span>{t('UploadFile')}</span>
                                                </IconButton>
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                </Grid> : <Grid item xs={12} lg={12} m={0}>
                                    <Grid className='border-download' >
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            {state.file && flag === true ?
                                                <CustomButton variant="outlined" fullWidth style={{ width: '40%', height: '50px', margin: '15px' }} borderColor='#30466908' backgroundColor='#30466908' hoverbackgroundColor='#30466908' className='MuiButtonBase-rootss'>
                                                    <Uploadfilsname />
                                                    <Typography className='textfieldhidetext' style={{ marginLeft: '10px' }}>{fileName}</Typography>
                                                </CustomButton> : ''}
                                            <CustomButton variant="outlined" fullWidth style={{ width: '35%', height: '50px', margin: '15px' }} borderColor='#36C96D4D' backgroundColor='#34C36A0D' hoverbackgroundColor='#34C36A1A' className='MuiButtonBase-rootss'>
                                                <IconButton variant='contained' component='label' style={{ color: '#36C96DCC', fontSize: '14px' }}>
                                                    <FileUploadIcon style={{ color: '#36C96D' }} />
                                                    <div style={{ display: 'none' }}>
                                                        <input type="file" name="file" id="fileToUpload" onChange={handleChange} style={{ marginLeft: '9px', wordWrap: 'break-word', width: '100%' }} />
                                                    </div>
                                                    <span>{t('UploadFile')}</span>
                                                </IconButton>
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                    <p style={{ color: 'green', paddingTop: '15px', paddingLeft: '15px' }}><i>{t('UPLD01')}</i></p>
                                </Grid>}
                            <Grid item xs={12} lg={6} m={0}>
                                {isRequired ?
                                    <p style={{ color: 'green', paddingTop: '15px', paddingLeft: '15px' }}><i>{t('DWNL01')}</i></p>
                                    : ''}
                            </Grid>
                            <Grid item xs={12} lg={6} m={0}>
                                {isRequired ?
                                    <p style={{ color: 'green', paddingTop: '15px', paddingLeft: '15px' }}><i>{t('UPLD01')}</i></p> : ''}
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Box display="flex" m={1} p={1}>
                                <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
                                    {t("COMMON008")}
                                </CancelButtons>
                                <SubmitButtons onClick={() => handleSubmit("DRAFT")} /* disabled={state.file && flag === true ? false : true}*/ color="primary" variant="contained" className='m-2'>
                                    {t("COMMON194")}
                                </SubmitButtons>
                                <SubmitButtons onClick={() => handleSubmit("RAISED")} disabled={state.file && flag === true ? false : true} color="primary" variant="contained" className='m-2'>
                                    {t("COMMON035")}
                                </SubmitButtons>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
} 
