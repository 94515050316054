export const JAVA_API_REQUEST_TYPE = "/api/v1/request_types";

export const JAVA_API_REQUEST_TYPE_LIST = "/api/v1/request_types";

export const JAVA_API_BRANCH_CONFIGURATION = "/api/v1/branch_configurations";

export const JAVA_API_TEMPLATES = "/api/v1/templates";

export const JAVA_API_TEMPLATES_LIST = "/api/v1/templates";

export const JAVA_API_BRANCH_CONFIGURATION_LIST = "/api/v1/branch_configurations";

export const JAVA_API_TEMPLATE_ITEMS = "/api/v1/template_items";

export const JAVA_API_TEMPLATE_ITEMS_LIST = "/api/v1/template_items";

export const JAVA_API_APPROVAL_WORKFLOW = "/api/v1/approval_workflows";

export const JAVA_API_APPROVAL_WORKFLOW_LIST = "/api/v1/approval_workflows";

export const JAVA_API_BRANCH = '/user/usercompany';
export const JAVA_API_BRANCH_OWNER_CHANGE = '/user/{userId}/ownerChange';
export const JAVA_API_WORKFLOW_NODE = "/api/v1/workflow_nodes"
export const JAVA_API_WORKFLOW_NODE_LIST = "/api/v1/workflow_nodes"
export const JAVA_API_APPROVAL_CONDITION = "/api/v1/approval_conditions";

export const JAVA_API_APPROVAL_CONDITION_LIST = "/api/v1/approval_conditions"

export const JAVA_API_MY_REQUESTS = "/api/v1/requests";

export const JAVA_API_EMPLOYEE_LIST = "http://10.10.10.142:9096/api/v2.0/emp"


export const JAVA_API_MY_REQUESTS_LIST = "/api/v1/requests/status";

export const JAVA_API_EMPLOYEE_LIST_USER_ID = "/employee";

export const JAVA_API_FILE_DOWNLOAD = JAVA_API_MY_REQUESTS + "/download"

export const JAVA_API_REQUESTS_STATUS_COUNTS = JAVA_API_MY_REQUESTS + "/status_count"
