import { makeStyles, Paper } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyLogo from "../../src/assets/images/empty_item.svg";
import Loader from "./Util/Loader";
const useStyles = makeStyles((theme) => ({
  custompagination: {
    height: 800,
    width: "100%",
    textAlign: "center",
    boxShadow: "0px 3px 8px #00000029",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "10px",
    opacity: 1,
    [theme.breakpoints.down("lg")]: {
      height: 450,
      width: "100%",
      textAlign: "center",
      boxShadow: "0px 3px 8px #00000029",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      borderRadius: "10px",
      opacity: 1,
    },
  }
}));
function CustomToolbar() {
  return (
    <GridToolbarContainer style={{display:'flex',justifyContent:'end'}}>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} style={{ color: '#36C96D' }} />
    </GridToolbarContainer>
  );
}
function DataTable(props) {
  const { t } = useTranslation();
  const { handleChange } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowChange = () => {
    var e = document.getElementById("pagesize");
    handleChange(1, e.value);
    setRowsPerPage(e.value);

  };

  const handlePageChange = (event, value) => {
    handleChange(value, rowsPerPage);
  };
  return (
    <>
      <Loader />
      <Paper>
        <div
          className={classes.custompagination}
        >
          {props.rows.length > 0 ? null : (
            <img alt="no record found"
              src={EmptyLogo}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
          )}

          {props.rows.length > 0 ? null : (
            <span
              style={{
                color: "#3D4977",
                opacity: "0.5",
                position: "absolute",
                paddingTop: 160,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              {t('dataTableEmptyList')}
            </span>
          )}
          <DataGrid
            rows={props.rows}
            hight={props.hight}
            pageSize={rowsPerPage}
            checkboxSelection={props.checkboxSelection}
            disableSelectionOnClick
            hideFooterPagination
            hideFooter={props.rows.length > 0 ? false : true}
            disableColumnMenu
            getRowId={props.rowId}
            columns={props.columns.map((column) => ({
              ...column,
              sortable: false,
            }))}
            defaultPage={1}
            localeText={{ columnHeaderSortIconLabel: t('SRT011') }}
            components={{ Toolbar: CustomToolbar }}
            sx={[
              () => ({
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F4F6F8",
                },
              }),
              () => ({
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
              }),
              () => ({
                "& .MuiDataGrid-overlay": {
                  display: "none",
                },
              }),
            ]}
          />
          <>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                position: "relative",
                bottom: 40,
                justifyContent: "flex-end",
              }}
            >
              {props.rows.length > 0 ? (
                <>
                  <label
                    for="records"
                    style={{ paddingRight: 5, paddingTop: 3.5, color: '#304669' }}
                  >
                    {t('COMMON203')}{" "}
                  </label>
                  <select
                    id="pagesize"
                    style={{
                      padding: 6,
                      paddingBottom: 5,
                      paddingTop: 5,
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "0.5px solid #CCCCCC",
                      borderRadius: "3px",
                      opacity: 1,
                      backgroundColor: '#fff',
                      color: '#304669'
                    }}
                    onChange={handleRowChange}
                    value={rowsPerPage}
                  >
                    <option value={5} >5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                  </select>
                  <p style={{ paddingTop: 3.5, color: '#304669' }}> {t('COMMON204')}</p>
                </>
              ) : null}
              <Pagination
                hidden={props.rows.length > 0 ? false : true}
                count={props.pageCount}
                onChange={handlePageChange}
              />
            </Stack>
          </>
        </div>
      </Paper>
    </>
  );
}

export default DataTable;

DataTable.defaultProps = {
  columns: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "First name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "fullName",
      headerName: "Full name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
  ],

  rows: [],
  checkboxSelection: false,
  pageCount: 10,
};
