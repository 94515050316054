import axios from "axios";
import { JAVA_API_BRANCH, JAVA_API_BRANCH_OWNER_CHANGE } from "../BackendEndPoints";

export const createOwnerUser = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_BRANCH, payload);
    if (res.status === 200 && res.data.code === "UASI0006") {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) { }
};

export const changeOwner = (payload, userId, onSuccess, onFailure) => async (dispatch) => {
  let url = JAVA_API_BRANCH_OWNER_CHANGE.replace("{userId}", userId);
  try {
    const res = await axios.put(url, payload);
    if (res.status === 200 && res.data.code === "FHRMSI0006") {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) { }
};

