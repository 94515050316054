import { Tooltip, Typography } from "@material-ui/core";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { deleteWorkflowNodeType, getWorkflowNodeList } from '../../../actions/WorkflowNode/WorkflowNodeActions';
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from '../../../components/ConfirmModalNew';
import DataTable from '../../../components/DataTableNew';
import HeaderToolbar from '../../../components/HeaderTooltip';
import CommonUtil from '../../../components/Util/CommonUtils';
import DeleteIcon from "../../reusableIcons/DeleteIcon";
import EditIcon from "../../reusableIcons/EditIcon";

export default function WorkflowNode() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    loadData(page, rowsPerPage);
    //eslint-disable-next-line
  }, []);

  //workflowNodeList
  useEffect(() => {
    if (!CommonUtil.isEmpty(store.workflowNode.workflowNodeList)) {
      setPage(store.workflowNode.workflowNodeList.currentPage - 1);
      setData(store.workflowNode.workflowNodeList.workflow_nodes);
      setTotalPages(store.workflowNode.workflowNodeList.totalPages)
    } else {
      setPage(0);
      setData([]);
    }

  }, [store.workflowNode.workflowNodeList]);
  const loadData = (page, rowsPerPage) => {
    dispatch(getWorkflowNodeList(page, rowsPerPage, '', ''));
  };
  const handleChangePage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size);
  };

  const breadcrumb = [{ path: '', name: t('APPROAVWORKFLNODEOW0007') }];
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: "code",
      headerName: t('WORKFLOWNODE0001'),
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.code} >
          <span className="table-cell-trucate">{params.row.code}</span>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: t('WORKFLOWNODE0002'),
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.name} >
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "nodeSequence",
      headerName: t('APPROAVWORKFLNODEOW0001'),
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.nodeSequence} >
          <span className="table-cell-trucate">{params.row.nodeSequence}</span>
        </Tooltip>
      ),
    },
    {
      field: "description",
      headerName: t('RestrictTypeForm005'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.description} >
          <span className="table-cell-trucate">{params.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: "approvalWorkflowId.name",
      headerName: t('APPROVALCONDITION0008'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.approvalWorkflowId.code,
      renderCell: (params) => (
        <Tooltip title={params.row.approvalWorkflowId.code} >
          <span className="table-cell-trucate">{params.row.approvalWorkflowId.code}</span>
        </Tooltip>
      ),
    },
    {
      field: "employeeId.email",
      headerName: t('APPROVER0001'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.employeeId.firstName + '\n' + params.row.employeeId.email,
      renderCell: (params) => (
        <Tooltip title={params.row.employeeId.firstName + '\n' + params.row.employeeId.email} >
          <div lassName="table-cell-trucate">
            <Typography className="table-cell-trucate" style={{ display: 'flex', margin: '0px 0px', padding: '0px 0px', fontWeight: 'bold' }}>{params.row.employeeId.firstName}</Typography>
            <Typography className="table-cell-trucate" style={{ fontSize: '12px' }}>{params.row.employeeId.email}</Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => EditWorkflowNode(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => deleteRecord(params.row)}
        />,
      ],
    },
  ]);
  const CreateWorkflowNode = () => {
    history.push
      ({
        pathname: '/fams/create-WorkflowNode',
        state: data,
      });
  };
  const EditWorkflowNode = (data) => {
    history.push
      ({
        pathname: '/fams/create-WorkflowNode',
        state: data,
      });
  };

  const deleteRecord = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            title={t('COMMON004')}
            onConfirm={() => {
              dispatch(
                deleteWorkflowNodeType(data.id,
                  (data) => {
                    toast.success(t('APPROAVWORKFLNODEOW0013'));
                    loadData(0, rowsPerPage);
                  },
                  (data) => {
                    switch (data.code) {
                      case "FHRMSE0054":
                        toast.error(t("WORKFLOWNODEEDELETE"));
                        break;
                      default:
                        toast.error(data.message);
                    }
                  }
                ));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  }

  return (
    <>
      <Breadcrumb links={breadcrumb}>
        <HeaderToolbar src={AddIcon} onClick={CreateWorkflowNode} tooltipTitle={t('COMMON009')} size='large' />
      </Breadcrumb>
      <DataTable
        columns={columns}
        rows={data}
        page={page}
        pageCount={totalPages}
        pageSize={rowsPerPage}
        handleChange={(newPage, size) => handleChangePage(newPage, size)}
      />
    </>
  );
}
