import axios from 'axios';

import { JAVA_API_DESIGNATION } from './ApiEndPoints';
import { DESIGNATION_LIST } from './types';

export const getDesignations = (pageNumber, pageSize, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_DESIGNATION + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: DESIGNATION_LIST,
        payload: res.data.data,
      });
      callBack(res.data.data);
    } else {
      dispatch({
        type: DESIGNATION_LIST,
        payload: [],
      });
    }
  } catch { }
};

export const updateDesignation = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === undefined ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_DESIGNATION, payload);
    } else {
      res = await axios.put(JAVA_API_DESIGNATION + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch { }
};

export const deleteDesignation = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_DESIGNATION, { data: deleteObj });
    callBack(res.data);
  } catch { }
};
