import axios from "axios";
import { toast } from "react-toastify";
import { JAVA_API_APPROVAL_CONDITION_LIST, JAVA_API_WORKFLOW_NODE, JAVA_API_WORKFLOW_NODE_LIST } from "../BackendEndPoints";
import { WORKFLOW_NODE_LIST } from "../types";

export const addWorkflowNode = (data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.post(JAVA_API_WORKFLOW_NODE, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const getWorkflowNodeList = (pageNumber, pageSize) => async (dispatch) => {

  const res = await axios.get(JAVA_API_WORKFLOW_NODE_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
  if (res.data.code === 'FHRMSI0001') {
    dispatch({
      type: WORKFLOW_NODE_LIST,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: WORKFLOW_NODE_LIST,
      payload: [],
    });
  }
};


export const updateWorkflowNodeType = (id, data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.put(JAVA_API_WORKFLOW_NODE + '/' + id, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess();
    } else {
      onFail(res.data);
    }
  } catch (error) {
    toast.error();
  }
};

export const deleteWorkflowNodeType = (id, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.delete(JAVA_API_WORKFLOW_NODE + '/' + id);
  try {
    if (res.data.code === 'FHRMSE0070') {
      onSuccess();
    } else {
      onFail(res.data);
    }
  } catch (error) {
    toast.error();
  }
};
export const getApprovalConditionList = (pageNumber, pageSize, filter, onSuccess, onFailure) => async (dispatch) => {
  const res = await axios.get(JAVA_API_APPROVAL_CONDITION_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&requestTypeId=' + filter.requestTypeId);
  if (res.data.code === 'FHRMSI0001') {
    onSuccess(res.data)
  } else {
    onFailure(res.data)
  }
};
