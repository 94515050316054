import { REACT_URI_APPROVER, REACT_URI_MY_REQUESTS } from '../../actions/EndPoints';
import { ReactComponent as Approverh_Icon } from '../../assets/images/LeftSideMenu/ApproverHIcon.svg';
import { ReactComponent as MyRequestsh_Icon } from '../../assets/images/LeftSideMenu/MyRequestsHIcon.svg';
import BrowserLanguage from '../../components/Util/BrowserLanguage';

let language = BrowserLanguage.getDefaultLanguage();
const employeeLoginEn = [
    {
        id: '1',
        name: 'Approver',
        Icon: Approverh_Icon,
        link: `${REACT_URI_APPROVER}`
    },
    {
        id: '2',
        name: 'My Requests',
        Icon: MyRequestsh_Icon,
        link: `${REACT_URI_MY_REQUESTS}`,
    }
];

const employeeLoginEs = [
    {
        id: '1',
        name: 'Aprobadora',
        Icon: Approverh_Icon,
        link: `${REACT_URI_APPROVER}`
    },
    {
        id: '2',
        name: 'Mis solicitudes',
        Icon: MyRequestsh_Icon,
        link: `${REACT_URI_MY_REQUESTS}`,
    }
];


const employeeLoginZh = [
    
    {
        id: '1',
        name: '审批人',
        Icon: Approverh_Icon,
        link: `${REACT_URI_APPROVER}`
    },
    {
        id: '2',
        name: '我的请求',
        Icon: MyRequestsh_Icon,
        link: `${REACT_URI_MY_REQUESTS}`,
    }
];




export default language === 'en' ? employeeLoginEn : language === 'es' ? employeeLoginEs : employeeLoginZh;
