import { Box, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getCompaniesByPartnerTypeANDActive, updateCompanyBranch } from "../../../actions/branch/BranchActions";
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from "../../../components/ConfirmModalNew";
import DataTable from '../../../components/DataTableNew';
import FilterButton from "../../../components/FilterButtom";
import HeaderToolbar from "../../../components/HeaderTooltip";
import CommonUtil from "../../../components/Util/CommonUtils";
import EditIcon from "../../reusableIcons/EditIcon";
import { MaterialUISwitch } from "../../reusableIcons/SwitchIcon";
import UpdateBranchIcon from "../../reusableIcons/UpdateBranchIcon";

export default function Branch() {
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const breadcrumb = [{ path: '', name: t('BRANCHCONFIG0007') }];
  const history = useHistory()
  const [totalPages, setTotalPages] = React.useState(1);
  const [editMode, setEditMode] = useState(false);
  const [clraefilterButton, setClraefilterButton] = useState(false);
  const [filter, setFilter] = useState({
    name: '',
    code: '',
    country: '',
    operator: 'AND',
    type: 'PARTNER',
    partnerType: 'BRANCH'
  });

  useEffect(() => {
    loadData(page, rowsPerPage, filter);
    //eslint-disable-next-line
  }, []);

  const loadData = (page, rowsPerPage, filter) => {
    dispatch(getCompaniesByPartnerTypeANDActive(page, rowsPerPage, filter));
  };
  useEffect(() => {
    if (!CommonUtil.isEmpty(store.branch.companyBranchList)) {
      setPage(store.branch.companyBranchList.currentPage - 1);
      setData(store.branch.companyBranchList.companies);
      setTotalPages(store.branch.companyBranchList.totalPages)
    } else {
      setPage(0);
      setData([]);
    }
  }, [store.branch.companyBranchList]);
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: "code",
      headerName: t('Branch0011'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.code,
      renderCell: (params) => (
        <Tooltip title={params.row.code} >
          <span className="table-cell-trucate">{params.row.code}</span>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: t('Branch0012'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={params.row.name} >
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      ),
    },

    {
      field: "userData.firstName",
      headerName: t('Branch0014'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.userData.firstName,
      renderCell: (params) => (
        <Tooltip title={params.row.userData.firstName} >
          <span className="table-cell-trucate">{params.row.userData.firstName}</span>
        </Tooltip>
      ),
    },
    {
      field: "userData.email",
      headerName: t('Branch0015'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.userData.email,
      renderCell: (params) => (
        <Tooltip title={params.row.userData.email} >
          <span className="table-cell-trucate">{params.row.userData.email}</span>
        </Tooltip>
      ),
    },
    {
      field: "country",
      headerName: t('COMMON189'),
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.country,
      renderCell: (params) => (
        <Tooltip title={params.row.country} >
          <span className="table-cell-trucate">{params.row.country}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 70,
      valueGetter: (params) => params.row.status,
    },

    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon disabled={params.row.status === "INACTIVE"} />}
          onClick={() => params.row.status === "INACTIVE" ? '' : openEditBranch(params.row)}
        />,
        <GridActionsCellItem
          icon={<Tooltip title={params.row.status === "ACTIVE" ? t('COMMON200') : t('COMMON199')}><MaterialUISwitch checked={params.row.status === "ACTIVE"} onChange={(event) => handleChangeSwitch(event, params.row)} /></Tooltip>}
        />,
        <GridActionsCellItem
          icon={<UpdateBranchIcon disabled={params.row.status === "INACTIVE"} />}
          onClick={() => params.row.status === "INACTIVE" ? '' : AdminChange(params.row)}
        />,
      ],
    },
  ]);
  const openEditBranch = (data) => {
    history.push
      ({
        pathname: '/fams/create-Updatebranch',
        state: data,
      });
  };

  const AdminChange = (data) => {
    let obj = {
      companyId: data.id,
      userId: data.userData.id,
      roleId: data.userData.roleId,
      code: data.code,
      name: data.name,
    }
    history.push
      ({
        pathname: '/fams/create-OwnerChangebranch',
        state: obj,
      });
  };
  const openCreateBranch = () => {
    history.push
      ({
        pathname: '/fams/create-branch',
        state: data,
      });
  };

  const handleChanges = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size, filter);
  };

  const handleChangeSwitch = (event, data) => {
    const name = (event.target.checked);
    let statusValue = '';
    if (name === true) {
      statusValue = 'ACTIVE';
    } else {
      statusValue = 'INACTIVE';
    }
    const payload = {
      name: data.name,
      status: statusValue
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={statusValue === 'ACTIVE' ? t('ACTV001') : t('ACTV002')}
            title={statusValue === 'ACTIVE' ? t('COMMON199') : t('COMMON200')}
            onConfirm={() => {
              dispatch(
                updateCompanyBranch(data.id, payload,
                  () => {
                    if (data.status === "ACTIVE") {
                      loadData(page + 1, rowsPerPage, filter);
                      toast.success(t('COMMON206'));
                    } else if (data.status === "INACTIVE") {
                      loadData(page + 1, rowsPerPage, filter);
                      toast.success(t('COMMON205'));
                    }
                  },
                  (data) => {
                    toast.error(data.message)
                  }
                ));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({
      ...filter,
      [name]: event.target.value,
    });
  };
  const handleCancel = () => {
    setFilter({
      ...filter,
      name: '',
      code: '',
      country: '',
    });
    setEditMode(!editMode);
  };

  const clearFilter = () => {
    setFilter({
      ...filter,
      name: '',
      code: '',
      country: '',
    });
    const clearFilter = {
      operator: 'AND',
      type: 'PARTNER',
      partnerType: 'BRANCH'
    }
    setClraefilterButton(false);
    loadData(1, rowsPerPage, clearFilter);
  }
  const handleClose = () => {
    setEditMode(false)
  };
  const reset = () => {
    if (filter.name.trim() === '' && filter.code.trim() === '' && filter.country.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setFilter({
      ...filter,
      name: '',
      code: '',
      country: '',
    });
  };

  const handlefilter = () => {
    if (filter.name.trim() === '' && filter.code.trim() === '' && filter.country.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    loadData(1, rowsPerPage, filter);
    setClraefilterButton(true)
    handleClose();
  };
  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Box p={1} alignItems="center" style={{ alignSelf: 'center' }}>
          {clraefilterButton === false ?
            <div >
              <Tooltip arrow title={t('COMMON006')}>
                <IconButton size='small' variant='contained' color='secondary' onClick={() => handleCancel()}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div> : ''}
        </Box>
        <HeaderToolbar src={AddIcon} onClick={openCreateBranch} tooltipTitle={t('COMMON009')} size='large' />
      </Breadcrumb>
      {editMode ? <>
        <Box style={{ boxShadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%)', backgroundColor: '#FFFFFF', marginBottom: '13px' }} display='flex'>
          <Grid container xs={12} sm={12} >
            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='name' label={t('COMMON014')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.name} />
              </Box>
            </Grid>
            <Grid item sm={2} >
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='code' label={t('COMMON045')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.code} />
              </Box>
            </Grid>
            <Grid item sm={2} >
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField fullWidth name='country' label={t('COMMON189')} helperText='' variant='outlined' size='medium' onChange={handleChange} value={filter.country} />
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={handlefilter} color="primary" variant="contained" className='mt-2' backgroundColor={'#36C96D'} hoverbackgroundColor={'#1AB755'}>
                  {t("APPLY001")}
                </FilterButton>
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <FilterButton onClick={reset} variant="outlined" className='mt-2' borderColor={'#36C96D'} hoverborderColor={'#1AB755'} color={'#36C96D'} hovercolor={'#1AB755'} >
                  {t("CLEAR001")}
                </FilterButton>
              </Box>
            </Grid>

          </Grid>
          <Box marginTop='0px'>
            <Tooltip arrow title={t('COMMON170')}>
              <IconButton size='small' variant='contained' color='primary' onClick={() => handleCancel()}>
                <span className='btn-wrapper--icon rounded'>
                  <CloseIcon style={{ color: '#B7B7B7' }} />
                </span>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </> : <>
        {clraefilterButton ?
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FilterButton onClick={clearFilter} variant="outlined" className='m-2' borderColor={'#FC5F4699'} hoverborderColor={'#E5482F99'} hoverbackgroundColor={'#FC5F4608'} color={'#FC5F46'} hovercolor={'#E5482F'} >
                {t("ClearFilters001")}
              </FilterButton>
            </Box>
          </Grid> : ''}
      </>
      }
      <div style={{ height: '100%', width: "100%" }}>
        <DataTable
          columns={columns}
          rows={data}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChanges(newPage, size)}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </Grid>
  );
}
