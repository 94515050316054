import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function Loader() {
    const store = useSelector((state) => state);
    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={store.loader?.loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}