import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ActivatePersonIcon = () => {
    const [isShown, setIsShown] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Tooltip title={t("COMMON199")}>
                <Grid
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    {isShown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.312" height="19.449" viewBox="0 0 24.312 19.449">
                            <path id="Path_6689" data-name="Path 6689" d="M22.665,4H5.647A3.647,3.647,0,0,0,2,7.647V19.8a3.647,3.647,0,0,0,3.647,3.647H22.665A3.647,3.647,0,0,0,26.312,19.8V7.647A3.647,3.647,0,0,0,22.665,4Zm-.5,2.431-7.148,7.148a1.216,1.216,0,0,1-1.726,0L6.145,6.431ZM23.88,19.8a1.216,1.216,0,0,1-1.216,1.216H5.647A1.216,1.216,0,0,1,4.431,19.8V8.145l7.148,7.148a3.647,3.647,0,0,0,5.154,0L23.88,8.145Z" transform="translate(-2 -4)" fill="#304669" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.312" height="19.449" viewBox="0 0 24.312 19.449">
                            <path id="Path_6689" data-name="Path 6689" d="M22.665,4H5.647A3.647,3.647,0,0,0,2,7.647V19.8a3.647,3.647,0,0,0,3.647,3.647H22.665A3.647,3.647,0,0,0,26.312,19.8V7.647A3.647,3.647,0,0,0,22.665,4Zm-.5,2.431-7.148,7.148a1.216,1.216,0,0,1-1.726,0L6.145,6.431ZM23.88,19.8a1.216,1.216,0,0,1-1.216,1.216H5.647A1.216,1.216,0,0,1,4.431,19.8V8.145l7.148,7.148a3.647,3.647,0,0,0,5.154,0L23.88,8.145Z" transform="translate(-2 -4)" fill="rgba(48,70,105,0.9)" />
                        </svg>
                    )}
                </Grid>
            </Tooltip>
        </>
    );
};
export default ActivatePersonIcon;
