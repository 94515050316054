import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import { JAVA_API_CHECK_IS_MEMBER_ACTIVE, JAVA_API_PERSON_DEACTIVATION } from '../EndPoints';
import { JAVA_API_PERSON } from './ApiEndPoints';
import { PERSON_LIST } from './types';

export const getPersons = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_PERSON + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: [],
        });
      }
    }
  } catch { }
};

export const listPersons = (levelId, pageNumber, payload, pageSize, isPageable, previousData, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/v2.0/access_level/' +
      levelId +
      '/persons' +
      '?page=' +
      pageNumber +
      '&size=' +
      pageSize +
      '&firstName=' +
      payload.firstName +
      '&lastName=' +
      payload.lastName +
      '&employeeCode=' +
      payload.employeeCode +
      '&addPerson=true'
    );
    if (res.status === 200 && res.data.code === 'CASI0001') {
      if (isPageable) {
        if (!CommonUtil.isEmpty(previousData)) {
          var empList = previousData.employee.concat(res.data.data.employee);
          res.data.data.employee = empList;
        }
        dispatch({
          type: PERSON_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: previousData,
        });
      }
    }
  } catch { }
};

export const getPersonByEmployeeCode = (employeeCode, onSuccess, onFailure) => async (dispatch) => {
  try {
    let query = '&firstName=&lastName=&email=';

    if (employeeCode !== '') {
      query += '&employeeCode=' + employeeCode;
    }
    const res = await axios.get(JAVA_API_PERSON + '/search?page=' + 1 + '&size=1' + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      onSuccess(res.data.data);
    } else {
      onFailure([]);
    }
  } catch { }
};

export const addPerson = (payload, callBack) => async (dispatch) => {
  try {
    let res = '';
    res = await axios.post(JAVA_API_PERSON, payload);
    callBack(res.data);
  } catch { }
};

export const updatePerson = (id, payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_PERSON + '/' + id, payload);
    callBack(res.data);
  } catch { }
};

export const deletePerson = (str, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_PERSON + '?id=' + str);
    callBack(res.data);
  } catch { }
};

export const activateEmployee = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.post('/user/activate/membership', payload);
    callBack(res.data);
  } catch (error) { }
};

export const validateActivationLink = (code, payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    let res = await axios.put('/user/' + code + '/activate', payload);
    if (res.data.code === 'CAAI3003') {
      onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (error) { }
};

//check Is User membership Active
export const checkIsMemeberActive = (id, index, record, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_CHECK_IS_MEMBER_ACTIVE + id + "/checkMembership");
    callBack(index, record, res.data);
  } catch { }
};

export const deactivateEmployee = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_PERSON_DEACTIVATION, { data: payload });
    callBack(res.data);
  }
  catch (error) { }
};
