import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Box } from '@mui/material';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URL_DASHBOARD, REACT_VERIFY_COMPANY } from '../../actions/EndPoints';
import { REFRESH_TOKEN_TIMER, SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from '../../actions/types';
import famsBackground from '../../assets/images/onboard/Group 7962.svg';
import loginImg from '../../assets/images/onboard/Login Image.svg';
import CustomTextfield from '../../components/CustomTextField';
import SubmitButtons from '../../components/SubmitButtons';
import CommonUtil from '../../components/Util/CommonUtils';
import { getAccessToken } from '../../oauth-client/OauthClientActions';
import { authorize } from '../../oauth-client/Onboard';
import setJWTToken from '../../oauth-client/setJWTToken';
import './custom.css';
import LoginLayout from './LoginLayout';
const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    '&:hover': {
      background: '#119743',
    },
  },
}));
function LoginForm(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const [error, setError] = useState({
    email: false,
    password: false,
  });
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const query = new URLSearchParams(props.location.search);

  const handleChange = (prop) => (event) => {

    setValues({ ...values, [prop]: event.target.value });
    setError({ ...error, [prop]: false });
  };


  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!CommonUtil.isEmptyString(query.get('code'))) {
      dispatch(getAccessToken(query.get('code'), history));
    } else if (store.security.validToken && !CommonUtil.isEmptyString(store.security.AccJwtToken)) {
      history.push('/fams/dashboard');
    } else {
      setJWTToken(false);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    }
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!CommonUtil.isValidEmail(values.email)) {
      toast.error(t('LOGIN0001'));
      setError({ email: true });
      return;
    }
    const user = {
      userName: values.email,
      password: values.password,
    };
    dispatch(
      authorize(
        user,
        (successData) => {
          if (successData.code === 'UASI0011') {
            hqSwitchCompany(successData.data);
          } else {
            localStorage.setItem("temp_token", 'Bearer ' + successData.data.access_token);
            history.push({ pathname: REACT_VERIFY_COMPANY });
          }
        },
        (errObj) => {
          switch (errObj.code) {
            case 'FHRMSE0015':
              setError({ ...error, email: true });
              break;
            case 'FHRMSE0014':
              setError({ ...error, password: true });
              break;
            case 'FHRMSE0099':
              setError({ email: true });
              break;
            case 'FHRMSE0013':
              setError({ password: true });
              break;
            default:
              setError({ email: true, password: true });
          }
          toast.error(errObj.message);
        }
      )
    );
  };

  const hqSwitchCompany = (data) => {
    setJWTToken('Bearer ' + data.access_token);
    const decoded = jwt_decode(data.access_token);
    localStorage.setItem("access_token", 'Bearer ' + data.access_token);
    localStorage.setItem("refresh_token", 'Bearer ' + data.refresh_token);
    localStorage.setItem('companyId', decoded.companyId);
    localStorage.setItem('companyName', decoded.companyName);
    localStorage.setItem('companyCode', decoded.companyCode);
    localStorage.setItem('USER', JSON.stringify(decoded));
    var currentSeconds = Math.floor(Date.now() / 1000);
    var secondsLeft = decoded.exp - currentSeconds;
    var timer = Math.floor(secondsLeft / 60) - 5;
    timer = timer * 60 * 1000;
    console.log('Set refresh token timer', timer);
    console.log('Time left to call refresh token', secondsLeft / 60);
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
    });
    dispatch({
      type: SET_REFRESH_TOKEN,
      payload: 'Bearer ' + data.refresh_token,
    });
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: 'Bearer ' + data.access_token,
    });
    dispatch({
      type: REFRESH_TOKEN_TIMER,
      payload: timer,
    });

    history.push({ pathname: REACT_URL_DASHBOARD });
  }
  return !CommonUtil.isEmptyString(query.get('code')) ? (
    <></>
  ) : (
    <>
      <LoginLayout login={loginImg} lang={true}>
        <Grid className='center_div' >
          <Grid container rowSpacing={1} direction='row' justifyContent='center' alignItems='center' className={classes.form}>
            <Grid item className='logo-center' >
              <img src={famsBackground} alt='logo' style={{ height: '20vh', width: '150px', marginBottom: '30px', }} />
            </Grid>
            <Grid item xs={12} >
              <Typography variant='h2' align='left' style={{ color: '#3D4977', fontWeight: '500', marginLeft: '44px' }}>
                {t('COMMON038')}
              </Typography>
            </Grid>
          </Grid>
          <form noValidate className={classes.form} onSubmit={handleSubmit} autoComplete='off'>
            <Grid container alignItems='center'>
              <Grid item>
                <PersonIcon className='login_icons' />
              </Grid>
              <Grid item xs>
                <CustomTextfield
                  error={error.email}
                  className='login_input'
                  size='medium'
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  validation='email'
                  label={t('COMMON134')}
                  autoComplete='off'
                  autoFocus
                  helperText={error.email}
                  name='email'
                  type={'text'}
                  handleChange={handleChange('email')}
                />
              </Grid>
            </Grid>
            <Grid container alignItems='center'>
              <Grid item>
                <LockIcon className='login_icons' />
              </Grid>
              <Grid item xs style={{ marginBottom: '-20px' }}>
                <CustomTextfield
                  error={error.password}
                  size='medium'
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='password'
                  validation='password'
                  label={t('COMMON135')}
                  autoComplete='off'
                  autoFocus
                  helperText={error.password}
                  name='password'
                  type={values.showPassword ? 'text' : 'password'}
                  handleChange={handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                          {values.showPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems='center'>
              <Grid item xs></Grid>
              <Grid item xs align='right'>
                <Box align='right' className='link_boxs'>
                  <Link href='/fams/forgot-password' className='forgot_passwordss'>
                    {t('COMMON137')}
                  </Link>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems='center'>
              <Grid item></Grid>
              <Grid item xs>
                <SubmitButtons type='submit' fullWidth variant='contained' className={classes.submit} style={{ height: '50px ' }} >
                  {t('COMMON136')}
                </SubmitButtons>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </LoginLayout>
    </>
  );
}
export default withRouter(LoginForm);
