import { Grid, Tooltip } from '@material-ui/core';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDepartment, getDepartments } from '../../../actions/organization/DepartmentApiCalls';
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from '../../../components/ConfirmModalNew';
import DataTable from '../../../components/DataTableNew';
import HeaderToolbar from '../../../components/HeaderTooltip';
import CommonUtil from '../../../components/Util/CommonUtils';
import DeleteIcon from '../../reusableIcons/DeleteIcon';
import EditIcon from '../../reusableIcons/EditIcon';

export default function Department() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();

  useEffect((props) => {
    loadDepartments(page, rowsPerPage);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.department.departmentList)) {
      setPage(store.department.departmentList.currentPage - 1);
      setTotalPages(store.department.departmentList.totalPages)
    } else {
      setPage(0);
    }
    setData(store.department.departmentList.department);
  }, [store.department.departmentList]);

  const loadDepartments = (page, rowsPerPage) => {
    dispatch(getDepartments(page, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadDepartments(newPage, size);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => (
      idsToDelete[index] = record.id
    ));
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            title={t('COMMON004')}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteDepartment(idsToDelete, (data) => {
                  handlError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    if (data.code === "OMSW0014") {
      const ids = []
      const test = []
      const delList = data.data.error;
      const existinglist = store.department.departmentList.department;
      delList.map((item, index) => (
        ids.push(item.data.idOrCode)
      ))
      existinglist.map((item, index) => (
        ids.map((id) => item.id === id ?
          test.push(<span>{item.code} : {data.data.error[0].message}</span>)
          : '')
      ));
      var toastMessage = () => {
        var hh = test.map((i) => {
          return <><span>{i}</span><br /></>
        })
        return <>{hh}</>;
      }
      toast.error(toastMessage());
    }
    if (data.data.error[0] !== undefined) {
      if (data.data.error[0].code === 'OMSE0190') {
        return;
      }
    }
    switch (data.code) {
      case 'OMSW0014':
        //  toast.error(t('ORGDEPT0001'));
        break;
      case 'OMSI0000':
        toast.success(t('ORGDEPT0002'));
        loadDepartments(page, rowsPerPage, true);
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  const breadcrumb = [{ path: '', name: t('DEPT001') }];
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: "name",
      headerName: t('DEPT004'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={params.row.name} >
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t('DEPT003'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.code,
      renderCell: (params) => (
        <Tooltip title={params.row.code} >
          <span className="table-cell-trucate">{params.row.code}</span>
        </Tooltip>
      ),
    },
    {
      field: "description",
      headerName: t('DEPT009'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
      renderCell: (params) => (
        <Tooltip title={params.row.description} >
          <span className="table-cell-trucate">{params.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: "parentDepartment.name",
      headerName: t('COMMON050'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.parentDepartment ? params.row?.parentDepartment.name : '',
      renderCell: (params) => (
        <Tooltip title={params.row.parentDepartment ? params.row?.parentDepartment.name : ''} >
          <span className="table-cell-trucate">{params.row.parentDepartment ? params.row?.parentDepartment.name : ''}</span>
        </Tooltip>
      ),
    },

    {
      field: "Actions",
      headerName: t('dataTableAction'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => EditDepartement(params.row)}
          disabled={params.row.code.toLowerCase() === "default"}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => deleteRecord([params.row])}
          disabled={params.row.code.toLowerCase() === "default"}
        />,
      ],
    },
  ]);

  const createDepartement = () => {
    history.push
      ({
        pathname: '/fams/create-department',
        state: data,
      });
  };
  const EditDepartement = (data) => {
    history.push
      ({
        pathname: '/fams/create-department',
        state: data,
      });
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <HeaderToolbar src={AddIcon} onClick={createDepartement} tooltipTitle={t('COMMON009')} size='large' />
      </Breadcrumb>
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={data}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChangeRowsPerPage(newPage, size)}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </Grid>
  );
}
