import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_COMPANY_BY_ID, SET_COMPANY } from './types';

export const getUser = (email, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/user/' + email);
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: 'SET_USER',
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: 'SET_USER',
        payload: {},
      });
      onFailure(res.data);
    }
  } catch { }
};

export const updateUser = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put('/user/' + payload.email, payload);
    if (res.status === 200 && res.data.code === 'UASI0002') {
      toast.success(res.data.message);
      dispatch({
        type: 'SET_USER',
        payload: payload,
      });
      onSuccess(res.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch { }
};

export const getCompanyList = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/company?pageNumber=0&pageSize=100');
    if (res.status === 200 && res.data.code === 'UASI0000') {
      onSuccess(res.data.data);
    } else {
      onFailure(res.data);
    }
  } catch { }
};

export const getCompany = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/company');
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: SET_COMPANY,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: SET_COMPANY,
        payload: {},
      });
      onFailure(res.data);
    }
  } catch { }
};

export const getCompanyProfile = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/company?pageNumber=1&pageSize=1000&companyId=' + localStorage.getItem('companyId'));
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: SET_COMPANY,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: SET_COMPANY,
        payload: {},
      });
      onFailure(res.data);
    }
  } catch { }
};

export const updateCompanyProfile = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put('/company/' + payload.id + '/profile', payload);
    if (res.status === 200 && res.data.code === 'UASI0007') {
      onSuccess(res.data);
      // dispatch({
      //   type: SET_COMPANY,
      //   payload: res.data.data,
      // });
      console.log("res.data.data:>>>>>>>>>>>", res.data.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch { }
};

export const sendResetPwdLink = (emailId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/forgot_password?emailId=' + emailId);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      onSuccess(res.data.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch { }
};

export const resetPassword = (code, payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put('/user/reset_password?code=' + code, payload);
    if (res.status === 200 && res.data.code === 'FHRMSI0002') {
      onSuccess(res.data.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch { }
};

export const getByBranchId = (id, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/company/' + id);
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: SET_COMPANY,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: SET_COMPANY,
        payload: {},
      });
      onFailure(res.data);
    }
  } catch { }
};


export const getCompanyById = (id, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/company/' + id);
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: GET_COMPANY_BY_ID,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: GET_COMPANY_BY_ID,
        payload: {},
      });
      onFailure(res.data);
    }
  } catch { }
};
