import { Avatar, Box, Button, Card, CardContent, Divider, Grid, ListItem, Menu, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getUser } from '../../actions/ProfileApiCalls';
import defaultProfile from '../../assets/images/defaultProfile.png';
import CompanyBackgroundImg from '../../assets/images/Group 8539.png';
import LogoutIcon from '../../assets/images/onboard/Logout Img.svg';
import Language from '../../assets/images/onboard/switchlang icon.svg';
import CommonUtil from '../../components/Util/CommonUtils';
import { logout } from '../../oauth-client/OauthClientActions';
import SelectLanguage from './SelectLanguegeChange';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  icon_color: {
    color: '#36C96D',
  },
  buttonbg: {
    backgroundColor: '#34C36A0A',
    '&:hover': {
      backgroundColor: '#34C36A1A',
    }
  },
}));

export default function UserProfileCard() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.security.user);
  const store = useSelector((state) => state.security);
  const [userData, setUserData] = useState({});
  const [profile, setProfile] = useState(defaultProfile);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userProfile = () => {
    history.push({
      pathname: '/fams/user-profile',
    });
    setAnchorEl(null);
  };

  const companyProfile = () => {
    history.push({
      pathname: '/fams/company-profile',
    });
    setAnchorEl(null);
  };

  const dashboard = () => {
    if ('EMPLOYEE' === store.user.roleCode.toUpperCase()) {
      history.push({
        pathname: '/fams/my-requests',
      });
      setAnchorEl(null);
    } else {
      history.push({
        pathname: '/fams/dashboard',
      });
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (!CommonUtil.isEmpty(store) && !CommonUtil.isEmpty(store.userData)) {

      setUserData({ ...store.userData });
      if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + store.userData.profilePhoto);
      }
    } else {
      dispatch(getUser(user.id));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store)) {
      setUserData({ ...store.userData });
    }

    if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
      setProfile('data:image/jpeg;base64,' + store.userData.profilePhoto);
    }
  }, [store]);

  return (
    <Fragment>
      <Button onMouseEnter={handleClick} className={`${classes.buttonbg} text-capitalize align-items-center`} style={{ marginRight: '40px' }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Box>
          <Avatar alt='' src={profile} />
        </Box>
        <div className='d-xl-block pl-2'>
          <div className='font-weight-bold pl-0' style={{
            color: '#36C96D'
          }}>{userData.firstName}</div>
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}

        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          boxShadow: '0px 5px 10px #E8E8E8 !important',
        }}
        className='MuiMenu-papers'
        PaperProps={{

          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(#E8E8E8 (0px 5px 10px)) !important',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#66DB92',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        onClose={handleClose}
      >
        <div onMouseLeave={() => { setAnchorEl(null); }}>
          <Card style={{
            backgroundColor: '#36C96D',
            backgroundImage: `url(${CompanyBackgroundImg})`,
            backgroundPosition: 'bottom right',
            backgroundRepeat: 'no-repeat',
            opacity: 1,
            position: 'relative',
            width: '215px'
          }}>
            <CardContent >
            <Grid container spacing={1} textAlign='left' p={1} style={{ width: '215px' }} >
                <Grid item xs={12} ><Tooltip title={userData.firstName + ' ' + userData.lastName}>
                  <div style={{ color: '#FFFFFF',overflow:'hidden',textOverflow:'ellipsis',width:'200px' }}>
                    {userData.firstName + ' ' + userData.lastName}
                  </div></Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ color: '#FFFFFF' }}>
                    {user.companyName}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ color: '#FFFFFF' }}>
                    {userData.email}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <ListItem button className='px-2' onClick={() => dashboard()}>
            <div className='nav-link-icon py-1'>
              <HomeIcon className={classes.icon_color} />
            </div>
            <Typography variant='body' style={{ color: '#304669BD' }}>
              {t('COMMON127')}
            </Typography>
          </ListItem>
          <ListItem button className='px-2' onClick={() => userProfile()}>
            <div className='nav-link-icon py-1'>
              <AccountBoxIcon className={classes.icon_color} />
            </div>
            <Typography variant='body' style={{ color: '#304669BD' }}>
              {t('COMMON128')}
            </Typography>
          </ListItem>
          <ListItem button className='px-2' style={{
            paddingTop: '0px',
            paddingBottom: ' 0px'
          }}>
            <div className='nav-link-icon py-1'>
              <SelectLanguage normalImage={Language} className={classes.icon_color} alt='Language' item={t('COMMON126')} />
            </div>
          </ListItem>
          <ListItem button className='px-2' onClick={() => companyProfile()}>
            <div className='nav-link-icon py-1'>
              <PersonIcon className={classes.icon_color} />
            </div>
            <Typography variant='body' style={{ color: '#304669BD' }}>
              {t('COMMON125')}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem button className='px-2' onClick={() => {
            handleClose();
            dispatch(logout());
          }} style={{
            width: '200px', marginTop: '10px', marginLeft: '5px', boxshadow: '0px 5px 10px #E8E8E8',
            borderRadius: '6px'
          }}>
            <div className='nav-link-icon py-1'>
              <img alt='' src={LogoutIcon} color='green' sizes='small' />
            </div>
            <Typography variant='body' style={{ fontSize: '15px', color: '#FC5F46' }}>
              {t('USERPROFCARD003')}
            </Typography>
          </ListItem>
        </div>
      </Menu>
    </Fragment>
  );
}
