import { IconButton, Paper } from "@material-ui/core";
import {
  Divider,
  Grid, Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCompanyById } from "../../actions/ProfileApiCalls";
import companyProfiles from '../../assets/images/companyprofile.svg';
import Breadcrumb from "../../components/Breadcrumb";
import CommonUtil from "../../components/Util/CommonUtils";
import EditIcon from "../reusableIcons/EditIcon";
import ProfileUplod from "./UploadImg";

export default function CompanyProfile() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const history = useHistory();
  const { t } = useTranslation();
  const breadcrumb = [{ path: '', name: t('COMMON125') }];
  const [payload, setPayload] = useState({
    id: '',
    name: '',
    industry: '',
    companySize: '',
    code: '',
    registrationNumber: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  });

  const id = localStorage.getItem("companyId");
  useEffect(() => {
    loadCompanyData();
    // eslint-disable-next-line 
  }, []);
  React.useEffect(() => {
    if (!CommonUtil.isEmpty(store.company.company)) {
      setPayload({
        id: store.company.company.id,
        name: store.company.company.name,
        industry: store.company.company.industry,
        companySize: store.company.company.companySize,
        code: store.company.company.code,
        registrationNumber: store.company.company.registrationNumber,
        email: store.company.company.email,
        phone: store.company.company.phone,
        address: store.company.company.address,
        city: store.company.company.city,
        state: store.company.company.state,
        country: store.company.company.country,
        pincode: store.company.company.pincode,
      });
    }

  }, [store.company]);
  const loadCompanyData = () => {
    dispatch(
      getCompanyById(id, (data) => { }),
      () => { }
    );
  };

  const EditCompany = () => {
    history.push({ pathname: '/fams/company-profile-screen', });
  };
  return (
    <>
      <Breadcrumb links={breadcrumb} style={{ paddingBottom: '15px' }}>
        <Box textAlign='left' style={{ paddingBottom: '15px', paddingTop: '10px' }}>
          {(store.security.user.companyCode === 'ZKTECO' && (store.security.user.roleCode.toLowerCase() === 'owner' || store.security.user.roleCode.toLowerCase() === 'superadmin')) && (
            <div style={{ backgroundColor: '#F2F2F6' }}>
              <IconButton onClick={EditCompany} style={{ fontSize: '0.5rem' }}>
                <EditIcon />
              </IconButton>
            </div>
          )}
        </Box>
      </Breadcrumb>
      <Paper className='css-pepar-in-userprofile'
        elevation={0}
        style={{
          minHeight: "200px",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
      >
        <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start">
          <Grid container justifyContent="center" alignItems='center' xl={2} lg={2} md={4} sm={4} style={{ marginTop: '110px', }}>
            <ProfileUplod profilePhoto={companyProfiles} disabled={true} heightimage='130px' />
          </Grid>
          <Grid item lg={4}>
            <Box display="flex" paddingBottom={4} paddingTop={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMP001')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.name : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON110')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.companySize : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON116')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.phone === undefined||payload.phone === '' ? '' : '+' + payload.phone : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON189')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.country : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON120')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.city : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON114')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.registrationNumber : ''}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item lg={5} paddingLeft="32px">
            <Box display="flex" paddingBottom={4} paddingTop={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON108')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.industry : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON045')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.code : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON042')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.email : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON118')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.address : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON121')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }} >:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.state : ''}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingBottom={4}>
              <Box item paddingRight={1} minWidth="150px">
                <Typography variant="fieldLabel" style={{ color: '#30466980' }}>{t('COMMON123')}</Typography>
              </Box>
              <Box item paddingRight={1} minWidth="25px">
                <Typography style={{ color: '#939FB1' }}>:</Typography>
              </Box>
              <Box item className='textbreakk'>
                <Typography variant="fieldValue" style={{ color: '#304669', opacity: '0.8' }}>
                  {payload ? payload.pincode : ''}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
