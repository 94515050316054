import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { addApprovalWorkflow, updateApprovalWorkflow } from '../../../actions/approvalWorkflow/ApprovalWorkflowActions';
import { REACT_URI_APPROVAL_WORKFLOW } from '../../../actions/EndPoints';
import { getRequestTypeList } from '../../../actions/requestType/RequestTypeActions';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';
const useStyles = makeStyles((theme) => ({
    customContainer: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 4px #00000029',
        border: '0.5px solid #00000029',
        opacity: 1,
        marginBottom: '5px',
        borderRadius: '14px',
        padding: '18px',
        margin: '24px',
        height: "700px",
        [theme.breakpoints.down("lg")]: {

            height: "400px",
        },
    },
}));

export default function AddApprovalWorkflow(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const [requestTypes, setRequestTypes] = React.useState([]);

    const [state, setState] = React.useState({
        id: props.history.location.state ? props.history.location.state?.id : '',
        code: props.history.location.state ? props.history.location.state?.code : "",
        name: props.history.location.state ? props.history.location.state?.name : "",
        requestTypeId: props.history.location.state.requestTypeId ? props.history.location.state?.requestTypeId : "",
        description: props.history.location.state ? props.history.location.state?.description : "",
    });

    const [error, setError] = React.useState({
        code: false,
        name: false,
        description: false,
        requestTypeId: false,

    });

    const handleClose = () => {
        history.push(REACT_URI_APPROVAL_WORKFLOW)
    };

    const breadcrumb = [
        { path: REACT_URI_APPROVAL_WORKFLOW, name: t('APPROAVWORKFLOW0002') },
        { path: '/fams/create-approvalWorkflow', name: props.history.location.state?.id === undefined ? t("APPROAVWORKFLOW0003") : t("APPROAVWORKFLOW0004") },
    ];

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });

        setError({
            [name]: false,
        });
    };

    useEffect(() => {
        loadRequestTypes();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.requestType.requestTypeList)) {
            setRequestTypes(store.requestType.requestTypeList.request_types);
        } else {
            setRequestTypes([]);
        }

    }, [store.requestType.requestTypeList]);

    const loadRequestTypes = () => {
        dispatch(getRequestTypeList(1, 0, ''));
    }

    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(state.code)) {
            setError({ code: true })
            toast.error(t("REQUESTTYPES0003"))
        } else if (!CommonUtil.validateCode(state.code)) {
            setError({ code: true })
            toast.error(t("codeValidation"))
        } else if (CommonUtil.isEmptyString(state.name)) {
            setError({ name: true })
            toast.error(t("REQUESTTYPES0004"))
        } else if (!CommonUtil.validateName(state.name)) {
            setError({ name: true })
            toast.error(t("nameValidation"))
        } else if (CommonUtil.isEmptyString(state.requestTypeId)) {
            setError({ requestTypeId: true })
            toast.error(t("BRANCHCONFIG0006"))
        } else {
            const update = {
                code: state.code.toUpperCase(),
                name: state.name,
                description: state.description,
                requestTypeId: state.requestTypeId.id,
            }
            if (!CommonUtil.isEmptyString(state.id)) {
                dispatch(updateApprovalWorkflow(state.id, update,
                    () => {
                        handleClose();
                        toast.success(t('APPROAVWORKFLOW0006'));
                    }, (error) => {
                        switch (error.code) {
                            case 'FHRMSE0042':
                                setError({ code: true });
                                break;
                            case 'FHRMSE0043':
                                setError({ name: true });
                                break;
                            case 'FHRMSE0101':
                                setError({ requestTypeId: true });
                                break;
                            case 'FHRMSE0082':
                                setError({ requestTypeId: true });
                                break;
                            default:
                                setError({ error });
                        }
                        toast.error(error.message);
                    }
                ));
            } else {
                dispatch(addApprovalWorkflow(update,
                    () => {
                        handleClose();
                        toast.success(t('APPROAVWORKFLOW0005'));
                    },
                    (error) => {
                        onFail(error);
                    }
                ));
            }
        }
    }

    const onFail = (data) => {
        if (data) {
            switch (data.code) {
                case "FHRMSE0042":
                    setError({ code: true });
                    break;
                case "FHRMSE0043":
                    setError({ name: true });
                    break;
                case "FHRMSE0082":
                    setError({ requestTypeId: true });
                    break;
                default:
                    setError({ ...error });
            }
            toast.error(data.message);
        }
    }
    return (
        <>
            <Grid   >
                <Breadcrumb links={breadcrumb} />
                <Grid fullWidth className={`${classes.customContainer} background-size`}>
                    <Grid
                        container
                        sx={12}
                        xs={12}
                        alignItems="center"
                        rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={6} sm={6}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <Autocomplete
                                    openText={t('COMMON169')}
                                    closeText={t('COMMON170')}
                                    clearText={t('COMMON215')}
                                    noOptionsText={t('COMMON214')}
                                    style={{ width: '100%' }}
                                    options={requestTypes}
                                    name={state.requestTypeId}
                                    disabled={!CommonUtil.isEmptyString(state.id)}
                                    value={state.requestTypeId}
                                    getOptionLabel={(reqtype) => (reqtype.name ? reqtype.name : '')}
                                    onChange={(event, newValue) => {
                                        if (!CommonUtil.isEmpty(newValue)) {
                                            setState({ ...state, requestTypeId: newValue });
                                        } else {
                                            setState({ ...state, requestTypeId: '' });
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} value={state.requestTypeId} name={state.requestTypeId} error={error.requestTypeId} variant='outlined' size="medium" fullWidth label={t('BRANCHCONFIG0004') + '*'} />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.code}
                                    name="code"
                                    label={t("COMMON045") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.code}
                                    onChange={handleChange}
                                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 30 }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    error={error.name}
                                    name="name"
                                    label={t("COMMON044") + '*'}
                                    helperText=""
                                    variant="outlined"
                                    size="medium"
                                    value={state.name}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={6} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    variant="outlined"
                                    error={error.description}
                                    name="description"
                                    label={t("REQUESTTYPES0002")}
                                    helperText=""
                                    size="medium"
                                    value={state.description}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 250 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">
                        <Box display="flex" m={1} p={1}>
                            <CancelButtons onClick={handleClose} color="primary" variant="outlined" className='m-2'>
                                {t("COMMON008")}
                            </CancelButtons>
                            <SubmitButtons onClick={handleSubmit} color="primary" variant="contained" className='m-2'>
                                {t("COMMON035")}
                            </SubmitButtons>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
