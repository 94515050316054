import axios from 'axios';
import { toast } from 'react-toastify';
import { JAVA_API_BRANCH_CONFIGURATION, JAVA_API_BRANCH_CONFIGURATION_LIST } from '../BackendEndPoints';
import { BRANCH_CONFIGURATION_LIST } from '../types';

const buildQuery = (filter) => {
  let query = '';
  if (filter.branchName) {
    query = `&companyName=${filter.branchName}`;
  }
  if (filter.requestTypeName) {
    query = query + `&requestTypeName=${filter.requestTypeName}`;
  }
  return query;
}

export const addBranchConfiguration = (data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.post(JAVA_API_BRANCH_CONFIGURATION, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const updateBranchConfiguration = (id, data, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.put(JAVA_API_BRANCH_CONFIGURATION + '/' + id, data);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess(res.data);
    } else {
      onFail(res.data);
    }
  } catch (error) {
  }
};

export const getBranchConfigurationList = (pageNumber, pageSize, filter) => async (dispatch) => {
  if (filter === undefined || filter === '') {
    filter = {
      branchName: '',
      requestTypeName: '',
    }
  }
  const res = await axios.get(JAVA_API_BRANCH_CONFIGURATION_LIST + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
  if (res.data.code === 'FHRMSI0001') {
    dispatch({
      type: BRANCH_CONFIGURATION_LIST,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: BRANCH_CONFIGURATION_LIST,
      payload: [],
    });
  }
};

export const deleteBranchConfiguration = (id, onSuccess, onFail) => async (dispatch) => {
  const res = await axios.delete(JAVA_API_BRANCH_CONFIGURATION + '/' + id);
  try {
    if (res.data.code === 'FHRMSI0001') {
      onSuccess();
    } else {
      onFail();
    }
  } catch (error) {
    toast.error();
  }
};
