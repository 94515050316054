import { Grid, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getStatusReportLogList } from '../../actions/reports/ReportsAction';
import Breadcrumb from '../../components/Breadcrumb';
import CustomColor from '../../components/CustomColor';
import DataTable from '../../components/DataTableNew';
import CommonUtil from '../../components/Util/CommonUtils';
import DashboardCards from './DashboardCards';
import Piechart from './Piechart';

export default function Dashboard(props) {
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const [data, setData] = useState([store.report.statusreportList]);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [status, setStatus] = React.useState();
  const [totalPages, setTotalPages] = React.useState(1);
  const breadcrumb = [{ path: '', name: t('COMMON180') }];
  useEffect(() => {
    loadData(page, rowsPerPage);
    //eslint-disable-next-line
  }, [status]);

  const handleStatusClick = (statusOfRequest) => {
    setStatus(statusOfRequest ? statusOfRequest : status);
  }

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.report.statusreportList)) {
      setPage(store.report.statusreportList.currentPage - 1);
      setData(store.report.statusreportList.requests);
      setTotalPages(store.report.statusreportList.totalPages)
    } else {
      setPage(0);
      setData([]);
    }
  }, [store.report.statusreportList]);


  const loadData = (page, rowsPerPage) => {
    const payload = {
      id: '',
      requestTypeId: '',
      templateId: '',
      status: status,
      companyId: '',
      userId: '',
    };
    dispatch(getStatusReportLogList(page, rowsPerPage, payload));
  };

  const handleChangePage = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size);
  };
  //eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      field: "companyId.name",
      headerName: t('BRANCHCONFIG0007'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.companyId.name,
      renderCell: (params) => (
        <Tooltip title={params.row.companyId.name} >
          <span className="table-cell-trucate">{params.row.companyId.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "requestTypeId.name",
      headerName: t('BRANCHCONFIG0004'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.requestTypeId.name,
      renderCell: (params) => (
        <Tooltip title={params.row.requestTypeId.name} >
          <span className="table-cell-trucate">{params.row.requestTypeId.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.status,
      renderCell: (params) => {
        return <CustomColor row={params.row.status} />;
      },
    }
  ])
  return (
    <>
      <Breadcrumb links={breadcrumb}>
      </Breadcrumb>
      <Grid item xs={12} sm={12} mt={5}>
        <DashboardCards handleStatusClick={(e) => { handleStatusClick(e) }} />
      </Grid><Grid container sm={12}>
        <Grid sm={6}>
          <Piechart /></Grid><Grid sm={6}>
          {status ?
            <DataTable
              columns={columns}
              rows={data}
              page={page}
              pageCount={totalPages}
              pageSize={rowsPerPage}
              handleChange={(newPage, size) => handleChangePage(newPage, size)}
            /> : <DataTable
              columns={columns}
              page={page}
              pageCount={totalPages}
              pageSize={rowsPerPage}
              handleChange={(newPage, size) => handleChangePage(newPage, size)}
            />}
        </Grid>
      </Grid>
    </>
  );
}
