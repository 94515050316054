
import { Button, withStyles } from '@material-ui/core';
import React from 'react';

export default function FilterButton(props) {
    const ColorButton = withStyles((theme) => ({
        root: {
            backgroundColor: props.backgroundColor,
            color: props.color,
            opacity: 1,
            borderColor: props.borderColor,
            width: '180px',
            minHeight: '40px',
            fontWeight: 100,
            '&:hover': {
                backgroundColor: props.hoverbackgroundColor,
                color: props.hovercolor,
                borderColor: props.hoverborderColor,
            },
        },
    }))(Button);

    return (
        <ColorButton {...props} />
    )
}