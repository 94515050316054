import { ThemeProvider } from '@material-ui/styles';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  REACT_LOGIN, REACT_LOGOUT, REACT_RESULT_PAGE, REACT_URI_APPLICATIONS, REACT_URI_APPROVAL_CONDITION, REACT_URI_APPROVAL_WORKFLOW, REACT_URI_APPROVER, REACT_URI_BRANCH_CONFIGURATIONS, REACT_URI_DEPARTMENT, REACT_URI_DESIGNATION, REACT_URI_MY_REQUESTS, REACT_URI_PERSON, REACT_URI_REPORTS, REACT_URI_REQUEST_TYPE, REACT_URI_TEMPLATES, REACT_URI_TEMPLATE_ITEMS, REACT_URI_WORKFLOW_NODE, REACT_URL_BRANCH, REACT_URL_DASHBOARD, REACT_VERIFY_COMPANY
} from './actions/EndPoints';
import { hideLoader, showLoader } from './actions/LoaderActions';
import { SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from './actions/types';
import store from './config/configureStore';
import LeftSidebar from './layout-blueprints/LeftSidebar';
import Logout from './oauth-client/Logout';
import { logout } from './oauth-client/OauthClientActions';
import SecuredRoute from './oauth-client/SecureRoute';
import setJWTToken from './oauth-client/setJWTToken';
import AddApprovalCondition from './pages/settings/approvalCondition/AddApprovalCondition';
import ApprovalCondition from './pages/settings/approvalCondition/ApprovalCondition';
import AddApprovalWorkflow from './pages/settings/approvalWorkflow/AddApprovalWorkflow';
import ApprovalWorkflow from './pages/settings/approvalWorkflow/ApprovalWorkflow';
import Approver from './pages/approver/Approver';
import BranchConfigurations from './pages/settings/branchConfigurations/BranchConfigurations';
import Branch from './pages/organization/branch/Branch';
import OwnerChange from './pages/organization/branch/OwnerChange';
import UpdateBranch from './pages/organization/branch/UpdateBranch';
import Dashboard from './pages/dashboard/Dashboard';
import AddDepartment from './pages/organization/department/AddDepartment';
import Department from './pages/organization/department/Department';
import AddDesignation from './pages/organization/designation/AddDesignation';
import Designation from './pages/organization/designation/Designation';
import Membership from './pages/organization/membership/Membership';
import AddMyRequest from './pages/myRequests/AddMyRequest';
import EditMyRequest from './pages/myRequests/EditMyRequest';
import MyRequests from './pages/myRequests/MyRequests';
import ActivateAccount from './pages/onboard/ActivateAccount';
import CommonResultPage from './pages/onboard/CommonResultPage';
import ForgotPasword from './pages/onboard/ForgotPasword';
import LoginForm from './pages/onboard/LoginForm';
import ResetPassword from './pages/onboard/ResetPassword';
import VerifyCompany from './pages/onboard/VerifyCompany';
import AddPerson from './pages/organization/person/AddPerson';
import Person from './pages/organization/person/Person';
import CompanyProfile from './pages/profile/CompanyProfile';
import CompanyProfileScreen from './pages/profile/CompanyProfileScreen';
import UserProfile from './pages/profile/UserProfile';
import UserProfileScreen from './pages/profile/UserProfileScreen';
import ReportsItems from './pages/reports/ReportsItems';
import CreateRequestType from './pages/settings/requestType/AddRequestType';
import TemplateItem from './pages/settings/templateItem/TemplateItem';
import AddTemplate from './pages/settings/template/AddTemplate';
import Template from './pages/settings/template/Template';
import AddWorkflowNode from './pages/settings/workflowNode/AddWorkflowNode';
import WorkflowNode from './pages/settings/workflowNode/WorkflowNode';
import MuiTheme from './theme';
import RequestType from './pages/settings/requestType/RequestType';
import AddBranchConfiguration from './pages/settings/branchConfigurations/AddBranchConfigurations';
import AddTemplateItem from './pages/settings/templateItem/AddTemplateItem';
import AddBranch from './pages/organization/branch/AddBranch';

const jwtToken = localStorage.getItem("access_token");

if (jwtToken) {
  try {
    setJWTToken(jwtToken);
    const decoded_jwtToken = jwt_decode(jwtToken);
    store.dispatch({
      type: SET_CURRENT_USER,
      payload: decoded_jwtToken,
    });

    store.dispatch({
      type: SET_REFRESH_TOKEN,
      payload: localStorage.getItem("refresh_token"),
    });
    store.dispatch({
      type: SET_ACCESS_TOKEN,
      payload: localStorage.getItem("access_token"),
    });

    const currentTime = Date.now() / 1000;
    if (decoded_jwtToken.exp < currentTime) {
      store.dispatch(logout());
      window.location.href = '/';
    }
  } catch (error) {
    localStorage.removeItem('companyCode');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyId');
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = '/';
  }
} else if (localStorage.getItem("temp_token")) {
  setJWTToken(localStorage.getItem("temp_token"));
} else {
  localStorage.removeItem('companyCode');
  localStorage.removeItem('companyName');
  localStorage.removeItem('companyId');
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

axios.interceptors.request.use(
  (config) => {
    store.dispatch(showLoader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (config) => {
    store.dispatch(hideLoader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const Routes = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Switch>
        <Redirect exact from='/' to={REACT_LOGIN} />
        <Route path={REACT_LOGIN} component={LoginForm} />
        <Route path={REACT_VERIFY_COMPANY} component={VerifyCompany} />
        <Route path={REACT_LOGOUT} component={Logout} />
        <Route path='/fams/forgot-password' component={ForgotPasword} />
        <Route path='/fams/reset-password' component={ResetPassword} />
        <Route path={REACT_RESULT_PAGE} component={CommonResultPage} />
        <Route path='/fams/activate-account' component={ActivateAccount} />
        <LeftSidebar>
          <SecuredRoute path={REACT_URL_DASHBOARD} component={Dashboard} />
          <SecuredRoute path={'/fams/user-profile'} component={UserProfile} />
          <SecuredRoute path={'/fams/user-profile-screen'} component={UserProfileScreen} />
          <SecuredRoute path={'/fams/company-profile-screen'} component={CompanyProfileScreen} />
          <SecuredRoute path={'/fams/company-profile'} component={CompanyProfile} />
          <SecuredRoute path={REACT_URI_PERSON} component={Person} />
          <SecuredRoute path={'/fams/Addperson'} component={AddPerson} />
          <SecuredRoute exact path={REACT_URI_DEPARTMENT} component={Department} />
          <SecuredRoute exact path={'/fams/create-department'} component={AddDepartment} />
          <SecuredRoute exact path={REACT_URI_DESIGNATION} component={Designation} />
          <SecuredRoute exact path={'/fams/create-designation'} component={AddDesignation} />
          <SecuredRoute exact path={REACT_URI_APPLICATIONS} component={Membership} />
          <SecuredRoute path={REACT_URI_BRANCH_CONFIGURATIONS} component={BranchConfigurations} />
          <SecuredRoute path={'/fams/create-branchConfiguration'} component={AddBranchConfiguration} />
          <SecuredRoute path={REACT_URI_REQUEST_TYPE} component={RequestType} />
          <SecuredRoute path={'/fams/create-request'} component={CreateRequestType} />
          <SecuredRoute path={REACT_URI_TEMPLATE_ITEMS} component={TemplateItem} />
          <SecuredRoute path={'/fams/create-templeteitems'} component={AddTemplateItem} />
          <SecuredRoute path={REACT_URI_TEMPLATES} component={Template} />
          <SecuredRoute path={'/fams/create-templete'} component={AddTemplate} />
          <SecuredRoute path={REACT_URI_APPROVAL_WORKFLOW} component={ApprovalWorkflow} />
          <SecuredRoute path={'/fams/create-approvalWorkflow'} component={AddApprovalWorkflow} />
          <SecuredRoute path={REACT_URI_MY_REQUESTS} component={MyRequests} />
          <SecuredRoute path={'/fams/create-myrequest'} component={AddMyRequest} />
          <SecuredRoute path={'/fams/create-editmyrequest'} component={EditMyRequest} />
          <SecuredRoute path={REACT_URL_BRANCH} component={Branch} />
          <SecuredRoute path={'/fams/create-branch'} component={AddBranch} />
          <SecuredRoute path={'/fams/create-OwnerChangebranch'} component={OwnerChange} />
          <SecuredRoute path={'/fams/create-Updatebranch'} component={UpdateBranch} />
          <SecuredRoute path={REACT_URI_WORKFLOW_NODE} component={WorkflowNode} />
          <SecuredRoute path={'/fams/create-WorkflowNode'} component={AddWorkflowNode} />
          <SecuredRoute path={REACT_URI_APPROVAL_CONDITION} component={ApprovalCondition} />
          <SecuredRoute path={'/fams/create-approvalCondition'} component={AddApprovalCondition} />
          <SecuredRoute path={REACT_URI_APPROVER} component={Approver} />
          <SecuredRoute path={REACT_URI_REPORTS} component={ReportsItems} />
        </LeftSidebar>
      </Switch>
    </ThemeProvider>
  );
};

export default Routes;
