import { REQUEST_TYPE_LIST } from "../../actions/types";

const initialState = {
    requestTypeList: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_TYPE_LIST:
            return {
                ...state,
                requestTypeList: action.payload
            };
        default:
            return state;
    }
}