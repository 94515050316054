import { combineReducers } from 'redux';
import securityReducer from '../oauth-client/securityReducer';
import approvalConditionReducer from './approvalCondition/approvalConditionReducer';
import ApprovalWorkflowReducer from './approvalWorkflow/ApprovalWorkflowReducer';
import BranchReducer from './branch/BranchReducer';
import BranchConfigurationsReducer from './branchConfigurations/BranchConfigurationsReducer';
import EmployeeListReducer from './EmployeeListReducer';
import EmployeeListV2Reducer from './EmployeeListV2Reducer';
import errorReducer from './errorReducer';
import LoaderReducer from './LoaderReducer';
import MyRequestsReducer from './myRequests/MyRequestsReducer';
import CompanyReducer from './organization/CompanyReducer';
import DepartmentReducer from './organization/DepartmentReducer';
import DesignationReducer from './organization/DesignationReducer';
import PersonReducer from './organization/PersonReducer';
import UserRoleReducer from './organization/UserRoleReducer';
import ReportReducer from './reports/ReportReducer';
import RequestTypeReducer from './requestType/RequestTypeReducer';
import TemplateItemsReducer from './templateItems/TemplateItemsReducer';
import TemplateReducer from './TemplateReducer';
import ThemeOptions from './ThemeOptions';
import WorkflowNodeReducer from './workflowNodeReducer/WorkflowNodeReducer';

//--------Organization end--------------//
export default combineReducers({
  errors: errorReducer,
  ThemeOptions: ThemeOptions,
  EmployeeList: EmployeeListReducer,
  security: securityReducer,
  person: PersonReducer,
  userRole: UserRoleReducer,
  department: DepartmentReducer,
  designation: DesignationReducer,
  requestType: RequestTypeReducer,
  template: TemplateReducer,
  branchConfiguration: BranchConfigurationsReducer,
  templateItem: TemplateItemsReducer,
  approvalWorkflow: ApprovalWorkflowReducer,
  workflowNode: WorkflowNodeReducer,
  approvalCondition: approvalConditionReducer,
  myRequest: MyRequestsReducer,
  branch: BranchReducer,
  employeeV2: EmployeeListV2Reducer,
  report: ReportReducer,
  loader: LoaderReducer,
  company: CompanyReducer,
});
