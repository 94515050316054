import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REFRESH_TOKEN_TIMER, SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from '../actions/types';
import setJWTToken from './setJWTToken';

export default function RefreshToken() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  useEffect(() => {
    console.log('Start Init Refreshed token @' + store.security.tokenRefreshTimer);

    if (store.security.tokenRefreshTimer > 60000) {
      console.log('Initiated Refreshed token @' + store.security.tokenRefreshTimer);
      console.log('Initiated Refreshed token @' + new Date());
      setInterval(() => {
        refreshToken();
      }, store.security.tokenRefreshTimer);
    }
    //eslint-disable-next-line
  }, [store.security.tokenRefreshTimer]);

  const refreshToken = async () => {
    var decoded_jwtToken = jwt_decode(localStorage.getItem("access_token"));
    var payload = {
      refreshToken: localStorage.getItem("refresh_token").replace('Bearer ', ''),
      companyCode: decoded_jwtToken.companyCode,
    };
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(payload),
    };
    try {
      const fetchResponse = await fetch(`/user/refresh_token`, settings);
      const res = await fetchResponse.json();
      if (res.code === 'FHRMSI0001') {
        console.log('Refreshed token @' + new Date());
        setJWTToken(res.data.access_token);
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        const decoded = jwt_decode(res.data.access_token);
        localStorage.setItem('companyCode', decoded.companyCode);
        console.log('Refreshed token');
        var currentSeconds = Math.floor(Date.now() / 1000);
        var secondsLeft = decoded.exp - currentSeconds;
        var timer = Math.floor(secondsLeft / 60) - 5;
        timer = timer * 60 * 1000;
        console.log('Set new refresh token timer', timer);

        dispatch({
          type: REFRESH_TOKEN_TIMER,
          payload: 0,
        });
        dispatch({
          type: SET_CURRENT_USER,
          payload: decoded,
        });
        dispatch({
          type: SET_REFRESH_TOKEN,
          payload: res.data.refresh_token,
        });
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: res.data.access_token,
        });
        dispatch({
          type: REFRESH_TOKEN_TIMER,
          payload: timer,
        });
      }
    } catch (e) {
      return e;
    }
  };
  return <></>;
}
