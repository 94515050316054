import { Grid, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStatusReportLogList } from "../../actions/reports/ReportsAction";
import CustomColor from "../../components/CustomColor";
import CommonUtil from "../../components/Util/CommonUtils";
import DataTable from "../../components/DataTableNew";

export default function CancelledReports() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(() => {
        loadData(page, rowsPerPage);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.report.statusreportList)) {
            setPage(store.report.statusreportList.currentPage - 1);
            setData(store.report.statusreportList.requests);
            setTotalPages(store.report.statusreportList.totalPages)
        } else {
            setPage(0);
            setData([]);
        }

    }, [store.report.statusreportList]);

    const loadData = (page, rowsPerPage) => {
        const payload = {
            id: '',
            requestTypeId: '',
            templateId: '',
            status: 'Cancelled',
            companyId: '',
            userId: '',
        };
        dispatch(getStatusReportLogList(page, rowsPerPage, payload));
    };

    const handleChangePage = (newPage, size) => {
        setRowsPerPage(size);
        setPage(newPage);
        loadData(newPage, size);
    };
    //eslint-disable-next-line
    const columns = React.useMemo(() => [
        {
            field: "companyId.name",
            headerName: t('BRANCHCONFIG0007'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.companyId.name,
            renderCell: (params) => (
                <Tooltip title={params.row.companyId.name} >
                    <span className="table-cell-trucate">{params.row.companyId.name}</span>
                </Tooltip>
            ),
        },
        {
            field: "requestTypeId.name",
            headerName: t('BRANCHCONFIG0004'),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.requestTypeId.name,
            renderCell: (params) => (
                <Tooltip title={params.row.requestTypeId.name} >
                    <span className="table-cell-trucate">{params.row.requestTypeId.name}</span>
                </Tooltip>
            ),
        },
        {
            field: "status",
            headerName: t('COMMON001'),
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => params.row.status,
            renderCell: (params) => {
                return <CustomColor row={params.row.status} />;
            },

        }
    ])

    return (
        <>
            <Grid>
                <DataTable
                    columns={columns}
                    rows={data}
                    page={page}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    handleChange={(newPage, size) => handleChangePage(newPage, size)}
                />
            </Grid>
        </>
    );
}