
import { Box, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URI_TEMPLATES } from '../../../actions/EndPoints';
import { getRequestTypeList } from '../../../actions/requestType/RequestTypeActions';
import { addTemplateType, updateTemplateType } from '../../../actions/templates/TemplatesActions';
import Breadcrumb from '../../../components/Breadcrumb';
import CancelButtons from '../../../components/CancelButtons';
import SubmitButtons from '../../../components/SubmitButtons';
import CommonUtil from '../../../components/Util/CommonUtils';

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  customContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 4px #00000029',
    border: '0.5px solid #00000029',
    opacity: 1,
    marginBottom: '5px',
    borderRadius: '14px',
    padding: '18px',
    margin: '24px',
    height: "700px",
    [theme.breakpoints.down("lg")]: {

      height: "400px",
    },
  },
}));


export default function AddTemplate(props) {
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [requestTypes, setRequestTypes] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.history.location.state ? props.history.location.state?.id : '',
    code: props.history.location.state ? props.history.location.state?.code : "",
    name: props.history.location.state ? props.history.location.state?.name : "",
    version: props.history.location.state ? props.history.location.state?.version : "",
    requestType: props.history.location.state.requestTypeId ? props.history.location.state?.requestTypeId : "",
    description: props.history.location.state ? props.history.location.state?.description : "",
    status: props.history.location.state ? props.history.location.state?.description : "",
  });
  const [isdisable, setdisable] = React.useState(false);
  const [error, setError] = React.useState({
    name: false,
    code: false,
    requestType: false,
  });

  const handleClose = () => {
    history.push(REACT_URI_TEMPLATES)
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  useEffect(() => {
    loadRequestTypes();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.requestType.requestTypeList)) {
      setRequestTypes(store.requestType.requestTypeList.request_types);
    } else {
      setRequestTypes([]);
    }

  }, [store.requestType.requestTypeList]);
  const loadRequestTypes = () => {
    dispatch(getRequestTypeList(1, 0, ''));
  }
  const breadcrumb = [
    { path: REACT_URI_TEMPLATES, name: t('COMMON195') },
    { path: '/fams/create-templete', name: props.history.location.state?.id === undefined || props.history.location.copy ? t("COMMON196") : t("TEMPLATEIT00001") },
  ];

  const handleSubmit = (data) => {
    if (CommonUtil.isEmptyString(state.requestType)) {
      setError({ requestType: true })
      toast.error(t("BRANCHCONFIG0006"))
    } else if (CommonUtil.isEmptyString(state.code)) {
      setError({ code: true })
      toast.error(t("REQUESTTYPES0003"))
    } else if (!CommonUtil.validateCode(state.code)) {
      setError({ code: true })
      toast.error(t("codeValidation"))
    } else if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true })
      toast.error(t("REQUESTTYPES0004"))
    } else if (!CommonUtil.validateName(state.name)) {
      setError({ name: true })
      toast.error(t("nameValidation"))
    } else {
      const update = {
        id: state.id,
        requestTypeId: state.requestType.id,
        name: state.name,
        code: state.code.toUpperCase(),
        status: data,
        version: state.version,
        description: state.description,
      }
      if (!CommonUtil.isEmptyString(state.id) && props.history.location.copy !== 'COPY') {
        setdisable(true)
        dispatch(updateTemplateType(state.id, update,
          () => {
            handleClose();
            toast.success(t('TEMPUPDSUC001'));
          },
          (error) => {
            setdisable(false)
            switch (error.code) {
              case 'FHRMSE0042':
                setError({ code: true });
                break;
              case 'FHRMSE0043':
              case 'FHRMSE0110':
                setError({ name: true });
                break;
              default:
                setError({ ...error });
            }
            toast.error(error.message);
          }
        ));
      } else {
        setdisable(true)
        dispatch(addTemplateType(update, () => {
          handleClose();
          if (!CommonUtil.isEmptyString(state.id)) {
            toast.success(t('COMMON202'));
          }
          else if (data === "DRAFT") {
            toast.success(t('COMMON201'));
          } else {
            toast.success(t('TEMPCRESUC001'));
          }
        },
          (error) => {
            setdisable(false)
            switch (error.code) {
              case 'FHRMSE0042':
                setError({ code: true });
                break;
              case 'FHRMSE0043':
              case 'FHRMSE0110':
                setError({ name: true });
                break;
              default:
                setError({ ...error });
            }
            toast.error(error.message);
          }
        ));
      }
    }
  }

  return (
    <>
      <Grid fullWidth   >
        <Breadcrumb links={breadcrumb} />
        <Grid fullWidth className={`${classes.customContainer} background-size`}>
          <Grid container sx={12} xs={12} alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6} sm={6} >
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <Autocomplete
                  openText={t('COMMON169')}
                  closeText={t('COMMON170')}
                  clearText={t('COMMON215')}
                  noOptionsText={t('COMMON214')}
                  style={{ width: '100%' }}
                  disabled={props.history.location.state ? true : false}
                  options={requestTypes}
                  name={'requestType'}
                  value={state.requestType}
                  getOptionLabel={(reqType) => (reqType.name ? reqType.name : '')}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      setState({ ...state, requestType: newValue });
                      setError({ requestType: false })
                    } else {
                      setState({ ...state, requestType: '' });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} value={state.requestType} name={'requestType'} error={error.requestType} variant='outlined' size="medium" fullWidth label={t('BRANCHCONFIG0004') + '*'} />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1} >
                <TextField
                  fullWidth
                  error={error.code}
                  name='code'
                  label={t('TEMTYPE001') + '*'}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  value={state.code}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 30 }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  error={error.name}
                  name='name'
                  label={t('COMMON014') + '*'}
                  helperText=''
                  variant='outlined'
                  size='medium'
                  value={state.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} >
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <TextField
                  fullWidth
                  name='version'
                  label={t('TEMP001')}
                  helperText=''
                  defaultValue="1"
                  disabled
                  value={'1'}
                  aria-readonly
                  variant='outlined'
                  size='medium'
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} >
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                multiline
                rows={3}
                fullWidth
                error={error.description}
                name='description'
                label={t('REQUESTTYPES0002')}
                helperText=''
                variant='outlined'
                size='medium'
                value={state.description}
                onChange={handleChange}
                inputProps={{ maxLength: 250 }}
              />
            </Box>
          </Grid>
          <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Box display="flex" m={1} p={1}>
              <CancelButtons onClick={handleClose} color='primary' variant='outlined' className='m-2'>
                {t('COMMON008')}
              </CancelButtons>
              <SubmitButtons onClick={() => { handleSubmit('DRAFT') }} color='primary' disabled={isdisable} variant='contained' className='m-2'>
                {t('COMMON194')}
              </SubmitButtons>
              <SubmitButtons onClick={() => { handleSubmit('ACTIVE') }} disabled={isdisable} color='primary' variant='contained' className='m-2'>
                {t('COMMON035')}
              </SubmitButtons>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

