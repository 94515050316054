import { Box, Divider, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from './CustomButton';

export default function ConfirmModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = () => {
    props.onConfirm();
  };

  return (
    <div>
      <Dialog maxWidth='lg' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='confirm-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1} display={'flex'} alignContent={'right'} mt={1} mb={2}
            >
              <span style={{ size: '20px', color: '#304669' }}>{props.title}</span>
            </Box>
            <CloseRoundedIcon onClick={handleClose} style={{ color: "#B7B7B7", background: "white", borderRadius: "50%", marginTop: '10px', }} fontSize="small" />
          </Box>
        </DialogTitle>
        <Divider id='form-dialog-title' />
        <DialogContent>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ size: '16px', color: '#304669' }} >
            {props.text}
          </Grid>
        </DialogContent>
        <DialogActions className='MuiDialogActions-roots'>
          <Grid container direction="row" justifyContent="center" alignItems="center"  >
            <CustomButton onClick={handleClose} variant='outlined' backgroundColor='white' color='#FC5F46' hovercolor='#E5482F' hoverborderColor='#E5482F' borderColor='#FC5F46' size='medium' className='m-2'>
              {t("COMMON008")}
            </CustomButton>
            <CustomButton onClick={handleSubmit} color='#FFFFFF' backgroundColor={(props.title) === 'Delete' || 'Cancel Request' ? '#FC5F46' : '#35BA6605'} hoverbackgroundColor={'#E5482F'} variant="contained" className='m-2'>
              {props.title}
            </CustomButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
