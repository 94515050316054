import { Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteRequestType, getRequestTypeList } from "../../../actions/requestType/RequestTypeActions";
import AddIcon from '../../../assets/images/Group 8073.svg';
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmModal from "../../../components/ConfirmModalNew";
import DataTable from '../../../components/DataTableNew';
import HeaderToolbar from "../../../components/HeaderTooltip";
import CommonUtil from "../../../components/Util/CommonUtils";
import DeleteIcon from "../../reusableIcons/DeleteIcon";
import EditIcon from "../../reusableIcons/EditIcon";

export default function RequestTypes() {
    const { t } = useTranslation();
    const store = useSelector((state) => state);
    const breadcrumb = [{ path: '', name: t('BRANCHCONFIG0004') }];
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory()
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //eslint-disable-next-line
    const columns = React.useMemo(() => [
        {
            field: "code",
            headerName: t('REQUESTTYPES0014'),
            flex: 1,
            width: 10,
            renderCell: (params) => (
                <Tooltip title={params.row.code} >
                    <span className="table-cell-trucate">{params.row.code}</span>
                </Tooltip>
            ),
        },
        {
            field: "name",
            headerName: t('REQUESTTYPES0015'),
            flex: 1,
            width: 10,
            renderCell: (params) => (
                <Tooltip title={params.row.name} >
                    <span className="table-cell-trucate">{params.row.name}</span>
                </Tooltip>
            ),
        },
        {
            field: "description",
            headerName: t('REQUESTTYPES0002'),
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={params.row.description} >
                    <span className="table-cell-trucate">{params.row.description}</span>
                </Tooltip>
            ),
        },
        {
            field: "Actions",
            headerName: t('dataTableAction'),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    onClick={() => openEditRequest(params.row)}

                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => deleteRecord(params.row)}
                />,
            ],
        },

    ]);

    useEffect(() => {
        loadData(page, rowsPerPage);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!CommonUtil.isEmpty(store.requestType.requestTypeList)) {
            setPage(store.requestType.requestTypeList.currentPage - 1);
            setTotalPages(store.requestType.requestTypeList.totalPages)
            setData(store.requestType.requestTypeList.request_types);
        } else {
            setPage(0);
            setData([]);
        }

    }, [store.requestType.requestTypeList]);

    const loadData = (page, rowsPerPage) => {
        dispatch(getRequestTypeList(page, rowsPerPage, '', ''));
    };

    const openCreateRequest = () => {
        history.push
            ({
                pathname: "/fams/create-request",
                state: data,
            });
    };
    const openEditRequest = (data) => {
        history.push
            ({
                pathname: "/fams/create-request",
                state: data,
            });
    };

    const handleChange = (newPage, size) => {
        setRowsPerPage(size);
        setPage(newPage);
        loadData(newPage, size);
    };

    const deleteRecord = (data) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmModal
                        open={true}
                        title={t('COMMON004')}
                        text={t('COMMON018')}
                        onConfirm={() => {
                            dispatch(
                                deleteRequestType(data.id,
                                    (data) => {
                                        toast.success(t('REQUESTTYPES0008'));
                                        loadData(0, rowsPerPage);
                                    },
                                    (data) => {
                                        switch (data.code) {
                                            case "FHRMSE0054":
                                                toast.error(t("REQUESTDELETE"));
                                                break;
                                            default:
                                                toast.error(data.message);
                                        }
                                    }
                                ));
                            onClose();
                        }}
                        onClose={() => onClose()}
                    />
                );
            },
        });
    }

    return (
        <Grid>
            <Breadcrumb links={breadcrumb}>
                <HeaderToolbar src={AddIcon} onClick={openCreateRequest} tooltipTitle={t('COMMON009')} size='large' />
            </Breadcrumb>
            <div style={{ height: '100%', width: "100%" }}>
                <DataTable
                    columns={columns}
                    rows={data}
                    page={page}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    handleChange={(newPage, size) => handleChange(newPage, size)}
                    rowsPerPage={rowsPerPage}
                />
            </div>
        </Grid>
    )
}